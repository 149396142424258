import {RiasecQuestionAnswer} from '../models/RiasecQuestionAnswer';

export class RiasecQuestionItem extends RiasecQuestionAnswer{
    
    public statement: string;

    constructor()
    {
        super();
    }

    public static toQuestionItem(obj: any): RiasecQuestionItem {
        var item = new RiasecQuestionItem();
        item.id = obj.id;
        item.statement = obj.questionText;
        item.answer = obj.answer;
        return item;
    }
}   