import {inject} from 'aurelia-framework';
import {Endpoint, Rest} from 'aurelia-api';

const setupPaymentUri: string = 'setuppayment/';
const failurePaymentUri: string = 'paymentFailure/';
const createPaymentRequestUri: string = 'createpayrequest';
const confirmUri: string = 'confirmation/';

@inject(Endpoint.of("payments"))
export class PaymentsServer {

    constructor(private endpoint: Rest) {
        (this.endpoint.defaults as any).retries = 2;
    }

    public paymentConfirmation(token: string, resultToken: string, sessionToken: string): Promise<any> {
        return this.endpoint.post(confirmUri + token, {sessionToken: sessionToken, resultToken : resultToken });
    }

    public paymentSetup(token: string): Promise<any> {
        return this.endpoint.post(setupPaymentUri + token);
    }

    public paymentFailure(token: string): Promise<any> {
        return this.endpoint.post(failurePaymentUri + token);
    }

    public createPayment(amountDue: number, userId: string ): Promise<any> {
        return this.endpoint.post(createPaymentRequestUri, 
            {
                userId: userId,
                amountDue: amountDue
            });
    }
}

