import {RouteCnfg} from '.././routes';


export class SpecialisedPages {
    public description: string ='';
    public cardSort: string ='';
    public routeRef: RouteCnfg = null;
    public include: boolean;
}

