
export class ErrorObject {
    public response: Response;
    public status: number;
    public code: string;
    public suggestedAction: string;
    public explanation: string;
    public url: string;
    public displayed: boolean;

    private static _EmptyError = new ErrorObject(null, null, null, null);


    constructor(public errorObj: any, public sourceObj: any, public action: string, public message: string, private onDecode?:(sender: ErrorObject, info: object) => void) {
        if (this.errorObj instanceof Response) {
            this.response = this.errorObj;
            this.status = this.response.status;
            this.code = this.status.toString(10);
            this.url = this.response.url;

            this.response.json().then(obj => {
                if (obj.message && obj.type && obj.code) {
                        this.message = obj.message;
                        this.code  = this.code + "::" + obj.code;
                }

                if (obj.error && obj.error_description) {
                    this.message = obj.error_description;
                    this.code = this.code + "::" + obj.error;
                }

                if (this.onDecode)
                {
                    this.onDecode(this, obj);
                }
            })
        } else if (this.errorObj instanceof TypeError && this.errorObj.message == "Failed to fetch") {
            this.status = 600;
            this.code = this.status.toString(10);
            this.url = this.errorObj.message;
        }

        switch (this.status)
        {
            case 400:
                this.suggestedAction = "You need to correct the data before attempting the action again.";
                this.explanation = "The server has failed your combination of action and data for some reason.";
                break;
            case 401:
                this.suggestedAction = "If you believe you should have access contact an administrator.";
                this.explanation = "The server has restricted access to the requested resource.";
                this.code = this.code + "::AccessDenied";
                break;
            case 404:
                this.suggestedAction = "You need to correct the data before attempting the action again.";
                this.explanation = "The request data was not found.";
                break;
            case 500:
                this.suggestedAction = "You should attempt the action again or in the case of repeated failures, try again later.";
                this.explanation = "Something has has gone wrong at the server. This may be transient.";
                break;
            case 600:
                this.suggestedAction = "You should attempt to correct the issue. Wi-Fi signal lost? Cable disconnected? Digger dug up cable?";
                this.explanation = "Looks like your internet connection has been lost. This may be transient.";
                break;
            default:
                this.suggestedAction = "You should attempt the action again or in the case of repeated failures, try again later.";
                this.explanation = "Hmm, that's a problem - we don't know what happened.";
                break;
        }
    }

    public static EmptyError(): ErrorObject {
        return ErrorObject._EmptyError;
    }
}   
