import {autoinject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {RiasecQuestionnaireInfo} from '../models/RiasecQuestionnaireInfo';
import {GlobalState} from '../models/GlobalState';
import {AdministrationServer} from '../server/AdministrationServer';
import {backPage} from '../resources/navigation/NextPage'
import {ErrorObject} from '../models/ErrorObject';
import {BillingSummary} from '../models/BillingSummary';
import {BillingDetail} from '../models/BillingDetail';
import {BillingCycle} from '../models/BillingCycle';

@autoinject
@backPage()
export class Billing {

    public questionnaires: RiasecQuestionnaireInfo[] = [];
    public errorObject: ErrorObject = null;
    public billingDetails: BillingDetail[];
    public billingCycleId: number;
    public billingDescription: string = '';
    public billingFrom: Date;
    public billingCompany: string;
    public billingSummary: BillingSummary[];
    public billingCycles: BillingCycle[];

    constructor(protected router: Router, protected globalState: GlobalState, private server: AdministrationServer) {
        this.globalState.clearFooterDetails();
    }

    public activate(): any {
        // Get the latest billing details not yet billed ...
        return this.server.loadBillingInfo(null, null)
                .then(data => {
                    this.billingCycleId = data.billingCycleId;
                    this.billingCycles = data.billingCycles;
                    this.billingDetails = data.billingItems;
                    this.billingSummary = data.billingSummary;
                    this.billingDescription = this.billingCycles.find(itm => itm.billingCycleId == this.billingCycleId).description;
                    })

                .catch(response => {
                    this.errorObject = new ErrorObject(
                        response,
                        null,
                        "Load billing details", 
                        "The data load of the billing details list has failed.");       
                });
    }

    public toggleState(event: Event): any
    {
        var cb = event.target as HTMLInputElement;
        if (cb.readOnly) cb.checked=cb.readOnly=false;
        else if (!cb.checked) cb.readOnly=cb.indeterminate=true;
    }


    public newBillingCycle(event: Event): any
    {
        return this.server.newBillingCycle()
                .then(data => {
                    this.billingCycleId = data.billingCycleId;
                    this.billingCycles = data.billingCycles;
                    this.billingDetails = data.billingItems;
                    this.billingSummary = data.billingSummary;
                    this.billingDescription = this.billingCycles.find(itm => itm.billingCycleId == this.billingCycleId).description;
                })
                .catch(response => {
                    this.errorObject = new ErrorObject(
                        response,
                        null,
                        "Create a new billing cycle", 
                        "The data load of the billing details list has failed.");       
                });
    }

    public loadBillingDetails(event:Event): any
    {
        if ((this.billingCycleId) && this.billingCycles.find(itm => itm.billingCycleId == this.billingCycleId).description.startsWith("Current")) {
            this.billingCycleId = null;
        }

        return this.server.loadBillingInfo(this.billingCycleId, this.billingCompany)
                .then(data => {
                    this.billingCycleId = data.billingCycleId;
                    this.billingCycles = data.billingCycles;
                    this.billingDetails = data.billingItems;
                    this.billingSummary = data.billingSummary;
                    this.billingDescription = this.billingCycles.find(itm => itm.billingCycleId == this.billingCycleId).description;
                })
                .catch(response => {
                    this.errorObject = new ErrorObject(
                        response,
                        null,
                        "Load billing details", 
                        "The data load of the billing details list has failed.");       
                });

    }

    public alterAssessmentState(event: Event): any
    {

    }
}   