
export class BillingSummary {
    public company: string;
    public billingGroupRuleId: number;
    public assessmentAmount: number;
    public subscriptionCharge: number;
    public billingGroupCount: number

    public static toBillingSummary(obj: any): BillingSummary {
        let item = new BillingSummary();
        item.company = obj.company;
        item.billingGroupRuleId = obj.billingGroupRuleId;
        item.billingGroupCount = obj.billingGroupCount;
        item.assessmentAmount = obj.itemAmount;
        item.subscriptionCharge = obj.subscriptionCharge;

        return item;
    }
}

