import {autoinject} from 'aurelia-framework';
import {Router, RouteConfig} from 'aurelia-router';
import {GlobalState} from '../models/GlobalState';
import {nextPage} from '../resources/navigation/NextPage';
import {Routes} from '../routes';

@autoinject
@nextPage(Routes.IA_SKILLS_CARDS.urlFragment)
export class SkillThemesHandsOn {
    
    constructor(protected router: Router, public globalState: GlobalState) {
    }

    public activate(params: any, route: RouteConfig): any {
        if (params.id && params.id.length >= 36)
        {
            if (!this.globalState.questionnaire.id || !this.globalState.questionnaire.id != params.id)
            {
                this.globalState.questionnaire.id = params.id;
                this.globalState.questionnaire.loaded = false;
            }
        } else if (this.globalState.Id)  
        {
            // Perform navigation so that we get the assessment Id present in the URL so that a refresh in the browser will not lose the clients page.
            this.router.navigateToRoute( route.name, { 'id': this.globalState.Id }, { trigger: false, replace: true });
        }
    }

    public showHelp(event: Event): any{
        alert("If you find it difficult to identify your preferred skills ask your future self this “Is this a skill I would like to use if I have trained into my career and am experienced and feel confident?”");
        return false;
    }
}    

