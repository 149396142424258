import {autoinject, computedFrom} from 'aurelia-framework';
import {RouteConfig} from 'aurelia-router';
import {AuthService} from 'aurelia-authentication';

export const authenticatedDependency: string = 'authService.authenticated';
export const standardUserRole: string = 'User';
export const questionnaireAnalysisRole: string = 'QuestionnaireAnalysis';
export const questionnaireUserRole: string = 'QuestionnaireUser';
export const assessmentUserRole: string = 'AssessmentUser';       // For Independent Assessments
export const administratorUserRole: string = 'Administrator';
export const inSessionRole: string = 'InSession';

@autoinject
export class SecurityRoles {

    constructor(private authService: AuthService) {
    }

  /**
     * Determine if the user is authenticated and their associated roles
     *
     * @return {string[]} An array of Roles that are associated with the user
     */
    public get Roles(): string[] {
        let authenticated = this.authService.authenticated;
        if (authenticated)
        {
            // return an array of roles. will Check to see if the user is actually a normal user ...
            let payload = <any>this.authService.getTokenPayload();
            if (payload)
            {
                return SecurityRoles.GetRoles(payload.role);
            }

            return ['N/A'];
        }
        
        return [];
    }

  /**
     * Return an array of Roles from either an input string separated by "," or an array object
     *
     * @return {string[]} An array of Roles that are associated with the user
     */
    public static GetRoles(rolesPayload: string | string[]): string[] {
        if (rolesPayload)
        {
            if (typeof(rolesPayload) === 'string')
            {
                return rolesPayload.split(",");
            }
            else if (Array.isArray(rolesPayload))
            {
                return rolesPayload;
            }
        }
        
        return [];
    }
    

  /**
     * This checks if the routeConfig has any authentication requirements.
     * If it does then the user must be authenticated ...
     * If it has role requirements, then the user must posses one of the specified roles.
     *
     * @return {boolean} True if the route passes the authorisation and authentication requirements.
     */
    public static RouteAuthorisationFilter(routeConfig: RouteConfig, roles: string[], isAuthenticated: boolean): boolean
    {
        return (routeConfig 
                && !routeConfig.hasOwnProperty('auth') || routeConfig['auth'] === isAuthenticated && (!routeConfig.hasOwnProperty('roles') || SecurityRoles.GetRoles(routeConfig['roles']).some(requiredRole => roles.indexOf(requiredRole) >= 0)));
    //return (routeConfig && typeof (<any>routeConfig).auth !== 'boolean' || (<any>routeConfig).auth == isAuthenticated && typeof (<any>routeConfig).roles !== 'string'|| (<string>(<any>routeConfig).roles).split(",").some(requiredRole => roles.indexOf(requiredRole) >= 0));
    }


  /**
     * Determine if the user is authenticated as a plain "User"
     *
     * @return {boolean} Returns true if the user is authenticated and has the role "User"
     */
    @computedFrom(authenticatedDependency)
    public get isAuthenticated(): boolean {
        return this.Roles.some((itm) => itm === standardUserRole);
    }

  /**
     * Determine if the user is authenticated as only a "QuestionnaireUser"" and not a plain "User"
     *
     * @return {boolean} Returns true if the user is authenticated and has the role QuestionnaireUser" and does not have the role "User".
     */
    @computedFrom(authenticatedDependency)
    public get isQuestionnaireUser(): boolean {
        let theRoles = this.Roles;
        return theRoles.some((itm) => itm === questionnaireUserRole) && !theRoles.some((itm) => itm === standardUserRole);
    }

  /**
     * Determine if the user is authenticated as only a "QuestionnaireUser"" and not a plain "User"
     *
     * @return {boolean} Returns true if the user is authenticated and has the role QuestionnaireUser" 
     */
   @computedFrom(authenticatedDependency)
   public get isQuestionnaireAnalysisUser(): boolean {
        return this.Roles.some((itm) => itm === questionnaireAnalysisRole);
   }

  /**
     * Determine if the user is authenticated as only a "QuestionnaireUser"" and not a plain "User"
     *
     * @return {boolean} Returns true if the user is authenticated and has the role InSession
     */
  @computedFrom(authenticatedDependency)
  public get isInSession(): boolean {
       return this.Roles.some((itm) => itm === inSessionRole) || this.Roles.some((itm) => itm === standardUserRole);
  }

/**
     * Determine if the user is authenticated as only a "AssessmentUser" and not a plain "User"
     *
     * @return {boolean} Returns true if the user is authenticated and has the role AssessmentUser" and does not have the role "User".
     */
   @computedFrom(authenticatedDependency)
   public get isAssessmentUser(): boolean {
    let theRoles = this.Roles;
    return theRoles.some((itm) => itm === assessmentUserRole) && !theRoles.some((itm) => itm === standardUserRole);
   }

   @computedFrom(authenticatedDependency)
    public get isAdministrator(): boolean {
        let theRoles = this.Roles;
        return theRoles.some((itm) => itm === standardUserRole ) && theRoles.some((itm) => itm === administratorUserRole) && !theRoles.some((itm) => itm === questionnaireUserRole) && !theRoles.some((itm) => itm === assessmentUserRole);
    }
}     

