import {autoinject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {GlobalState} from '../models/GlobalState';
import {AdministrationServer} from '../server/AdministrationServer';
import {QuestionServer} from '../server/QuestionServer';
import {backPage} from '../resources/navigation/NextPage'
import {RiasecQuestionnaireInfo} from '../models/RiasecQuestionnaireInfo';
import {ErrorObject} from '../models/ErrorObject';

@autoinject
@backPage()
export class Users {

    public questionnaires: RiasecQuestionnaireInfo[] = [];
    public errorObject: ErrorObject = null;
    public userId: string = "";
    public acceptId: string = "";
    public deleteDescription: string = "";
    public pageTitle: string = "";
    public percentage: number = 40;
    private selectedItem: RiasecQuestionnaireInfo = null;

    constructor(protected router: Router, protected globalState: GlobalState, private server: QuestionServer, private adminServer: AdministrationServer) {
    }

    public activate(params: any): any
    {
        if (params.id)
        {
            this.userId = params.id;
        }

        if ((this.globalState.users) && (this.globalState.users.length > 0))
        {
            let user = this.globalState.users.find(itm => itm.userId == this.userId);
            this.pageTitle = "Assessments associated with " + user.knownAsName + " (" + user.company + ")";
        }
        else
        {
            this.adminServer.loadUsers()
            .then(response => {
                this.globalState.users = response;
                let user = this.globalState.users.find(itm => itm.userId == this.userId);
                this.pageTitle = "Assessments associated with " + user.knownAsName + " (" + user.company + ")";
            });
        }

        return this.server.loadUsersAssessments(this.userId)
                .then(response => {
                    this.questionnaires = response;
                })
                .catch(response => {
                    this.errorObject = new ErrorObject(
                        response,
                        null,
                        "Load User Assessment List", 
                        "The data load of the questionnaire list has failed.");       
                });
    }

    public cleanupAssessment(event: Event): any
    {
        // Prevent default behavior when clicking a link
        event.preventDefault();
        let target = <Node>event.target;
        this.percentage = 40;              // Default to 40% from window top .. which is about middle of the window for small counts.
        if (this.questionnaires.length > 30)
        {
            // but if too many lines, need to calculate the actual % position
            let index = (<any>target.parentElement.parentElement).rowIndex;
            if (index)
            {
                this.percentage = (index * 100) / this.questionnaires.length; 
            }
        }
        this.selectedItem = <RiasecQuestionnaireInfo>(<any>target).model;
        this.deleteDescription = `Do you want to delete the assessment for ${this.selectedItem.firstname} ${this.selectedItem.surname}?`;
        this.acceptId = this.selectedItem.id;
    }

    public doCancel(event: Event): any
    {
        // Prevent default behavior when clicking a link
        event.preventDefault();
        // Clear the existing value, so that we trigger a redisplay.
        this.acceptId = "";
        this.selectedItem = null;
    }

    public doAssessmentCleanup(event: Event): any
    {
        // Prevent default behavior when clicking a link
        event.preventDefault();
        this.server.deleteAssessment(this.selectedItem.id, this.userId).then(
            () => 
            {
                // Clear the existing value, so that we trigger a redisplay.
                this.selectedItem.isDeleted = true;
                this.acceptId = "";
            }
        );
    }
}   