import {autoinject} from 'aurelia-framework';
import {Router, RouteConfig} from 'aurelia-router';
import {AuthService} from 'aurelia-authentication';
import {GlobalState} from '../models/GlobalState';
import {ErrorObject} from '../models/ErrorObject';
import {QuestionServer} from '../server/QuestionServer';
import {backPage} from '../resources/navigation/NextPage'
import {CardSortAnalysisViewModel} from '../models/CardSortAnalysisViewModel';

@autoinject
@backPage()
export class HelpingCareersAnalysis extends CardSortAnalysisViewModel {

    constructor(protected router: Router, public globalState: GlobalState, protected server: QuestionServer, protected authService: AuthService) {
        super(router, globalState, server, authService);
        this.setId = "6"; // = Helping
    }

    public activate(params: any, route: RouteConfig): any {
        if (params.id && params.id.length >= 36)
        {
            if (!this.globalState.questionnaire.id || !this.globalState.questionnaire.id != params.id)
            {
                this.globalState.questionnaire.id = params.id;
                this.globalState.questionnaire.loaded = false;
            }
        } else if (this.globalState.Id)  
        {
            // Perform navigation so that we get the assessment Id present in the URL so that a refresh in the browser will not lose the clients page.
            this.router.navigateToRoute( route.name, { 'id': this.globalState.Id }, { trigger: false, replace: true });
        }

        if (this.globalState.questionnaire.id) 
        {
            return this.server.loadCareersAnalysisResults(this.globalState.questionnaire.id , this.globalState, this)
                .then(response => {
                    if (this.isQuestionnaireAnalysisUser)
                    {
                        this.isFullPrint = false;
                    }                         
                })
                .catch(reason => 
                {
                    this.errorObject = new ErrorObject(
                        reason,
                        this.globalState.questionnaire,
                        'Load card sort analysis results', 
                        'There has been an error reported attempting to load the analysis of the card data. Try again?');       
                    this.isBusy = false;
                });
        } 
        else
        {
            this.fullPrintOption = false;
        }
    }
}   

