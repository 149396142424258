import {autoinject, computedFrom} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {AccountsServer} from '../server/AccountsServer';
import {QuestionServer} from '../server/QuestionServer';
import {GlobalState} from '../models/GlobalState';
import {ErrorObject} from '../models/ErrorObject';
import {RiasecQuestionnaire} from '../models/RiasecQuestionnaire';

@autoinject
export class AccountsUpdateQuestionnire {

    public name: string = '';
    public isAnalysisViewable: boolean = false;
    public isCardSortOccupationRequired: boolean = false;
    public isCardSortHelpingRequired: boolean = false;
    public isCardSortBusinessRequired: boolean = false;
    public isCardSortEngineeringRequired: boolean = false;
    public isCardSortScienceRequired: boolean = false;
    public isCardSortArtsRequired: boolean = false;
    public isCardSortTradesRequired: boolean = false;
    public isCardSortCreativityRequired: boolean = false;
    public questionnaire: RiasecQuestionnaire;
    public errorObject: ErrorObject = null;
    public isResendBusy: boolean = false;
    public isUpdateBusy: boolean = false;

    constructor(protected router: Router, protected globalState: GlobalState, private server: AccountsServer, private questionServer: QuestionServer) {
    }

    public activate(params: any) {
        if (params.id)
        {
            this.globalState.questionnaire.id = params.id;
            this.globalState.questionnaire.loaded = false;
            this.questionServer.loadQuestionnaire(params.id, this.globalState)
                .then( questionniare => { 
                    this.questionnaire = questionniare;
                    this.AssignLocals();
                });
        }
        else
        {
            this.questionnaire = this.globalState.questionnaire;
            this.AssignLocals();
        }
    }

    private AssignLocals(): void
    {
        if (this.questionnaire && this.questionnaire.id && this.questionnaire.firstname)
        {
            let obj = this.questionnaire;
            this.name = obj.firstname + ' ' + obj.surname + ' (' + obj.emailAddress + ')';
            this.isAnalysisViewable = obj.isAnalysisViewable;
            this.isCardSortOccupationRequired = obj.isCardSortOccupationRequired;
            this.isCardSortHelpingRequired = obj.isCardSortHelpingRequired;
            this.isCardSortBusinessRequired = obj.isCardSortBusinessRequired;
            this.isCardSortEngineeringRequired = obj.isCardSortEngineeringRequired;
            this.isCardSortScienceRequired = obj.isCardSortScienceRequired;
            this.isCardSortArtsRequired = obj.isCardSortArtsRequired;
            this.isCardSortTradesRequired = obj.isCardSortTradesRequired;
            this.isCardSortCreativityRequired = obj.isCardSortCreativityRequired;
        }
    }

    public updateAccessibleViews(): any {
        if (!this.isUpdateBusy) {
            this.isUpdateBusy = true;
            this.errorObject = ErrorObject.EmptyError();
            this.server.updateQuestionnaireLink(this.questionnaire.id, this.isAnalysisViewable, this.isCardSortOccupationRequired, this.isCardSortHelpingRequired, this.isCardSortBusinessRequired, this.isCardSortEngineeringRequired, this.isCardSortScienceRequired, this.isCardSortArtsRequired, this.isCardSortTradesRequired, this.isCardSortCreativityRequired)
                .then(response => {
                        this.isUpdateBusy = false;
                    })
                .catch(reason => {
                    this.errorObject = new ErrorObject(
                        reason,
                        this,
                        'Update accessible questionniare pages', 
                        'Something happened when attempting to update the list of accessible pages. Try again? Probably not much you can do.');       
                    this.isUpdateBusy = false;
                });
        }
	}

    public resendEmailLink()
    {
        if (!this.isResendBusy) {
            this.errorObject = ErrorObject.EmptyError();
            this.isResendBusy = true;
            return this.server.resendQuestionnaireLink(this.questionnaire.id)
                .then(response => {
                    this.isResendBusy = false;
                })
                .catch(reason => 
                {
                    this.errorObject = new ErrorObject(
                        reason,
                        this,
                        'Resend questionnaire link', 
                        'Unable to re-send the questionnaire link due to a reported error. Try again? Probably not much you can do.');       
                    this.isResendBusy = false;
                });
        }
    }


    @computedFrom("questionnaire","questionnaire.id")
    public get isUpdateNotValid(): boolean {
        return (!this.questionnaire || !this.questionnaire.id);
    }
}
