import {autoinject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {AuthService} from 'aurelia-authentication';
import {backPage} from '../resources/navigation/NextPage'
import {GlobalState} from '../models/GlobalState';
import {QuestionServer} from '../server/QuestionServer';
import {CardSortIAAnalysisGroupedViewModel} from '../models/CardSortIAAnalysisGroupedViewModel';
import {Routes} from '../routes';

@autoinject
@backPage()
export class BusinessAnalysis extends CardSortIAAnalysisGroupedViewModel {

    constructor(protected router: Router, public globalState: GlobalState, protected server: QuestionServer, protected authService: AuthService) {
        super(router, globalState, server, authService, "5", Routes.IA_BUSINESS_INTRO);
    }
}    

