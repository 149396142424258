import {RiasecQuestionItem} from '../models/RiasecQuestionItem';

export class RiasecQuestionSet {

    public id: string;
    public heading: string;
    public questions: RiasecQuestionItem[];

    constructor() {
        this.questions = new Array<RiasecQuestionItem>();
    }

    public static toQuestionSet(obj: any): RiasecQuestionSet {
        var itm = new RiasecQuestionSet();
        itm.id = obj.id;
        itm.heading = obj.title;
        itm.questions = obj.questions.map(RiasecQuestionItem.toQuestionItem);
        return itm;
    }
}    