
export class Career {
    public careerWords: string ='';
    public careers: string = '';
    
    public static toCareer(obj: any): Career {
        let item = new Career();
        item.careerWords = obj.careerWords;
        item.careers = obj.careers;
        return item;
    }
}

