import {BindingEngine, autoinject} from 'aurelia-framework';
import {AuthService} from 'aurelia-authentication';
import {Router, RouteConfig} from 'aurelia-router';
import {GlobalState} from '../models/GlobalState';
import {ErrorObject} from '../models/ErrorObject';
import {QuestionServer} from '../server/QuestionServer';
import {CareerAttributeItem} from '../models/CareerAttributeItem';
import {CareerAttributeOptions} from '../models/CareerAttributeOptions';

@autoinject
export class CareerStatement {
    
    private index: number;
    public isNextPageDisabled: boolean = true;
    public nextPageBtn: HTMLButtonElement;
    public errorObject: ErrorObject = null;
    public isBusy: boolean;
    public isEndOfCards: boolean = false;
    public isOptionsDisplayed: boolean = false;
    public attributes: CareerAttributeItem[];
    public attribute: CareerAttributeItem;
    public attributeOptions: CareerAttributeOptions[];

    constructor(protected router: Router, public globalState: GlobalState,  protected server: QuestionServer, protected authService: AuthService, protected bindingEngine: BindingEngine) {
    }

    public activate(params: any, route: RouteConfig): any {
        if (params.id && params.id.length >= 36)
        {
            if (!this.globalState.questionnaire.id || !this.globalState.questionnaire.id != params.id)
            {
                this.globalState.questionnaire.id = params.id;
                this.globalState.questionnaire.loaded = false;
            }
        } else if (this.globalState.Id)  
        {
            // Perform navigation so that we get the assessment Id present in the URL so that a refresh in the browser will not lose the clients page.
            this.router.navigateToRoute( route.name, { 'id': this.globalState.Id }, { trigger: false, replace: true });
        }

        this.index = 0;
    }

    public attached(): any {
        this.globalState.clearNowVersion();

        if (this.globalState.questionnaire.id) 
        {
            return this.server.loadAttributesData(this.globalState.questionnaire, this.globalState)
                .then(obj => {
                    this.attributes = obj;
                    this.attribute = null;
                    if ((this.attributes) && (this.attributes.length > 0))
                    {
                        this.attribute = this.attributes[this.index];
                    }

                    return obj;
                })
                .catch(reason => 
                {
                    this.errorObject = new ErrorObject(
                        reason,
                        this.globalState.questionnaire,
                        'Load Career Defining Attributes', 
                        'There has been an error reported attempting to load the assessment career attributes. Try again?');       
                    this.isBusy = false;
                    return reason;
                });
        } 
    }
}    

