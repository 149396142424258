import {autoinject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {AuthService} from 'aurelia-authentication';
import {nextPage} from '../resources/navigation/NextPage'
import {GlobalState} from '../models/GlobalState';
import {QuestionServer} from '../server/QuestionServer';
import {CardSortAnalysisGroupedViewModel} from '../models/CardSortAnalysisGroupedViewModel';
import {Routes} from '../routes';

@autoinject
@nextPage(Routes.CAREERS_CREATIVITY)
export class CreativitySingleCardSortResults extends CardSortAnalysisGroupedViewModel {

    constructor(protected router: Router, public globalState: GlobalState, protected server: QuestionServer, protected authService: AuthService) {
        super(router, globalState, server, authService);
        this.setId = "12";
    }
}    
