import {inject} from 'aurelia-framework';
import {Endpoint, Rest} from 'aurelia-api';


const questionnaireRegisterUri: string = 'issue';

@inject(Endpoint.of("tokens"))
export class TokenServer {

    constructor(private endpoint: Rest) {
    }

    public questionnaireRegister(emailAddress: string, questionnaireToken: string, aversion: string, ahash: string): Promise<any | Error> {
        // Post the data to the registration end point in www-form-urlencoded format so that the Auth pipeline can use the data
        // This returns a JWT access token that is reqired to grant access to complete the questionniare.
        return this.endpoint.post(questionnaireRegisterUri + '?a='+Math.floor(Math.random() * 100000).toString(), {
            username: emailAddress,
            password: questionnaireToken,
            version: aversion,
            hash: ahash,
            grant_type: "password"
        }, 
        {
            headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                     }
        });
    }
}
