import {autoinject, computedFrom} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {ErrorObject} from '../models/ErrorObject';
import {AccountsServer} from '../server/AccountsServer';
import {GlobalState} from '../models/GlobalState';
import {backPage} from '../resources/navigation/NextPage'

@autoinject
@backPage()
export class AccountsSendQuestionnireLink {

    public emailAddress: string = '';
    public firstName: string = '';
    public surname: string = '';
    public isIndependentAssessment: boolean = false;
    public appointment: string = new Date().toISOString().substring(0, 10);
    public errorObject: ErrorObject = null;
    public isBusy: boolean = false;

    constructor(protected router: Router, protected globalState: GlobalState, private server: AccountsServer) {
    }

    public generateLink(): any {
        if (!this.isBusy) {
            this.isBusy = true;
            this.errorObject = ErrorObject.EmptyError();
            this.server.sendQuestionnaireLink(this.emailAddress, this.firstName, this.surname, this.isIndependentAssessment, (this.appointment) ? new Date(this.appointment) : null)
                .then(response => {
                    this.isBusy = false;
                    this.router.navigateBack();
                })
                .catch(reason => {
                    this.errorObject = new ErrorObject(
                        reason,
                        this,
                        'Send Email Containing Link', 
                        'There was an issue sending the email containing the link to the questionnaire user. Try again? Probably not much you can do.');       
                    this.isBusy = false;
                });
        }
	}

    @computedFrom("firstName","emailAddress","surname")
    public get isSendNotValid(): boolean {
        return (!this.firstName || !this.surname || !this.emailAddress || this.emailAddress.length < 5);
    }
}
