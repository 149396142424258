import {RiasecQuestionnaire} from 'models/RiasecQuestionnaire';
import {Routes} from 'routes';
import {SpecialisedPages} from '../models/SpecialisedPages';

export class GlobalState {
    public userId: string;
    public email: string = '';
    public phone: string;
    public sessionId: string;
    public questionnaire: RiasecQuestionnaire;
    public version: string;
    public firstname: string;
    public surname: string;
    public now: string;
    public users: any[] = [];
    public specialisedPageList: SpecialisedPages[] = [];

     /**
     * Used to lookup the next specialised occupation card sort page. Returns the SpecialisedPages of the next page.
     * Input is the current page route (intro).
     *
     * @param {string}              questionnaireId   The questionnaire identifier for the clients session
     *
     * @return {SpecialisedPages}       The next SpecialisedPages
     */
    public nextSpecialisedPage: SpecialisedPages[] = [];

    constructor()
    {
        this.questionnaire = new RiasecQuestionnaire();
    }

    public clearFooterDetails()
    {
        this.firstname = '';
        this.surname = '';
        this.now = '';
        this.version = '';
    }
    
    public clearNowVersion()
    {
        this.now = '';
        this.version = '';
    }

    public buildSpecialisedPages( specialisedList: SpecialisedPages[] )
    {
        // this is dealing with the data returned by the server ...
        let currentRoute = Routes.IA_SPECIALISED_INTRO.route;

        // Note: We build a forward lsit from the current page to the next page ...
        specialisedList.filter(itm => itm.include).forEach( item => {
            this.nextSpecialisedPage[currentRoute] = item;
            currentRoute = item.routeRef.route;
        });

        let endPage = new SpecialisedPages();
        endPage.routeRef = Routes.IA_SPECIALISED_OPTIONAL;
        endPage.description = 'Optional Card Sorts';

        this.nextSpecialisedPage[currentRoute] = endPage;

        specialisedList.filter(itm => !itm.include).forEach( item => {
            this.nextSpecialisedPage[item.routeRef.route] = endPage;
        });
    }

    //
    //  Assign the common fields sent from the MutationObserver.
    //
    public assignCommonData(obj: any): void {
        this.firstname = obj.firstname;
        this.surname = obj.surname;
        this.version = obj.version;
        this.now = obj.completedAt;
        if ((obj.pagesVisited) && (obj.id) && (obj.isIndependentAssessment) && (this.questionnaire))
        {
            this.questionnaire.id = obj.id;
            this.questionnaire.pagesVisited = obj.pagesVisited;
            this.questionnaire.isIndependentAssessment = obj.isIndependentAssessment;
        }
    }

    public assignNames(obj: any): void {
        this.firstname = obj.firstname;
        this.surname = obj.surname;
        this.version = obj.version;
        this.now = obj.completedAt;
        this.questionnaire.id = obj.id;
        this.questionnaire.version = obj.version;
        this.questionnaire.pagesVisited = obj.pagesVisited;
        this.questionnaire.isIndependentAssessment = obj.isIndependentAssessment;
    }

    public get Id(): string
    {
        return ((this.questionnaire) && (this.questionnaire.id)) ? this.questionnaire.id : "";
    }
}    

export interface IGlobalState {
    globalState: GlobalState;
}
