import {autoinject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {AuthService} from 'aurelia-authentication';
import {GlobalState} from 'models/GlobalState';
import {QuestionServer} from 'server/QuestionServer';
import {nextPage} from 'resources/navigation/NextPage'
import {CardSortAnalysisViewModel} from 'models/CardSortAnalysisViewModel';
import {Routes} from '../routes';

@autoinject
@nextPage(Routes.IA_SPECIALISED_INTRO.urlFragment)
export class OccupationalAnalysis extends CardSortAnalysisViewModel {

    constructor(protected router: Router, public globalState: GlobalState, protected server: QuestionServer, protected authService: AuthService) {
        super(router, globalState, server, authService);
        this.setId = "7";
    }
}   

