import {autoinject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {AuthService} from 'aurelia-authentication';
import {GlobalState, IGlobalState} from '../models/GlobalState';
import {QuestionServer} from '../server/QuestionServer';
import {ErrorObject} from '../models/ErrorObject';
import {backPage} from '../resources/navigation/NextPage'
import {CareerTheme, ICareerThemes} from '../models/CareerTheme';
import {CardSortAnalysisGroupedViewModel} from '../models/CardSortAnalysisGroupedViewModel';
import {Routes} from '../routes';

@autoinject
@backPage()
export class TradesCareers extends CardSortAnalysisGroupedViewModel implements ICareerThemes, IGlobalState  {

    public themedCareers: CareerTheme[] = [];
    protected setId: string = '11';
    public errorObject: ErrorObject = null;
    public isBusy: boolean;

    constructor(protected router: Router, public globalState: GlobalState, protected server: QuestionServer, protected authService: AuthService) {
        super(router, globalState, server, authService);
        this.nextSpecialisedPage = this.globalState.nextSpecialisedPage[Routes.IA_TRADES_INTRO.route];
    }

    public activate(): any {
        if (this.globalState.questionnaire.id) 
        {
            return this.server.loadCareerThemeReport(this.globalState.questionnaire.id , this.setId, this.globalState, this)
                .catch(reason => 
                {
                    this.errorObject = new ErrorObject(
                        reason,
                        this.globalState.questionnaire,
                        'Load Themed Career Report', 
                        'There has been an error reported attempting to load the themed career report analysis of the card data. Try again?');       
                    this.isBusy = false;
                });
        } 
    }

    public attached(): void{
        // If a refresh is done then global state gets cleared ... so reload it.
        this.checkNextSpecialisedPage(Routes.IA_TRADES_INTRO.route);
    }

    public nextPage(): boolean {
        return this.router.navigateToRoute( this.nextSpecialisedPage.routeRef.route, { 'id': this.globalState.Id }, { trigger: true, replace: false });
    }
}   
