import {bindable, autoinject, computedFrom} from 'aurelia-framework';
import {DOM} from 'aurelia-pal'

@autoinject
export class AcceptHandlerCustomElement {
    @bindable 
    public id: any = null;
    @bindable 
    public description: string = null;
    @bindable 
    public percentage: number = 40;
    @bindable 
    public acceptPrompt: string = "Accept";
    public isVisible: boolean = null;
    public acceptEvent: CustomEvent;
    public cancelEvent: CustomEvent;

    constructor(private readonly element: Element) {
        this.acceptEvent = DOM.createCustomEvent('onaccept', { bubbles: true, cancelable: true, detail: { id: this.id} });
        this.cancelEvent = DOM.createCustomEvent('oncancel', { bubbles: true, cancelable: true, detail: { id: this.id} });
    }

    public accept(): void {
        this.isVisible = false;
        this.element.dispatchEvent(this.acceptEvent);
    }

    public close(): void {
        this.isVisible = false;
        this.element.dispatchEvent(this.cancelEvent);
    }

    @computedFrom("percentage")   
    public get topPosition(): string
    {
        let topPosition = Math.max(10, this.percentage);
        topPosition = Math.min(90, topPosition);
        return "top: "+ topPosition.toString() + "%";
    }

    public idChanged(value: any, oldValue: any)
    {
         // We only want to stop the user if they have full access - ie sitting with a client in a session.
         if (value != oldValue && value)
         {
            // Stop the user, present the dialog.
            this.isVisible = true;
         }
    }
}

