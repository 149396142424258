import {NavModel} from 'aurelia-router';


Object.defineProperty(NavModel.prototype, 'childIsActive', { value: function() {

    let result: boolean = false;
    if (((this).children) && (this.children.length > 0))
    {
        this.children.forEach((itm) => {
            result = result || itm.isActive;
        });
    
    }

    return result;
}
});