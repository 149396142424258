import {autoinject, Disposable} from 'aurelia-framework';
import {Router, RouteConfig} from 'aurelia-router';
import {backPage} from '../resources/navigation/NextPage'
import {ErrorObject} from '../models/ErrorObject';
import {GlobalState} from '../models/GlobalState';
import {QuestionServer} from '../server/QuestionServer';

@autoinject
@backPage()
export class UserCardCardSortResults {

    private subscription: Disposable;
    public isBusy: boolean = false;
    public errorObject: ErrorObject = null;


    constructor(protected router: Router, public globalState: GlobalState, protected server: QuestionServer) {
    }

    public activate(params: any, route: RouteConfig): void
    {
        if (params.id && params.id.length >= 36)
        {
            if (!this.globalState.questionnaire.id || !this.globalState.questionnaire.id != params.id)
            {
                this.globalState.questionnaire.id = params.id;
                this.globalState.questionnaire.loaded = false;
            }
        } else if (this.globalState.Id)  
        {
            // Perform navigation so that we get the assessment Id present in the URL so that a refresh in the browser will not lose the clients page.
            this.router.navigateToRoute( route.name, { 'id': this.globalState.Id }, { trigger: false, replace: true });
        }
    }

    public deactivate(): any 
    {
        this.subscription?.dispose();
        this.subscription = null;
    }

}    
