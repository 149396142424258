import {BindingEngine, autoinject, Disposable} from 'aurelia-framework';
import {Router, RouteConfig} from 'aurelia-router';
import {AuthService} from 'aurelia-authentication';
import {backPage} from '../resources/navigation/NextPage'
import {ErrorObject} from '../models/ErrorObject';
import {GlobalState} from '../models/GlobalState';
import {QuestionServer} from '../server/QuestionServer';
import {CardSortAnalysisGroupedViewModel} from '../models/CardSortAnalysisGroupedViewModel';
import {ScaleResult} from '../models/ScaleResult';

@autoinject
@backPage()
export class UserCardCardSortResults extends CardSortAnalysisGroupedViewModel {

    private fileURL: any;
    public isBusy: boolean = false;
    public analysisOption: number = 2;
    public selectedItems: ScaleResult[] = [];
    public summaryItems: ScaleResult[] = [];
    public printing: boolean = false;
    private infixup: boolean = false;
    private inPrePop: boolean = false;
    private subscription: Disposable;


    constructor(protected router: Router, public globalState: GlobalState, protected server: QuestionServer, protected authService: AuthService, protected bindingEngine: BindingEngine) {
        super(router, globalState, server, authService);
        this.setId = "UserTitles";
    }

    public activate(params: any, route: RouteConfig): any 
    {
        if (params.id && params.id.length >= 36)
        {
            if (!this.globalState.questionnaire.id || !this.globalState.questionnaire.id != params.id)
            {
                this.globalState.questionnaire.id = params.id;
                this.globalState.questionnaire.loaded = false;
            }
        } else if (this.globalState.Id)  
        {
            // Perform navigation so that we get the assessment Id present in the URL so that a refresh in the browser will not lose the clients page.
            this.router.navigateToRoute( route.name, { 'id': this.globalState.Id }, { trigger: false, replace: true });
        }
        
        return this.optionSelected();
    }

    public deactivate(): any 
    {
        this.subscription?.dispose();
        this.subscription = null;
        if (this.fileURL)
        {
            window.URL.revokeObjectURL(this.fileURL);
        }
    }

    public optionSelected(): any {
        if (this.globalState.questionnaire.id) {
            return this.server.loadUserCardTitlesAnalysis(this.globalState.questionnaire.id, this.analysisOption.toString(), this.globalState, this.results)
                .then(results =>
                    {
                        this.sortItems();
                    })
                .catch(reason => 
                {
                    this.errorObject = new ErrorObject(
                        reason,
                        this.globalState.questionnaire,
                        'Load User Titles Analysis', 
                        'There has been an error reported attempting to load the analysis of the card data. Try again?');       
                });
        }
    }

    public selectedItemsChanged(slices: any): void
    {
        slices.forEach(itm => {
            if (itm.removed)
            {
                itm.removed.forEach(result => 
                { 
                    result.includeDescription = result.description;
                    result.include = false;
                    result.description = "";
                });
            }

            if (itm.addedCount > 0)
            {
                let item = this.selectedItems[itm.index];
                item.include = true;
                item.description = item.includeDescription ?? "";
            }
        });

        this.sortItems();
    }

    public sortItems(): void
    {
        if (!this.infixup)
        {
            this.infixup = true;
            this.subscription?.dispose();
            this.subscription = null;
            try
            {
                // Sort based on assumed numbers in titles. But make sure there are items with numbers first,
                // else we leave the sorted order unchanged - until there are numbers present.
                let sortedItems = Array.from(this.results.scaleResults);

                sortedItems.sort((a, b) => 
                { 
                    if (!a.include && b.include) return 1;
                    if (a.include && !b.include) return -1;
                    if (a.sortValue < b.sortValue) return -1;
                    if (a.sortValue > b.sortValue) return 1;
                    if (a.ratio < b.ratio) return 1;
                    if (a.ratio > b.ratio) return -1;
                    return 0;
                });

                this.summaryItems = Array.from(this.results.scaleResults);
                this.summaryItems.sort((a, b) => 
                { 
                    if (a.ratio < b.ratio) return 1;
                    if (a.ratio > b.ratio) return -1;
                    if (a.sortDescription < b.sortDescription) return 1;
                    if (a.sortDescription > b.sortDescription) return -1;
                    return 0;
                });


                let items = [];
                sortedItems.forEach((itm) => 
                    { 
                        if (itm.include) {
                            items.push(itm);
                        }
                    });

                this.selectedItems = items;    
                this.results.scaleResults = sortedItems;
            }
            finally
            {
                this.subscription = this.bindingEngine.collectionObserver(this.selectedItems).subscribe((slices) => 
                {
                    this.selectedItemsChanged(slices);
                });

                this.infixup = false;
            }
        }
    }

    public sortAndPrint(): void
    {
        //this.sortItems();

        // Print the current page
        this.printing = true;
        window.setTimeout(() => 
            {
                window.print();
                this.printing = false;
            }
            , 200);
    }

    public prePopulateStatement(event: Event):any
    {
        if (!this.inPrePop)
        {
            this.inPrePop = true;
            try
            {
                let text = "";
                // Sort based on assumed numbers in titles. But make sure there are items with numbers first,
                // else we leave the sorted order unchanged - until there are numbers present.
                let sortedItems = Array.from(this.results.scaleResults);
                let sortRequired = false;
                sortedItems.forEach((itm) => 
                    { 
                        sortRequired = sortRequired || itm.sortValue != 222;
                    });

                if (sortRequired)
                {
                    sortedItems.sort((a, b) => 
                    { 
                        if (!a.include && b.include) return 1;
                        if (a.include && !b.include) return -1;
                        if (a.sortValue < b.sortValue) return -1;
                        if (a.sortValue > b.sortValue) return 1;
                        if (a.ratio < b.ratio) return 1;
                        if (a.ratio > b.ratio) return -1;
                        return 0;
                    });
                }

                sortedItems.forEach((itm) => 
                    { 
                        if (itm.sortValue != 222)
                        {
                            text = text + itm.description + ' ';
                        }
                    });


                this.results.notes = text;
            }
            finally
            {
                this.inPrePop = false;
            }
        }
    }

    public downloadReport(event: Event):any
    {
        // Prevent default behavior when clicking a link
        event.preventDefault();

        if (this.fileURL)
        {
            window.URL.revokeObjectURL(this.fileURL);
        }

        // Get filename from href
        let careerElement = <Node>event.target;
        (<any>careerElement).Disabled = true;
        let busyIndicator = document.createElement('i');
        busyIndicator.className = 'fa fa-spinner fa-spin busy-indicator';
        careerElement.appendChild(busyIndicator);

        this.server.loadCareerReport(this.globalState.questionnaire.id)
                .then(blob => {

                    careerElement.removeChild(busyIndicator);

                    // IE doesn't allow using a blob object directly as link href
                    // instead it is necessary to use msSaveOrOpenBlob
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob);
                        return;
                    } 

                    this.fileURL = window.URL.createObjectURL(blob);

                    let element = document.createElement('a');
                    element.href = this.fileURL;
                    element.download = this.globalState.firstname + "." +  this.globalState.surname + ".CareerReport.docx";
                    element.style.display = 'none';
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);
                 })
                .catch(response => {
                    careerElement.removeChild(busyIndicator);
                    this.errorObject = new ErrorObject(
                        response,
                        null,
                        "Generate Career Report", 
                        "Failed to download the generated report document.");       
                });
    }

    public savePage(): any {
        if (!this.isBusy) {
            this.isBusy = true;
            this.errorObject = ErrorObject.EmptyError();
            return this.server.saveUserTitles(
                this.globalState.questionnaire.id,
                { items: this.results.scaleResults,
                  selection: this.analysisOption,
                  summary: this.results.notes}, this.results)
                .then(response => {
                    this.isBusy = false;
                })
                .catch(reason =>
                {
                    this.errorObject = new ErrorObject(
                        reason,
                        this,
                        "Save User Titles", 
                        "Could not save the changes made to the user titles." );        
                    this.isBusy = false;
                });
        }
    }
}    
