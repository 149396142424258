import {autoinject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {AuthService} from 'aurelia-authentication';
import {GlobalState} from '../models/GlobalState';
import {QuestionServer} from '../server/QuestionServer';
import {CardSortAnalysisGroupedViewModel} from './CardSortAnalysisGroupedViewModel';
import {SpecialisedPages} from '../models/SpecialisedPages';
import {RouteCnfg} from '../routes';

@autoinject
export class CardSortIAAnalysisGroupedViewModel extends CardSortAnalysisGroupedViewModel {

    public nextSpecialisedPage: SpecialisedPages;

    constructor(protected router: Router, public globalState: GlobalState, protected server: QuestionServer, protected authService: AuthService, setIdParam: string, protected introRoute: RouteCnfg) {
        super(router, globalState, server, authService);
        this.setId = setIdParam;

        if (this.introRoute)
        {
            this.nextSpecialisedPage = this.globalState.nextSpecialisedPage[this.introRoute.route];
        }
    }

    public attached(): any
    {
        // If a refresh is done then global state gets cleared ... so reload it.
        if (this.introRoute)
        {
            this.checkNextSpecialisedPage(this.introRoute.route);
        }
    }

    public nextPage(): boolean {
        return this.router.navigateToRoute( this.nextSpecialisedPage.routeRef.route, { 'id': this.globalState.Id }, { trigger: true, replace: false });
    }
}    

