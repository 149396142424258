import {autoinject, computedFrom} from 'aurelia-framework';
import {AuthService} from 'aurelia-authentication';
import {questionnaireUserRole, questionnaireAnalysisRole, standardUserRole, assessmentUserRole, administratorUserRole} from '../models/SecurityRoles';


@autoinject
export class CommonRoles {

    protected readonly roles: string[] = [];

    constructor(protected authService: AuthService) {
        let payload = <any>this.authService.getTokenPayload();
        if (payload && payload.role)   
        {
            if (typeof(payload.role) === 'string')
            {
                this.roles = payload.role.split(',');
            }
            else if (Array.isArray(payload.role))
            {
                this.roles = payload.role;  
            }
        }
    }


  /**
     * Determine if the user is authenticated as a plain "User"
     *
     * @return {boolean} Returns true if the user is authenticated and has the role "User"
     */
   @computedFrom("roles")
    public get isAuthenticated(): boolean {
        return this.roles.some((itm) => itm === standardUserRole);
    }

  /**
     * Determine if the user is authenticated as only a "QuestionnaireUser"" and not a plain "User"
     *
     * @return {boolean} Returns true if the user is authenticated and has the role QuestionnaireUser" and does not have the role "User".
     */
   @computedFrom("roles")
    public get isQuestionnaireUser(): boolean {
        return this.roles.some((itm) => itm === questionnaireUserRole) && !this.roles.some((itm) => itm === standardUserRole);
    }

    @computedFrom("roles")
    public get isFullAccess(): boolean
    {
        return this.roles.some((itm) => itm === standardUserRole);
    }

    @computedFrom("roles")
    public get isReportAccess(): boolean
    {
        return this.roles.some((itm) => itm === standardUserRole || itm === questionnaireAnalysisRole);
    }

  /**
     * Determine if the user is authenticated as only a "QuestionnaireUser"" and not a plain "User"
     *
     * @return {boolean} Returns true if the user is authenticated and has the role QuestionnaireUser" and does not have the role "User".
     */
   @computedFrom("roles")
   public get isQuestionnaireAnalysisUser(): boolean {
        return this.roles.some((itm) => itm === questionnaireAnalysisRole);
   }

/**
     * Determine if the user is authenticated as only a "AssessmentUser" and not a plain "User"
     *
     * @return {boolean} Returns true if the user is authenticated and has the role QuestionnaireUser" and does not have the role "User".
     */
    @computedFrom("roles")
    public get isAssessmentUser(): boolean {
        return this.roles.some((itm) => itm === assessmentUserRole) && !this.roles.some((itm) => itm === standardUserRole);
    }

    @computedFrom("roles")
    public get isAdministrator(): boolean {
        return this.roles.some((itm) => itm === standardUserRole ) && this.roles.some((itm) => itm === administratorUserRole) && !this.roles.some((itm) => itm === questionnaireUserRole) && !this.roles.some((itm) => itm === assessmentUserRole);
    }
}     

