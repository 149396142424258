import {CareerAttributeOptions, CareerAttributeOptionContainer } from './CareerAttributeOptions';

export class CareerAttributeUserSelection {

    public description: string = '';
    public options: CareerAttributeOptions[];

    public CareerAttributeItem()
    {
    }

    public static toCareerAttributeUserSelection(obj: any): CareerAttributeUserSelection {
        let item = new CareerAttributeUserSelection();
        item.description = obj.description;// + obj.options.description;
        item.options = [];

        let optionsContainers = obj.options.map(CareerAttributeOptionContainer.toCareerAttributeOptionContainer);

        optionsContainers.forEach( itm => {
            item.options = item.options.concat(itm.options);
        });

        return item;
    }

}    
