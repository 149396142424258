import {autoinject, computedFrom} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {GlobalState} from '../models/GlobalState';
import {AdministrationServer} from '../server/AdministrationServer';
import {backPage} from '../resources/navigation/NextPage'
import {ErrorObject} from '../models/ErrorObject';

@autoinject
@backPage()
export class AssessmentCopy {

    public errorObject: ErrorObject = null;
    public isBusy: boolean = false;
    public sourceId: string = "";
    public targetId: string = "";
    public targetUserId: string = '';
    public firstname: string = '';
    public surname: string = '';
    public excludeStatement: boolean = false
    public asIndependentAssessment: boolean = false;


    constructor(protected router: Router, protected globalState: GlobalState, private server: AdministrationServer) {
        this.globalState.clearFooterDetails();
    }

    @computedFrom("sourceId", "targetId", "targetUserId", "firstname", "surname")
    public get isInputNotValid(): boolean {
        return (this.sourceId == null) || (this.sourceId.length != 36) || (this.firstname == null) || (this.surname == null) ||
               ((this.targetId == null) && (this.targetUserId == null));
    }

    public doAssessmentCopy(event: Event): any 
    {
        event.preventDefault();

        this.isBusy = true;
        return this.server.doAssessmentCopy( 
            {
                sourceId: this.sourceId,
                targetId: null,
                userId: this.targetUserId,
                firstname: this.firstname,
                surname: this.surname,
                excludeStatement: this.excludeStatement,
                asIndependentAssessment: this.asIndependentAssessment
            })
            .then(response => {
                this.isBusy = false;
                this.targetUserId = '';
            })
            .catch(response => {
                this.isBusy = false;
                this.errorObject = new ErrorObject(
                    response,
                    null,
                    "Assessment Copy", 
                    "The copy of the assessment to the specified user has failed.");       
            });
    }
}   