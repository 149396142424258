
export class CardSortAnswerEx {
    public isSelected: boolean = false;
    public isOtherItem: boolean = false;
    public text: string;
    public id: number;

    constructor() {
    }

    public get uiClass(): string {
        if (this.isSelected) {
            return 'definite';
        }

        return '';
    }
}    