import {bindable} from 'aurelia-framework';
import {ErrorObject} from 'models/ErrorObject';

export class ErrorHandlerCustomElement {
    @bindable 
    public errorObject: ErrorObject = null;
    public action: string = null;
    public message: string = null;
    public errorList: ErrorObject[] = null;
    public isErrorIndicator: boolean = false;
    private isErrorIndicatorSet: boolean = false;
    private isMinimumElaspedTimeActive: boolean = false;
    private timeoutHandle: number;

    constructor() {
    }

    public detached(): void
    {
        if (this.timeoutHandle) { 
          window.clearTimeout(this.timeoutHandle);
        }
    }

    public close(): void {
        this.isErrorIndicatorSet = false;
        this.isErrorIndicator = false;
    }

    public errorObjectChanged(value: ErrorObject, oldValue: any)
    {
        // Check if we have been asked to immediatly clear the error display.
        if (value && value.errorObj === null && value.sourceObj === null)
         {

            this.isErrorIndicatorSet = false;
            this.isErrorIndicator = false;
            return;
         }

         // We have this complex logic to allow the spinner to rotate for a minimum of 1 second - else it disappears too fast!
         this.isErrorIndicatorSet = !!(value);
         if (value) {
            if (value.code == "402")
            {
                value.message = "The expected payment for the Insight Assessment has not yet been received."
                value.explanation = "Payment due";
            }

            //console.error(value);
            if (this.errorList) {
               this.errorList.unshift(value);
            }
            else {
                this.errorList = [value];
            }
            
             this.isMinimumElaspedTimeActive = true;
             if (this.timeoutHandle) { 
                window.clearTimeout(this.timeoutHandle);
             }

             this.timeoutHandle = window.setTimeout(() => {
               this.isMinimumElaspedTimeActive = false;
               this.isErrorIndicator = this.isErrorIndicatorSet;
               this.timeoutHandle = null;
             }, 10000);
         }

         this.isErrorIndicator = this.isErrorIndicatorSet || this.isMinimumElaspedTimeActive;
     }

}
