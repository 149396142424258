import {autoinject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {SecurityRoles} from '../models/SecurityRoles';
import {GlobalState} from '../models/GlobalState';
import {CardSortCore} from '../models/CardSortCore';
import {QuestionServer} from '../server/QuestionServer';
import {backPage, nextPage, clearFullDisplay} from '../resources/navigation/NextPage';
import {Routes} from '../routes';

@autoinject
@backPage()
@clearFullDisplay(true)
@nextPage(Routes.IA_ARTS_MATRIX.urlFragment)
export class ArtsCards extends CardSortCore {
    
    constructor(router: Router, public globalState: GlobalState, server: QuestionServer, public securityRoles: SecurityRoles) {
        super(router, globalState, server, securityRoles);
        this.setIdFragment = "10";
        this.isNextPageVisible = true;
        this.isIndependentAssessment = true;
    }
}
