import {inject} from 'aurelia-framework';
import {Endpoint, Rest} from 'aurelia-api';
import {BillingInfo} from '../models/BillingInfo';

const registerUri: string = 'register';
const registerUserUri: string = 'userregistration';
const profileUri: string = 'profile';
const loadUserUri: string = 'user/';
const loadUsersUri: string = 'users';
const assessmentCopyUri: string = 'assessmentCopy';
const billingUri: string = 'billing';
const reportTemplateUri: string = 'reportTemplate';

@inject(Endpoint.of("administration"))
export class AdministrationServer {

    constructor(private endpoint: Rest) {
        (this.endpoint.defaults as any).retries = 3;
    }

    public register(registrationDetails: any): Promise<any> {
        return this.endpoint.post(registerUri, registrationDetails);
    }

    public getAccountProfile(): Promise<any> {
        return this.endpoint.find(profileUri);
    }

    public loadUsers(): Promise<any> {
        return this.endpoint.find(loadUsersUri)
        .then(data => {
            let users = data;
            for (let i = 0; i < users.length; i++)
            {
                let rem = i % 4;
                users[i].css = (rem < 2) ? 'oddline' : 'evenline';
            }

            return users;
        });
    }
    
    public uploadReportTemplateFile(userId: string, file: FormData): Promise<any> 
    {
        return this.endpoint.client.post(reportTemplateUri+ '/' +userId, file);
    }

    public newBillingCycle(): Promise<BillingInfo|Error|any> 
    {
        return this.endpoint.post(billingUri)
        .then(obj => {
            let result = BillingInfo.toBillingInfo(obj);
            return result;
        });
    }

    public loadBillingInfo(billingCycleId: number, company: string): Promise<BillingInfo|any> {
        return this.endpoint.find(billingUri, {billingCycleId: billingCycleId, company: company})
        .then(obj => {
            let result = BillingInfo.toBillingInfo(obj);
            return result;
        });
    }

    public alterBillingState(billingCycleId: number, billingItemId:number, assessmentId: number, isReportCharged: boolean, isAssessmentCharged: boolean): Promise<any> {
        return this.endpoint.update(billingUri+'/'+billingCycleId+'/'+billingItemId+'/'+assessmentId+'/'+isReportCharged+'/'+isAssessmentCharged)
        .then(data => {
            return data;
        });
    }

    public loadUser(userId: string): Promise<any> {
        return this.endpoint.find(loadUserUri + userId);
    }

    public saveUser(userId: string, info: any): Promise<any> {
        return this.endpoint.update(loadUserUri + userId, null, info);
    }

    public doAssessmentCopy(info: any): Promise<any> {
        return this.endpoint.post(assessmentCopyUri, info );
    }

    public saveAccountProfile(usrEmail: string, usrName: string, usrPhoneNumber: string, usrSurname: string, usrWebsite: string, usrCompany: string, usrReportKnownAs: string, updLinkBody: string, updLinkSubject: string): Promise<any> {
        return this.endpoint.update(profileUri, null,
            {
                userEmail: usrEmail, 
                knownAsName: usrName, 
                surname: usrSurname, 
                reportKnownAsName: usrReportKnownAs, 
                website: usrWebsite,
                company: usrCompany,
                phoneNumber: usrPhoneNumber,
                linkBody: updLinkBody,
                linkSubject: updLinkSubject
            });
    }

    public registerUser(usrEmail: string, usrName: string, usrPhoneNumber: string): Promise<any> {
        return this.endpoint.post(registerUserUri,
            {
                userEmail: usrEmail, 
                knownAsName: usrName, 
                phoneNumber: usrPhoneNumber
            });
    }

}

