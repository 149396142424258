import {observable} from 'aurelia-framework';
import {CardSortItem} from '../models/CardSortItem';
import {CardSortAnswerEx} from '../models/CardSortAnswerEx';


export class CardSortItemEx extends CardSortItem {
    public answerText: string;

    @observable
    public otherText: string;
    public additionalText: string;
    public definition: string;
    public meanings: CardSortAnswerEx[];
    private otherMeaning: CardSortAnswerEx;

    public static toCardSortItemEx(obj: any): CardSortItemEx {
        let anythingSelected = false;
        let item = new CardSortItemEx();
        if (obj instanceof CardSortItemEx)
        {
            Object.assign(item, obj);
            return item;
        }

        item.doObjToCardSortItem(obj);
        item.definition  = obj.definition;
        item.answerText  = obj.answerText;
        item.answerCode  = obj.answerCode;
        item.answerIndex = obj.answerIndex;
        item.additionalText = obj.answerAdditional;
        if (obj.meanings)
        {
            item.meanings = obj.meanings.map(itm =>
            {
                if (itm instanceof CardSortAnswerEx)
                {
                    return itm;
                }
                else
                {
                    let ans = new CardSortAnswerEx();
                    ans.id = itm.id;
                    ans.text = itm.meaning;
                    ans.isOtherItem = ans.text.startsWith("Other");
                    ans.isSelected = item.answerCode >= '1' && item.answerCode <= '9' ? item.answerCode == ans.id.toString() : ans.text == item.answerText;
                    anythingSelected = anythingSelected || ans.isSelected;
                    return ans;
                }
            });

            if (obj.answerIndex)
            {
                let selectedItem = item.meanings.find(itm => itm.id === obj.answerIndex);
                if (selectedItem)
                {
                    selectedItem.isSelected = true;
                }
            }

            item.otherMeaning = item.meanings.find(itm => itm.isOtherItem);
            if (item.otherMeaning && !anythingSelected)
            {
                item.otherMeaning.isSelected = item.answerText > ' ';
                item.otherText = item.otherMeaning.isSelected ? item.answerText : null;
            }
        }

        return item;
    }

    public DoSelectMeaning(meaning: CardSortAnswerEx): void
    {
        if (!meaning.isSelected)
        {
            // We only want to trigger this code on a change, as this gets called a bit more frequently than desired ...
            // This also causes UI effects in terms of changing the classes present on the elements
            this.meanings.forEach(itm => itm.isSelected = false);
        }

        if (this.otherText)
        {
            // Remove any keyed text so that the UI placeholder gets exposed again.
            this.otherText = null;
        }

        this.answerText = meaning.text;
        this.answerCode = meaning.id.toString();
        this.answerIndex = meaning.id; 
        meaning.isSelected = true;
    }

    // This is called by the aurelia binding system on a change to the "otherText" property.
    public otherTextChanged(): void
    {
        if (this.otherMeaning)
        {
            let newValue = (this.otherText > ' ');
            if (newValue != this.otherMeaning.isSelected)
            {
                // We only want to trigger this code on a change, as this gets called a bit more frequently than desired ...
                // This also causes UI effects in terms of changing the classes present on the elements
                this.meanings.forEach(itm => itm.isSelected = false);
            }

            this.otherMeaning.isSelected = newValue;
            if (newValue)
            {
                this.answerText = this.otherText;
                this.answerCode = this.otherMeaning.id.toString();
                this.answerIndex = this.otherMeaning.id; 
            }
        }
    }
}    

export interface ICardSortItemExList {
    cardList: CardSortItemEx[];
}
