
export class RiasecQuestionAnswer {

    public id: string;
    public answer: string;
    public text: string;

    constructor() {
    }

    public get uiClass(): string {
        if (this.answer === '+') {
            return 'more';
        }

        if (this.answer === '-') {
            return 'less';
        }

        return '';
    }

    public get isMore(): boolean {
        return this.answer === '+';
    }

    public set isMore(value: boolean) {
        this.answer = value === true ? '+' : '';
    }

    public get isLess(): boolean {
        return this.answer === '-';
    }

    public set isLess(value: boolean) {
        this.answer = value === true ? '-' : '';
    }
}    