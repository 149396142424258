import {bindable} from 'aurelia-framework';

export class BusyIndicatorCustomElement {
    @bindable 
    public isBusy: boolean = false;
    public isBusyIndicator: boolean = false;
    private isBusyIndicatorSet: boolean = false;
    private isMinimumElaspedTimeActive: boolean = false;
    private timeoutHandle: number;

    constructor() {
    }

    public detached(): void
    {
        if (this.timeoutHandle) { 
          window.clearTimeout(this.timeoutHandle);
        }
    }

    public isBusyChanged(value: boolean, oldValue: boolean)
    {
         // We have this complex logic to allow the spinner to rotate for a minimum of 1 second - else it disappears too fast!
         this.isBusyIndicatorSet = value;
         if (value) {
             this.isMinimumElaspedTimeActive = true;
             if (this.timeoutHandle) { 
                window.clearTimeout(this.timeoutHandle);
             }

             this.timeoutHandle = window.setTimeout(() => {
               this.isMinimumElaspedTimeActive = false;
               this.isBusyIndicator = this.isBusyIndicatorSet;
               this.timeoutHandle = null;
             }, 400);
         }

         this.isBusyIndicator = this.isBusyIndicatorSet || this.isMinimumElaspedTimeActive;
     }
}
