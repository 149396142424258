import {Router, RouterConfiguration} from "aurelia-router";
import {autoinject} from "aurelia-framework";
import {AuthenticateStep} from 'aurelia-authentication';
import {GlobalState} from "./models/GlobalState";
import {Routes} from './routes';
import {BreadCrumb} from "./bread-crumb"

@autoinject
export class App {
    public router: Router;

    constructor(public globalState: GlobalState, public breadcrumb: BreadCrumb) {
    }

    configureRouter(config: RouterConfiguration, router: Router) {
        this.router = router;
        config.title = 'Insight';
        config.addAuthorizeStep(AuthenticateStep);         // Add a route filter so only authenticated users are authorized to access some routes
        config.addPostRenderStep(this.breadcrumb);

        // let Routes = mapper;

        config.map([
            { route: ["",Routes.CLIENT_HOME], moduleId: "./accounts/login", title: "Welcome to Insight by Career Sense", name: "CLIENT_HOME"},

            { route: "administration/menu", moduleId: "./administration/administrationMenu", nav: false, title: "Administration", auth: true, roles: "Administrator", name: "ADMINISTRATION" },
            { route: Routes.USERS, moduleId: "./administration/users", nav: false, title: "Users", name: "USERS" },                          //Note: this is used by the google login/registration process
            { route: Routes.USER.addIdFragment(), moduleId: "./administration/user", nav: false, title: "User", name: "USER" },                          //Note: this is used by the google login/registration process
            { route: Routes.USER_BILLING, moduleId: "./administration/billing", nav: false, title: "Billing Report", auth: true, roles: "Administrator", name: "BILLING" },
            { route: Routes.USER_REGISTER, moduleId: "./administration/registerUser", nav: false, title: "Register User", auth: true, roles: "Administrator", name: "USER_REGISTER" },
            { route: Routes.USER_ASSESSMENTS.addIdFragment(), moduleId: "./administration/assessments", nav: false, title: "User Assessments", auth: true, roles: "Administrator", name: "USER_ASSESSMENTS", href: Routes.USER_ASSESSMENTS.toHRef() },
            { route: Routes.QUESTIONNAIRE_COPY, moduleId: "./administration/assessmentCopy", nav: false, title: "Assessment Copy", auth: true, roles: "Administrator", name: "ASSESSMENT_COPY" },

            { route: Routes.CLIENT_LIST, moduleId: "./accounts/clientResults", href: Routes.CLIENT_LIST.toHRef(), nav: true, title: "Clients", auth: true, roles: "User", name: "CLIENT_LIST"},
            { route: "accounts/registration", moduleId: "./accounts/registration", nav: false, title: "Practitioner Registration" },                                            //Note: this is used by the google login/registration process
            { route: Routes.CLIENT_ASSESSMENT.idFragment, moduleId: "./accounts/questionnaire", nav: false, title: "Assessment", name: Routes.CLIENT_ASSESSMENT.route},  

            { route: Routes.CLIENT_SENDASSESSMENT, href: Routes.CLIENT_SENDASSESSMENT.toHRef(), moduleId: "./accounts/sendQuestionnaire", nav: false, title: "Send Insight Link", auth: true, roles: "User", name: "CLIENT_SENDASSESSMENT" },
            { route: Routes.CLIENT_UPDATEDETAILS.addIdFragment(), href: Routes.CLIENT_UPDATEDETAILS.toHRef(), moduleId: "./accounts/updateDetail", nav: false, title: "Update Client Details", auth: true, roles: "User", name: "CLIENT_UPDATEDETAILS" },
            { route: Routes.CLIENT_UPDATEPAGES.addIdFragment(), href: Routes.CLIENT_UPDATEPAGES.toHRef(), moduleId: "./accounts/updateQuestionnaire", nav: false, title: "Update Questionnaire", auth: true, roles: "User", name: "CLIENT_UPDATEPAGES" },
            { route: Routes.USER_PROFILE, moduleId: "./accounts/profile", nav: false, title: "Profile", auth: true, roles: "User", name: "USER_PROFILE" },
        
            { route: Routes.QUESTIONNAIRE.addIdFragment(), moduleId: "./riasec/riasecHeader", href: Routes.QUESTIONNAIRE.toHRef(), nav: false, title: "Assessment", auth: true, roles: "User", name: "ASSESSMENT"},         
            { route: Routes.QUESTIONNAIRE_PAYMENT + "/:id/:resultToken/:sessionToken", moduleId: "./accounts/payment", href: Routes.QUESTIONNAIRE_PAYMENT.toHRef(), nav: false, title: "Payment", auth: false, name: "ASSESSMENT_PAYMENT"},         
            { route: Routes.QEND, moduleId: "./riasec/end", nav: false, title: "End of Questions", auth: true, name: "QEND" },
            { route: Routes.ANALYSIS_CARDS_COMBINED.addIdFragment(), moduleId: "./cards/combinedCardSortAnalysis", href: Routes.ANALYSIS_CARDS_COMBINED.toHRef(), nav: false, title: "Combined Analysis",  auth: true, roles: "User", name: "ANALYSIS_CARDS_COMBINED" },
            { route: Routes.ANALYSIS_CARDS_GROUPED.addIdFragment(), moduleId: "./cards/groupedCardSortAnalysis", href: Routes.ANALYSIS_CARDS_GROUPED.toHRef(), nav: false, title: "Grouped Analysis",  auth: true, roles: "User" , name: "ANALYSIS_CARDS_GROUPED"},

            //{ route: Routes.BACKGROUND_INFO, moduleId: "./riasec/background", nav: true, title: "Background", auth: true, roles: "User,QuestionnaireAnalysis" },

            { route: Routes.CARDS_VALUES.idFragment, moduleId: "./cards/valuesCardSort", href: Routes.CARDS_VALUES.href, nav: true, title: "Values", auth: true, roles: "User, QuestionnaireUser", name: Routes.CARDS_VALUES.route },
            { route: Routes.ANALYSIS_CARDS_VALUES.addIdFragment(), moduleId: "./cards/valuesCardSortAnalysis", href: Routes.ANALYSIS_CARDS_VALUES.toHRef(), nav: false, title: "Values Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_VALUES" },
            { route: Routes.ANALYSIS_CARDS_CAREERVALUES.idFragment, moduleId: "./cards/valuesCardSortAnalysisII", href: Routes.ANALYSIS_CARDS_CAREERVALUES.href, nav: false, title: "Values Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: Routes.ANALYSIS_CARDS_CAREERVALUES.route },
            { route: Routes.CARDS_MEANINGS.idFragment, moduleId: "./cards/valuesMeaningsCardSort", href: Routes.CARDS_MEANINGS.href, nav: true, title: "Meanings", auth: true, roles: "User,QuestionnaireAnalysis", name: Routes.CARDS_MEANINGS.route },
            
            { route: Routes.CARDS_SKILLS.idFragment, moduleId: "./cards/skillsCardSort", href: Routes.CARDS_SKILLS.href, nav: true, title: "Skills", auth: true, roles: "User, QuestionnaireUser", name: Routes.CARDS_SKILLS.route },
            { route: Routes.ANALYSIS_CARDS_SKILLS.addIdFragment(), moduleId: "./cards/skillsCardSortAnalysis", href: Routes.ANALYSIS_CARDS_SKILLS.toHRef(), nav: false, title: "Skills Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_SKILLS" },
            { route: Routes.ANALYSIS_CARDS_SKILLSII.addIdFragment(), moduleId: "./cards/skillsCardSortAnalysisII", href: Routes.ANALYSIS_CARDS_SKILLSII.toHRef(), nav: false, title: "Skills Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_SKILLSII"},

            { route: Routes.CARDS_INTERESTS.idFragment, moduleId: "./cards/interestsCardSort", href: Routes.CARDS_INTERESTS.href, nav: true, title: "Interests", auth: true, roles: "User, QuestionnaireUser", name: Routes.CARDS_INTERESTS.route },
            { route: Routes.ANALYSIS_CARDS_INTERESTS.addIdFragment(), moduleId: "./cards/interestsCardSortAnalysis", href: Routes.ANALYSIS_CARDS_INTERESTS.toHRef(), nav: false, title: "Interests Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_INTERESTS" },
            { route: Routes.ANALYSIS_CARDS_INTERESTSII.addIdFragment(), moduleId: "./cards/interestsCardSortAnalysisII", href: Routes.ANALYSIS_CARDS_INTERESTSII.toHRef(), nav: false, title: "Interests Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_INTERESTSII" },
            { route: Routes.Q40.idFragment, moduleId: "./riasec/riasecQuestions", href: Routes.Q40.href, nav: true, title: "Q40", auth: true, roles: "User, QuestionnaireUser", name: Routes.Q40.route},

            { route: "Routes.ANALYSIS_RESULTS", moduleId: "./riasec/riasecResults", nav: true, title: "Q40 Results", auth: true, roles: "User", name: "Q40_MENU" },
            { route: Routes.ANALYSIS_RESULTS.addIdFragment(), moduleId: "./riasec/riasecResults", href: Routes.ANALYSIS_RESULTS.toHRef(), nav: true, title: "Q40 Results", auth: true, roles: "User", name: "ANALYSIS_RESULTS", settings: { parentMenu: "Q40_MENU"}},
            { route: Routes.CAREERS_RECOMMENDED.addIdFragment(), moduleId: "./riasec/predictedCareers", href: Routes.CAREERS_RECOMMENDED.toHRef(), nav: true, title: "Q40 Recommended Career Options", auth: true, roles: "User", name: "CAREERS_RECOMMENDED", settings: { parentMenu: "Q40_MENU"}},
            { route: Routes.ANALYSIS_RESULTS.addIdFragment(), moduleId: "./riasec/riasecResults", href: Routes.ANALYSIS_RESULTS.toHRef(), nav: true, title: "Q40 Results", auth: true, roles: "QuestionnaireAnalysis", name: "ANALYSIS_RESULTS_USER"},
            { route: Routes.CARDS_STATEMENT.idFragment, moduleId: "./cards/userCardSortAnalysis", href: Routes.CARDS_STATEMENT.href, nav: true, title: "Statement", auth: true, roles: "User, QuestionnaireAnalysis", name: Routes.CARDS_STATEMENT.route },

            { route: Routes.CARDS_OCCUPATIONS.idFragment, moduleId: "./cards/occupationalCardSort", href: Routes.CARDS_OCCUPATIONS.href, nav: true, title: "Occupations", auth: true, roles: "User,QuestionnaireOccupation", name: Routes.CARDS_OCCUPATIONS.route },
            { route: Routes.ANALYSIS_CARDS_OCCUPATIONS.addIdFragment(), moduleId: "./cards/occupationalCardSortAnalysis", href: Routes.ANALYSIS_CARDS_OCCUPATIONS.toHRef(), nav: false, title: "Occupations Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_OCCUPATIONS" },
            { route: Routes.ANALYSIS_CARDS_SPECIALISED.addIdFragment(), moduleId: "./cards/specialisedAnalysis", href: Routes.ANALYSIS_CARDS_SPECIALISED.toHRef(), nav: true, title: "Specialised", auth: true, roles: "User", name: "ANALYSIS_CARDS_SPECIALISED" },

            { route: Routes.CARDS_BUSINESS.addIdFragment(), moduleId: "./cards/businessCardSort", href: Routes.CARDS_BUSINESS.toHRef(), nav: true, title: "Business", auth: true, roles: "User,QuestionnaireBusiness", name: "CARDS_BUSINESS" },
            { route: Routes.ANALYSIS_CARDS_BUSINESS.addIdFragment(), moduleId: "./cards/businessCardSortAnalysis", href: Routes.ANALYSIS_CARDS_BUSINESS.toHRef(), nav: false, title: "Business Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_BUSINESS" },
            { route: Routes.ANALYSIS_CARDS_BUSINESS_EX.addIdFragment(), moduleId: "./cards/businessCardSingleAnalysis", href: Routes.ANALYSIS_CARDS_BUSINESS_EX.toHRef(), nav: false, title: "Business Grouped Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_BUSINESS_EX" },

            { route: Routes.CARDS_ENGINEERING.addIdFragment(), moduleId: "./cards/engineeringCardSort", href: Routes.CARDS_ENGINEERING.toHRef(), nav: true, title: "Engineering", auth: true, roles: "User,QuestionnaireEngineering", name: "CARDS_ENGINEERING" },
            { route: Routes.ANALYSIS_CARDS_ENGINEERING.addIdFragment(), moduleId: "./cards/engineeringCardSortAnalysis", href: Routes.ANALYSIS_CARDS_ENGINEERING.toHRef(), nav: false, title: "Engineering Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_ENGINEERING" },
            { route: Routes.ANALYSIS_CARDS_ENGINEERING_EX.addIdFragment(), moduleId: "./cards/engineeringCardSingleAnalysis", href: Routes.ANALYSIS_CARDS_ENGINEERING_EX.toHRef(), nav: false, title: "Engineering Grouped Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_ENGINEERING_EX"},

            { route: Routes.CARDS_HELPING.addIdFragment(), moduleId: "./cards/helpingCardSort", href: Routes.CARDS_HELPING.toHRef() , nav: true, title: "Helping", auth: true, roles: "User,QuestionnaireHelping", name: "CARDS_HELPING"},
            { route: Routes.ANALYSIS_CARDS_HELPING.addIdFragment(), moduleId: "./cards/helpingCardSortAnalysis", href: Routes.ANALYSIS_CARDS_HELPING.toHRef(), nav: false, title: "Helping Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_HELPING" },
            { route: Routes.ANALYSIS_CARDS_HELPING_EX.addIdFragment(), moduleId: "./cards/helpingCardSingleAnalysis", href: Routes.ANALYSIS_CARDS_HELPING_EX.toHRef(), nav: false, title: "Helping Grouped Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_HELPING_EX" },
            { route: Routes.CAREERS_HELPING.addIdFragment(), moduleId: "./cards/helpingCardSortCareersAnalysis", href: Routes.CAREERS_HELPING.toHRef(), nav: false, title: "Helping Careers", auth: true, roles: "User,QuestionnaireAnalysis", name: "CAREERS_HELPING" },

            { route: Routes.CARDS_SCIENCE.addIdFragment(), moduleId: "./cards/scienceCardSort", href: Routes.CARDS_SCIENCE.toHRef(), nav: true, title: "Science", auth: true, roles: "User,QuestionnaireScience", name: "CARDS_SCIENCE" },
            { route: Routes.ANALYSIS_CARDS_SCIENCE.addIdFragment(), moduleId: "./cards/scienceCardSortAnalysis", href: Routes.ANALYSIS_CARDS_SCIENCE.toHRef(), nav: false, title: "Science Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_SCIENCE" },
            { route: Routes.ANALYSIS_CARDS_SCIENCE_EX.addIdFragment(), moduleId: "./cards/scienceCardSingleAnalysis", href: Routes.ANALYSIS_CARDS_SCIENCE_EX.toHRef(), nav: false, title: "Science Grouped Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_SCIENCE_EX" },
       
            { route: Routes.CARDS_CREATIVITY.addIdFragment(), moduleId: "./cards/creativityCardSort", href: Routes.CARDS_CREATIVITY.toHRef(), nav: true, title: "Creativity", auth: true, roles: "User,QuestionnaireCreativity", name: "CARDS_CREATIVITY" },
            { route: Routes.ANALYSIS_CARDS_CREATIVITY.addIdFragment(), moduleId: "./cards/creativityCardSortAnalysis", href: Routes.ANALYSIS_CARDS_CREATIVITY.toHRef(), nav: false, title: "Creativity Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_CREATIVITY"},
            { route: Routes.ANALYSIS_CARDS_CREATIVITY_EX.addIdFragment(), moduleId: "./cards/creativityCardSingleAnalysis", href: Routes.ANALYSIS_CARDS_CREATIVITY_EX.toHRef(), nav: false, title: "Creativity Grouped Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_CREATIVITY_EX" },
            { route: Routes.CAREERS_CREATIVITY.addIdFragment(), moduleId: "./cards/creativityCardSortCareers", href: Routes.CAREERS_CREATIVITY.toHRef(), nav: false, title: "Creativity Careers", auth: true, roles: "User,QuestionnaireAnalysis", name: "CAREERS_CREATIVITY" },
        
            { route: Routes.CARDS_TRADES.addIdFragment(), moduleId: "./cards/tradesCardSort", href: Routes.CARDS_TRADES.toHRef(), nav: true, title: "Pre-Degree", auth: true, roles: "User,QuestionnaireTrades", name: "CARDS_TRADES" },
            { route: Routes.ANALYSIS_CARDS_TRADES.addIdFragment(), moduleId: "./cards/tradesCardSortAnalysis", href: Routes.ANALYSIS_CARDS_TRADES.toHRef(), nav: false, title: "Pre-Degree Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_TRADES" },
            { route: Routes.ANALYSIS_CARDS_TRADES_EX.addIdFragment(), moduleId: "./cards/tradesCardSingleAnalysis", href: Routes.ANALYSIS_CARDS_TRADES_EX.toHRef(), nav: false, title: "Pre-Degree Grouped Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_TRADES_EX" },
            { route: Routes.CAREERS_TRADES.addIdFragment(), moduleId: "./cards/tradesCardSortCareers", href: Routes.CAREERS_TRADES.toHRef(), nav: false, title: "Pre-Degree Careers", auth: true, roles: "User,QuestionnaireAnalysis", name: "CAREERS_TRADES" },
        
            { route: Routes.CARDS_ARTS.addIdFragment(), moduleId: "./cards/artsCardSort", href: Routes.CARDS_ARTS.toHRef(), nav: true, title: "Arts & Soc Science", auth: true, roles: "User,QuestionnaireArts", name: "CARDS_ARTS" },
            { route: Routes.ANALYSIS_CARDS_ARTS.addIdFragment(), moduleId: "./cards/artsCardSortAnalysis", href: Routes.ANALYSIS_CARDS_ARTS.toHRef(), nav: false, title: "Arts Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name:  "ANALYSIS_CARDS_ARTS" },
            { route: Routes.ANALYSIS_CARDS_ARTS_EX.addIdFragment(), moduleId: "./cards/artsCardSingleAnalysis", href: Routes.ANALYSIS_CARDS_ARTS_EX.toHRef(), nav: false, title: "Arts Grouped Analysis", auth: true, roles: "User,QuestionnaireAnalysis", name: "ANALYSIS_CARDS_ARTS_EX" },

            { route: Routes.ANALYSIS_OUTCOMES.addIdFragment(), moduleId: "./riasec/outcomes", href: Routes.ANALYSIS_OUTCOMES.toHRef(), nav: true, title: "Outcomes", auth: true, roles: "User", name: "ANALYSIS_OUTCOMES" },
            
            { route: Routes.IA_MAIN_INTRO.idFragment, moduleId: "./ia/mainIntro", href: Routes.IA_MAIN_INTRO.href, nav: false, title: "CSI Introduction", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_MAIN_INTRO.route },

            { route: Routes.IA_VALUES_INTRO.idFragment, moduleId: "./ia/valuesIntro", href: Routes.IA_VALUES_INTRO.href, nav: false, title: "Values Intro", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_VALUES_INTRO.route },
            { route: Routes.IA_VALUES_CARDS.idFragment, moduleId: "./ia/valuesCards", href: Routes.IA_VALUES_CARDS.href, nav: false, title: "Values Card Sort", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_VALUES_CARDS.route },

            { route: Routes.IA_VALUES_LEAST.idFragment, moduleId: "./ia/valuesLeast", href: Routes.IA_VALUES_LEAST.href, nav: false, title: "Values Least Important", auth: true, roles: Routes.IA_SECURITY_ROLES, name:  Routes.IA_VALUES_LEAST.route  },
            { route: Routes.IA_VALUES_MOST.idFragment,  moduleId: "./ia/valuesMost", href: Routes.IA_VALUES_MOST.href, nav: false, title: "Values Most Important", auth: true, roles: Routes.IA_SECURITY_ROLES, name:  Routes.IA_VALUES_MOST.route  },
            { route: Routes.IA_VALUES_MEANINGS_INTRO.idFragment,  moduleId: "./ia/valuesMeaningsIntro", href: Routes.IA_VALUES_MEANINGS_INTRO.href, nav: false, title: "Values Meanings", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_VALUES_MEANINGS_INTRO.route },
            { route: Routes.IA_VALUES_MEANINGS_QUESTIONS.idFragment,  moduleId: "./ia/valuesMeaningsQuestions", href: Routes.IA_VALUES_MEANINGS_QUESTIONS.href, nav: false, title: "Values Meanings", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_VALUES_MEANINGS_QUESTIONS.route },
            { route: Routes.IA_VALUES_UNDERSTANDING.idFragment,  moduleId: "./ia/valuesUnderstandingsIntro", href: Routes.IA_VALUES_UNDERSTANDING.href, nav: false, title: "Values Understanding", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_VALUES_UNDERSTANDING.route },

            { route: Routes.IA_VALUES_PURPOSE.idFragment,  moduleId: "./ia/valuesPurpose", href: Routes.IA_VALUES_PURPOSE.href, nav: false, title: "Values Purpose", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_VALUES_PURPOSE.route },
            { route: Routes.IA_VALUES_PREFERENCES.idFragment,  moduleId: "./ia/valuesPreferences", href: Routes.IA_VALUES_PREFERENCES.href, nav: false, title: "Values Preferences", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_VALUES_PREFERENCES.route},

            { route: Routes.IA_SKILLS_INTRO.idFragment,  moduleId: "./ia/skillsIntro", href: Routes.IA_SKILLS_INTRO.href, nav: false, title: "Skills Intro", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_SKILLS_INTRO.route },
            { route: Routes.IA_SKILLS_CARDS.idFragment,  moduleId: "./ia/skillsCards", href: Routes.IA_SKILLS_CARDS.href, nav: false, title: "Skills Card Sort", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_SKILLS_CARDS.route },
            { route: Routes.IA_SKILLS_MOST.idFragment,  moduleId: "./ia/skillsMost", href: Routes.IA_SKILLS_MOST.href, nav: false, title: "Skills Most Important", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_SKILLS_MOST.route },
            { route: Routes.IA_SKILLS_TYPE.idFragment,  moduleId: "./ia/skillsTypes", href: Routes.IA_SKILLS_TYPE.href, nav: false, title: "Skill Types", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_SKILLS_TYPE.route },
            { route: Routes.IA_SKILLS_PREFERRED.idFragment,  moduleId: "./ia/skillsPreferred", href: Routes.IA_SKILLS_PREFERRED.href, nav: false, title: "Skills Preferred", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_SKILLS_PREFERRED.route},

            { route: Routes.IA_INTERESTS_INTRO.idFragment,  moduleId: "./ia/interestsIntro", href: Routes.IA_INTERESTS_INTRO.href, nav: false, title: "Interests Intro", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_INTERESTS_INTRO.route },
            { route: Routes.IA_INTERESTS_CARDS.idFragment,  moduleId: "./ia/interestsCards", href: Routes.IA_INTERESTS_CARDS.href, nav: false, title: "Interests Card Sort", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_INTERESTS_CARDS.route },
            { route: Routes.IA_INTERESTS_MOST.idFragment,  moduleId: "./ia/interestsMost",   href: Routes.IA_INTERESTS_MOST.href, nav: false, title: "Interests Most Important", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_INTERESTS_MOST.route },
            { route: Routes.IA_INTERESTS_THEMES.idFragment,  moduleId: "./ia/interestsThemes",   href: Routes.IA_INTERESTS_THEMES.href, nav: false, title: "Interests Themes", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_INTERESTS_THEMES.route },

            { route: Routes.IA_Q40_INTRO.idFragment,  moduleId: "./ia/q40Intro",   href: Routes.IA_Q40_INTRO.href, nav: false, title: "Q40", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_Q40_INTRO.route},
            { route: Routes.IA_Q40_QUESTIONS.idFragment,  moduleId: "./ia/q40Questions",   href: Routes.IA_Q40_QUESTIONS.href, nav: false, title: "Q40 Questions", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_Q40_QUESTIONS.route},
            { route: Routes.IA_Q40_ANALYSIS.idFragment,  moduleId: "./ia/q40Analysis",   href: Routes.IA_Q40_ANALYSIS.href, nav: false, title: "Q40 Analysis", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_Q40_ANALYSIS.route },
            
            { route: Routes.IA_CDA_INTRO.idFragment,  moduleId: "./ia/cdaIntro",   href: Routes.IA_CDA_INTRO.href, nav: false, title: "Career Defining Attributes Intro", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_CDA_INTRO.route },
            { route: Routes.IA_CDA_QUESTIONS.idFragment,  moduleId: "./ia/cdaQuestions",   href: Routes.IA_CDA_QUESTIONS.href, nav: false, title: "Career Defining Attributes", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_CDA_QUESTIONS.route },
            { route: Routes.IA_CDA_PRIORITY.idFragment,  moduleId: "./ia/cdaPriority",   href: Routes.IA_CDA_PRIORITY.href, nav: false, title: "Career Defining Attributes Prioritisation", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_CDA_PRIORITY.route },
            { route: Routes.IA_CDA_ANALYSIS.idFragment,  moduleId: "./ia/cdaAnalysis",   href: Routes.IA_CDA_ANALYSIS.href, nav: false, title: "Career Defining Attributes Analysis", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_CDA_ANALYSIS.route },

            { route: Routes.IA_CAREER_STATEMENT.idFragment,  moduleId: "./ia/careerStatement",   href: Routes.IA_CAREER_STATEMENT.href, nav: false, title: "Career Statement", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_CAREER_STATEMENT.route },
            { route: Routes.IA_CAREER_POSSIBLE.idFragment,  moduleId: "./ia/careerPossible",   href: Routes.IA_CAREER_POSSIBLE.href, nav: false, title: "Possible Careers", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_CAREER_POSSIBLE.route },
            { route: Routes.IA_CAREER_OCCUPATION_CARDS.idFragment,  moduleId: "./ia/occupationalCards",   href: Routes.IA_CAREER_OCCUPATION_CARDS.href, nav: false, title: "Occupational Card Sort", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_CAREER_OCCUPATION_CARDS.route },
            { route: Routes.IA_CAREER_OCCUPATION_ANALYSIS.idFragment,  moduleId: "./ia/occupationalAnalysis", href: Routes.IA_CAREER_OCCUPATION_ANALYSIS.href, nav: false, title: "Occupational Areas Analysis", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_CAREER_OCCUPATION_ANALYSIS.route },
            { route: Routes.IA_CAREER_OCCUPATION_PREFERRED.idFragment,  moduleId: "./ia/occupationsPreferred", href: Routes.IA_CAREER_OCCUPATION_PREFERRED.href, nav: false, title: "Preferred Occupational Areas", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_CAREER_OCCUPATION_PREFERRED.route },
            
            { route: Routes.IA_SPECIALISED_INTRO.idFragment,  moduleId: "./ia/specialisedIntro", href: Routes.IA_SPECIALISED_INTRO.href, nav: false, title: "Specialised Intro", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_SPECIALISED_INTRO.route },
            { route: Routes.IA_SPECIALISED_OPTIONAL.idFragment,  moduleId: "./ia/specialisedOptional", href: Routes.IA_SPECIALISED_OPTIONAL.href, nav: false, title: "Specialised Optional", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_SPECIALISED_OPTIONAL.route },
            
            { route: Routes.IA_BUSINESS_INTRO.idFragment,  moduleId: "./ia/businessIntro",   href: Routes.IA_BUSINESS_INTRO.href, nav: false, title: "Business Intro", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_BUSINESS_INTRO.route },
            { route: Routes.IA_BUSINESS_CARDS.idFragment,  moduleId: "./ia/businessCards",   href: Routes.IA_BUSINESS_CARDS.href, nav: false, title: "Business Cards", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_BUSINESS_CARDS.route },
            { route: Routes.IA_BUSINESS_ANALYSIS.idFragment,  moduleId: "./ia/businessAnalysis",   href: Routes.IA_BUSINESS_ANALYSIS.href, nav: false, title: "Business Analysis", auth: true, roles: "QuestionnaireAnalysis", name: Routes.IA_BUSINESS_ANALYSIS.route },
            { route: Routes.IA_BUSINESS_MATRIX.idFragment,  moduleId: "./ia/businessMatrix",   href: Routes.IA_BUSINESS_MATRIX.href, nav: false, title: "Business Matrix", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_BUSINESS_MATRIX.route },
            { route: Routes.IA_ENGINEERING_INTRO.idFragment,  moduleId: "./ia/engineeringIntro",   href: Routes.IA_ENGINEERING_INTRO.href, nav: false, title: "Engineering Intro", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_ENGINEERING_INTRO.route },
            { route: Routes.IA_ENGINEERING_CARDS.idFragment,  moduleId: "./ia/engineeringCards",   href: Routes.IA_ENGINEERING_CARDS.href, nav: false, title: "Engineering Cards", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_ENGINEERING_CARDS.route },
            { route: Routes.IA_ENGINEERING_ANALYSIS.idFragment,  moduleId: "./ia/engineeringAnalysis",   href: Routes.IA_ENGINEERING_ANALYSIS.href, nav: false, title: "Engineering Analysis", auth: true, roles: "QuestionnaireAnalysis", name: Routes.IA_ENGINEERING_ANALYSIS.route },
            { route: Routes.IA_ENGINEERING_MATRIX.idFragment,  moduleId: "./ia/engineeringMatrix",   href: Routes.IA_ENGINEERING_MATRIX.href, nav: false, title: "Engineering Matrix", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_ENGINEERING_MATRIX.route },
            { route: Routes.IA_HELPING_INTRO.idFragment,  moduleId: "./ia/helpingIntro",   href: Routes.IA_HELPING_INTRO.href, nav: false, title: "Helping Intro", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_HELPING_INTRO.route },
            { route: Routes.IA_HELPING_CARDS.idFragment,  moduleId: "./ia/helpingCards",   href: Routes.IA_HELPING_CARDS.href, nav: false, title: "Helping Cards", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_HELPING_CARDS.route },
            { route: Routes.IA_HELPING_ANALYSIS.idFragment,  moduleId: "./ia/helpingAnalysis",   href: Routes.IA_HELPING_ANALYSIS.href, nav: false, title: "Helping Analysis", auth: true, roles: "QuestionnaireAnalysis", name: Routes.IA_HELPING_ANALYSIS.route },
            { route: Routes.IA_HELPING_CAREERS.idFragment,  moduleId: "./ia/helpingCareers",   href: Routes.IA_HELPING_CAREERS.href, nav: false, title: "Helping Careers", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_HELPING_CAREERS.route },
            { route: Routes.IA_HELPING_MATRIX.idFragment,  moduleId: "./ia/helpingMatrix",   href: Routes.IA_HELPING_MATRIX.href, nav: false, title: "Helping Matrix", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_HELPING_MATRIX.route },
            
            { route: Routes.IA_SCIENCE_INTRO.idFragment,  moduleId: "./ia/scienceIntro",   href: Routes.IA_SCIENCE_INTRO.href, nav: false, title: "Science Intro", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_SCIENCE_INTRO.route },
            { route: Routes.IA_SCIENCE_CARDS.idFragment,  moduleId: "./ia/scienceCards",   href: Routes.IA_SCIENCE_CARDS.href, nav: false, title: "Science Cards", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_SCIENCE_CARDS.route },
            { route: Routes.IA_SCIENCE_ANALYSIS.idFragment,  moduleId: "./ia/scienceAnalysis",   href: Routes.IA_SCIENCE_ANALYSIS.href, nav: false, title: "Science Analysis", auth: true, roles: "QuestionnaireAnalysis", name: Routes.IA_SCIENCE_ANALYSIS.route },
            { route: Routes.IA_SCIENCE_MATRIX.idFragment,  moduleId: "./ia/scienceMatrix",   href: Routes.IA_SCIENCE_MATRIX.href, nav: false, title: "Science Matrix", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_SCIENCE_MATRIX.route },
            { route: Routes.IA_CREATIVITY_INTRO.idFragment,  moduleId: "./ia/creativityIntro",   href: Routes.IA_CREATIVITY_INTRO.href, nav: false, title: "Creativity Intro", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_CREATIVITY_INTRO.route },
            { route: Routes.IA_CREATIVITY_CARDS.idFragment,  moduleId: "./ia/creativityCards",   href: Routes.IA_CREATIVITY_CARDS.href, nav: false, title: "Creativity Cards", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_CREATIVITY_CARDS.route },
            { route: Routes.IA_CREATIVITY_ANALYSIS.idFragment,  moduleId: "./ia/creativityAnalysis",   href: Routes.IA_CREATIVITY_ANALYSIS.href, nav: false, title: "Creativity Analysis", auth: true, roles: "QuestionnaireAnalysis", name: Routes.IA_CREATIVITY_ANALYSIS.route },
            { route: Routes.IA_CREATIVITY_MATRIX.idFragment,  moduleId: "./ia/creativityMatrix",   href: Routes.IA_CREATIVITY_MATRIX.href, nav: false, title: "Creativity Matrix", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_CREATIVITY_MATRIX.route },
            { route: Routes.IA_TRADES_INTRO.idFragment,  moduleId: "./ia/tradesIntro",   href: Routes.IA_TRADES_INTRO.href, nav: false, title: "Trades Intro", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_TRADES_INTRO.route },
            { route: Routes.IA_TRADES_CARDS.idFragment,  moduleId: "./ia/tradesCards",   href: Routes.IA_TRADES_CARDS.href, nav: false, title: "Trades Cards", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_TRADES_CARDS.route },
            { route: Routes.IA_TRADES_ANALYSIS.idFragment,  moduleId: "./ia/tradesAnalysis",   href: Routes.IA_TRADES_ANALYSIS.href, nav: false, title: "Trades Analysis", auth: true, roles: "QuestionnaireAnalysis", name: Routes.IA_TRADES_ANALYSIS.route },
            { route: Routes.IA_TRADES_CAREERS.idFragment,  moduleId: "./ia/tradesCareers",   href: Routes.IA_TRADES_CAREERS.href, nav: false, title: "Trades Careers", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_TRADES_CAREERS.route },
            { route: Routes.IA_TRADES_MATRIX.idFragment,  moduleId: "./ia/tradesMatrix",   href: Routes.IA_TRADES_MATRIX.href, nav: false, title: "Trades Matrix", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_TRADES_MATRIX.route },
            { route: Routes.IA_ARTS_INTRO.idFragment,  moduleId: "./ia/artsIntro",   href: Routes.IA_ARTS_INTRO.href, nav: false, title: "Arts Intro", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_ARTS_INTRO.route },
            { route: Routes.IA_ARTS_CARDS.idFragment,  moduleId: "./ia/artsCards",   href: Routes.IA_ARTS_CARDS.href, nav: false, title: "Arts Cards", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_ARTS_CARDS.route },
            { route: Routes.IA_ARTS_MATRIX.idFragment,  moduleId: "./ia/artsMatrix",   href: Routes.IA_ARTS_MATRIX.href, nav: false, title: "Arts Matrix", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_ARTS_MATRIX.route },
            { route: Routes.IA_ARTS_ANALYSIS.idFragment,  moduleId: "./ia/artsAnalysis",   href: Routes.IA_ARTS_ANALYSIS.href, nav: false, title: "Arts Analysis", auth: true, roles: "QuestionnaireAnalysis", name: Routes.IA_ARTS_ANALYSIS.route },

            { route: Routes.IA_END.idFragment,  moduleId: "./ia/endOfAssessment",   href: Routes.IA_END.href, nav: false, title: "Assessment End", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_END.route },
            { route: Routes.IA_END_STAGE1.idFragment,  moduleId: "./ia/endStage1",   href: Routes.IA_END_STAGE1.href, nav: false, title: "Stage 1 - End", auth: true, roles: Routes.IA_SECURITY_ROLES, name: Routes.IA_END_STAGE1.route },

            { route: "logout", moduleId: "./accounts/logout", nav: true, auth: true, title: "Logout", name: "logout"}
        ]);
        config.mapUnknownRoutes("./accounts/login");
    }
}