import {bindable, autoinject} from 'aurelia-framework';
import {DOM} from 'aurelia-pal'
import {GlobalState} from '../../models/GlobalState';
import {CardSortItemEx} from '../../models/CardSortItemEx';
import {ErrorObject} from '../../models/ErrorObject';
import {QuestionServer} from '../../server/QuestionServer';
import {CardOption} from '../../models/CardOption';
import {CardOptions} from '../../models/CardOptions';

@autoinject
export class MeaningsOptionsHandlerCustomElement {
    @bindable 
    public optionsNotify: boolean = null;

    @bindable 
    public valuesList:CardSortItemEx[] = [];

    public errorObject: ErrorObject = null;
    public isMostMeaningsOnly: boolean;
    public isVisible: boolean = null;
    public isBusy: boolean = false;
    public evt: CustomEvent;
    public cancelEvent: CustomEvent;
    

    constructor(private readonly element: Element, public globalState: GlobalState, protected server: QuestionServer ) {
        this.evt = DOM.createCustomEvent('onsave', { bubbles: true, cancelable: true, detail: {}});
        this.cancelEvent = DOM.createCustomEvent('oncancel', { bubbles: true, cancelable: true, detail: {}});
    }

    public close(event: Event): void {
        this.isVisible = false;
        this.element.dispatchEvent(this.cancelEvent);
    }

    public save(event: Event): any {
        this.isBusy = true;
        this.errorObject = ErrorObject.EmptyError();

        let options = new CardOptions();
        options.id = this.globalState.questionnaire.id;
        options.isMostMeaningsOnly = this.isMostMeaningsOnly;
        this.valuesList.forEach((valueItm) => {
            if (valueItm.isAnswerModified)
            {
                let itm = new CardOption();
                itm.id = valueItm.id;
                itm.answer = valueItm.answer;
                itm.answerCode = valueItm.answerCode;
                options.items.push(itm);
            }
        });


        this.server.saveCardOptions(this.globalState.questionnaire.id, '1', options)
            .then(response => {
                // if there was any error on the previous attempt - throw it away now.
                this.isBusy = false;
                this.isVisible = false;
                this.globalState.questionnaire.isMostMeaningsOnly = this.isMostMeaningsOnly;
                this.element.dispatchEvent(this.evt);
            })
            .catch(reason => 
            {
                this.errorObject = new ErrorObject(
                    reason,
                    options,
                    'Save Meanings Options', 
                    `The meanings options are unchanged/have not been saved.`);       

                this.isBusy = false;
            });
    }

    public optionsNotifyChanged(value: boolean, oldValue: any)
    {
        if (value != oldValue && value)
        {
            this.isVisible = (value);
            this.isMostMeaningsOnly = this.globalState.questionnaire.isMostMeaningsOnly;
        }
    }
}
