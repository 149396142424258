import {autoinject, computedFrom} from 'aurelia-framework';
import {AuthService} from 'aurelia-authentication';
import {AccountsServer} from '../server/AccountsServer';
import {Router} from 'aurelia-router';
import {GlobalState} from '../models/GlobalState';
import {ErrorObject} from '../models/ErrorObject';
import {Routes} from '../routes';

@autoinject
export class AccountsRegister {

    public errorObject: ErrorObject = null;
    public isBusy: boolean = false;
    public registrationToken: string = '';

    constructor(protected router: Router, protected globalState: GlobalState, private authService: AuthService, private server: AccountsServer) {
    }

    public activate(params) {
        this.registrationToken = params.token || "";
    }

    public authenticate(name: string): any {

        this.server.validateRegistrationToken(this.registrationToken)
            .then(data => {
                if (data && data.okay)
                {
                    this.authService.authenticate(name, null, { registrationToken: this.registrationToken, sessionId: data.sessionId })
                        .then(repsonse => 
                        {
                            this.router.navigate(Routes.CLIENT_LIST);
                        })
                        .catch(reason => 
                        {
                            this.errorObject = new ErrorObject(
                                reason,
                                this,
                                'Google login failed', 
                                'Try again?');       
                            this.isBusy = false;
                        });
                }
                else
                {
                    this.registrationToken = 'Token not valid. Try again.';
                }
            })
            .catch(reason => 
            {
                this.errorObject = new ErrorObject(
                    reason,
                    this,
                    'Token registration check', 
                    'The registration token could not be validated/does not exist or has been previously used.');       
                this.isBusy = false;
            });
	}


    @computedFrom("registrationToken")
    public get isNotValid(): boolean {
        return (!this.registrationToken || this.registrationToken.length < 20);
    }
}
