import {Career} from './Career';

export class CareerGroup {
    public careerGroup: string ='';
    public careers: Career[] = [];
    
    public static toCareerGroup(obj: any): CareerGroup {
        let item = new CareerGroup();
        item.careerGroup = obj.careerCoding;
        item.careers = obj.careers.map(Career.toCareer);
        return item;
    }
}

