import {CareerGroup} from '../models/CareerGroup';

export class CareerTheme {
    public theme: string = "";
    public careerGroups: CareerGroup[] = [];
    
    public static toCareerTheme(obj: any): CareerTheme {
        let item = new CareerTheme();
        item.theme = obj.theme;
        item.careerGroups = obj.careerCoding.map(CareerGroup.toCareerGroup);
        return item;
    }
}

export interface ICareerThemes {
    themedCareers: CareerTheme[];
}

