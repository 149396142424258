import {autoinject} from 'aurelia-framework';
import {nextPage} from '../resources/navigation/NextPage';
import {Routes} from '../routes';
import {RootAssessmentPage} from '../models/RootAssessmentPage';


@autoinject
@nextPage(Routes.IA_CDA_QUESTIONS.urlFragment)
export class CDAIntro extends RootAssessmentPage {
    
}
