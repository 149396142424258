import {bindable, autoinject} from 'aurelia-framework';
import {DOM} from 'aurelia-pal'

@autoinject
export class ReSortNotificationCustomElement {
    @bindable 
    public resortNotify: boolean = null;
    @bindable 
    public itemCount: number = 0;
    public isVisible: boolean = null;
    public evt: CustomEvent;

    constructor(private readonly element: Element) {
        this.evt = DOM.createCustomEvent('oncontinue', { bubbles: true, cancelable: true, detail: {}});
    }

    public close(): void {
        this.isVisible = false;
        this.element.dispatchEvent(this.evt);
    }

    public resortNotifyChanged(value: boolean, oldValue: any)
    {
        if (value != oldValue && value)
        {
            this.isVisible = (value);
        }
    }
}
