
export class HelpIndicatorCustomElement {
    public helpVisible: boolean = false;
    private timeoutHandle: number;

    constructor() {
    }

    public detached(): void
    {
        if (this.timeoutHandle) { 
          window.clearTimeout(this.timeoutHandle);
        }
    }
    
    public helpPage(): void
    {
        if (this.timeoutHandle) { 
          window.clearTimeout(this.timeoutHandle);
        }

        this.timeoutHandle = null;
        this.helpVisible = !this.helpVisible;

        if (this.helpVisible)
        {
            this.timeoutHandle = window.setTimeout(() => {
                this.helpVisible = false;
                this.timeoutHandle = null;
            }, 10000);
        }
    }

    public close(): void
    {
        this.helpVisible = false;
    }
}
