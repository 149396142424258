import {autoinject, computedFrom} from 'aurelia-framework';
import {Router, RouteConfig} from 'aurelia-router';
import {QuestionServer} from '../server/QuestionServer';
import {RiasecQuestionnaire} from '../models/RiasecQuestionnaire';
import {RiasecQuestionSet} from '../models/RiasecQuestionSet';
import {RiasecQuestionAnswer} from '../models/RiasecQuestionAnswer';
import {RiasecQuestionItem} from '../models/RiasecQuestionItem';
import {SecurityRoles} from '../models/SecurityRoles';
import {GlobalState} from '../models/GlobalState';
import {ErrorObject} from '../models/ErrorObject';
import {Routes} from '../routes';

@autoinject
export class RiasecQuestions {
    public questionSet: RiasecQuestionSet;
    public questionSetIndex: number;
    public isNextPageDisabled: boolean;
    public nextPageBtn: HTMLButtonElement;
    public questionnaire: RiasecQuestionnaire;
    public isBusy: boolean = false;
    public isEndOfCards: boolean = false;
    public isAnyAnswersPresent: boolean = false;
    public errorObject: ErrorObject = null;

    constructor(protected router: Router, protected globalState: GlobalState, private server: QuestionServer, private securityRoles: SecurityRoles) {
        this.questionSetIndex = 0;
        this.questionnaire = globalState.questionnaire;
        this.questionnaire.loaded = false;                      // Always need to load the latest version of the answers ...
        this.questionSet = this.questionnaire.questionSets[0];
    }

    public activate(params: any, route: RouteConfig): any {
        if (params.id && params.id.length >= 36)
        {
            if (!this.globalState.questionnaire.id || !this.globalState.questionnaire.id != params.id)
            {
                this.globalState.questionnaire.id = params.id;
                this.globalState.questionnaire.loaded = false;
            }
        } else if (this.globalState.Id)  
        {
            // Perform navigation so that we get the assessment Id present in the URL so that a refresh in the browser will not lose the clients page.
            this.router.navigateToRoute( route.name, { 'id': this.globalState.Id }, { trigger: false, replace: true });
        }
    }

    public attached(): any {
        this.globalState.clearNowVersion();
        if (this.questionnaire.id && !this.questionnaire.loaded) {
            return this.server.loadQuestionnaire(this.questionnaire.id, this.globalState)
                .then(questionnaire => 
                {
                    this.globalState.clearNowVersion();
                    this.questionnaire = <RiasecQuestionnaire>questionnaire;
                    this.questionSet = this.questionnaire.questionSets[0];
                    this.questionSetIndex = 0;
                    this.isNextPageDisabled = !(this.isAnyMore && this.isAnyLess);
                    return questionnaire;
                });
        }

        this.isNextPageDisabled = !(this.isAnyMore && this.isAnyLess);
    }

    public backPage() {
        if (--this.questionSetIndex < 0) {
            this.questionSetIndex = 0;
            this.router.navigateBack();
        }
        else {
            this.errorObject = ErrorObject.EmptyError();
            this.isBusy = false;
            this.questionSet = this.questionnaire.questionSets[this.questionSetIndex];
            this.isNextPageDisabled = !(this.isAnyMore && this.isAnyLess);
        }
    }

    public questionBtn(event: any): boolean {
        let question = <RiasecQuestionItem>event.target.model;
        if (event.altKey === true || event.ctrlKey === true) {
            question.isLess = !question.isLess;
            this.questionSet.questions.forEach(((itm) => {
                if (itm != question && itm.isLess) {
                    itm.isLess = false;
                }
            }));
        }
        else {
            if (question.isMore) {
                question.isMore = false;
            }
            else if (question.isLess) {
                question.isLess = false;
            }
            else if (!this.isAnyMore) {
                question.isMore = true;
            }
            else if (!this.isAnyLess) {
                question.isLess = true;
            }
            else
            {
                question.isMore = !question.isMore;
                this.questionSet.questions.forEach(((itm) => {
                    if (itm != question && itm.isMore) {
                        itm.isMore = false;
                    }
                }));
            }
        }

        let priorBtnState = this.isNextPageDisabled;
        this.isNextPageDisabled = !(this.isAnyMore && this.isAnyLess);

        // Give the next button focus on state change to enabled so that enter can be used to go the next question.
        if (priorBtnState != this.isNextPageDisabled && !this.isNextPageDisabled)
        {
            this.nextPageBtn.disabled = false;
            this.nextPageBtn.focus();
        }

        // let the event keep on bubbling ...
        return false;
    }

    private get isAnyMore(): boolean {
        let result: boolean = false;
        if ((this.questionSet) && (this.questionSet.questions)) {
            this.questionSet.questions.forEach(((itm) => {
                result = result || itm.isMore;
            }));
        }

        return result;
    }

    private get isAnyLess(): boolean {
        let result: boolean = false;
        if ((this.questionSet) && (this.questionSet.questions)) {
            this.questionSet.questions.forEach(((itm) => {
                result = result || itm.isLess;
            }));
        }

        return result;
    }

    @computedFrom('questionSetIndex')
    public get questionNumber(): number {
        return this.questionSetIndex+1;
    }

    public moveToNextPage(event: any) : void {
        if (this.securityRoles.isQuestionnaireUser)
        {
            this.router.navigate(Routes.QEND, {});
        }
        else
        {
            this.router.navigateToRoute(Routes.CARDS_OCCUPATIONS.route, { id: this.globalState.Id }, { trigger: true, replace: false });
        }
    }

    public nextPage(): any {
        let answers = this.questionSet.questions.map((itm) => {
            var ans = new RiasecQuestionAnswer();
            ans.id = itm.id;
            ans.answer = itm.answer;
            return ans;
        });

        let savePoint = {
            questionSet:  this.questionSet,
            questionSetIndex: this.questionSetIndex,
            answers: answers
        }

        this.isBusy = true;
        this.errorObject = ErrorObject.EmptyError();
        this.server.saveQuestionAnswers(this.questionnaire.id, '1', savePoint.questionSet.id, savePoint.answers)
            .then(response => {
                // if there was any error on the previous attempt - throw it away now.
                this.isBusy = false;
            })
            .catch(reason => 
            {
                this.errorObject = new ErrorObject(
                    reason,
                    savePoint,
                    'Save Questionnaire Answers', 
                    `The answers to the question about "<span class='errorHighlight'>${savePoint.questionSet.heading}</span>" have not been saved.`);       

                this.isBusy = false;
                this.questionSetIndex = savePoint.questionSetIndex;
                this.questionSet = savePoint.questionSet;
                this.isNextPageDisabled = !(this.isAnyMore && this.isAnyLess);
            });

        this.questionSetIndex++;

        if (this.questionSetIndex >= this.questionnaire.questionSets.length) {
            this.isEndOfCards = true;
            this.isNextPageDisabled = true;
            this.questionSetIndex--;
            return;
        }

        this.isEndOfCards = false;
        this.questionSet = this.questionnaire.questionSets[this.questionSetIndex];
        this.isNextPageDisabled = !(this.isAnyMore && this.isAnyLess);
    }
}   