import {autoinject, bindable, computedFrom} from 'aurelia-framework';
import {AuthService} from 'aurelia-authentication';

@autoinject
export class NavBar {
    @bindable router = null;

    constructor(private authService: AuthService) {
        this.authService = authService;
    };

    @computedFrom('authService.authenticated')
    public get authenticated() {
        return this.authService.authenticated;
    }
}
