import {PipelineStep, NavigationInstruction, Next} from "aurelia-router";
import {autoinject, computedFrom} from "aurelia-framework";
import {GlobalState} from "./models/GlobalState";
import {RoutePaths, RouteCnfg} from 'routes';
import { AccountsServer } from "server/AccountsServer";

@autoinject
export class BreadCrumb implements PipelineStep {
    private isVisible: boolean = false;
    public visitedRoutes: string[] = [];
    public breadCrumbRoutes: RouteCnfg[] = RoutePaths.IA_BREADCRUMB_ROUTES;
    public id: string;

    constructor (public globalState: GlobalState, private server: AccountsServer)
    {
        this.id = null;
        this.visitedRoutes = [];
    }

    public run(instruction: NavigationInstruction, next: Next): Promise<any>
    {
        if ((this.globalState.questionnaire) && (this.globalState.questionnaire.id) && (this.id != this.globalState.questionnaire.id || (this.globalState.questionnaire.pagesVisited) && this.visitedRoutes.length == 0))
        {
            this.visitedRoutes = [];
            this.breadCrumbRoutes = [];
            if ((this.globalState.questionnaire.pagesVisited) && (this.globalState.questionnaire.pagesVisited.length >= 2))
            {
                this.visitedRoutes = this.globalState.questionnaire.pagesVisited.split(",").filter(itm => itm > "");
            }
            this.id = this.globalState.questionnaire.id;
            this.isVisible = this.globalState.questionnaire.isIndependentAssessment == true;
            // Populate the page list with known state based on what has already been visted previously for all the pages
            RoutePaths.IA_BREADCRUMB_ROUTES.forEach(itm => {
                itm.visited = this.visitedRoutes.some(visitedItm => visitedItm == itm.route);
                itm.isVisible = this.isVisible;
            });

        }

        let route = instruction.config.name;
        let routeCnfg = RoutePaths.IA_BREADCRUMB_ROUTES.find(itm => itm.route == route);
        if ((routeCnfg))
        {
            routeCnfg.visited = true;
            this.breadCrumbRoutes = RoutePaths.IA_BREADCRUMB_ROUTES;
            if (this.isVisible !== true)
            {
                RoutePaths.IA_BREADCRUMB_ROUTES.forEach(itm => {
                    itm.isVisible = true;
                });
            }

            this.isVisible = true;

            if (!this.visitedRoutes.some(itm => itm == route))
            {
                this.visitedRoutes.push(route);        
                this.globalState.questionnaire.pagesVisited = this.visitedRoutes.join(",");
                if (this.visitedRoutes.length >= 2) {
                    this.server.updatePagesVisited(this.id, this.globalState.questionnaire.pagesVisited);
                }
            }
        }

        return next();
    }

    @computedFrom("globalState.surname")
    public get surname(): string
    {
        return (this.globalState.surname) ? this.globalState.surname : this.globalState.questionnaire.surname;
    }
 
    public get firstname(): string
    {
        return (this.globalState.firstname) ? this.globalState.firstname : this.globalState.questionnaire.firstname;
    }

    public get audit(): string
    {
        return this.globalState.now;
    }
}