import {autoinject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {AuthService} from 'aurelia-authentication';
import {GlobalState} from '../models/GlobalState';
import {QuestionServer} from '../server/QuestionServer';
import {CardSortItem} from '../models/CardSortItem';
import {nextPage} from '../resources/navigation/NextPage'
import {CardSortIAAnalysisViewModel} from '../models/CardSortIAAnalysisViewModel';
import {Routes} from '../routes';

@autoinject
@nextPage(Routes.IA_VALUES_MEANINGS_INTRO.urlFragment)
export class ValuesCardsLeastAnalysis extends CardSortIAAnalysisViewModel {

    constructor(protected router: Router, public globalState: GlobalState, protected server: QuestionServer, protected authService: AuthService) {
        super(router, globalState, server, authService, "1", Routes.IA_VALUES_CARDS, null, null);
    }

    protected getReClassifyList() : CardSortItem[]
    {
        return this.notList.concat(this.slightList).concat(this.maybeList);
    }
}   

