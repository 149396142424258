import {autoinject} from 'aurelia-framework';
import {Router,RouteConfig} from 'aurelia-router';
import {GlobalState} from '../models/GlobalState';
import {QuestionServer} from '../server/QuestionServer';
import {backPage} from '../resources/navigation/NextPage'
import {ErrorObject} from '../models/ErrorObject';
import {RoutePaths, Routes} from '../routes';
import {SpecialisedPages} from '../models/SpecialisedPages';

@autoinject
@backPage()
export class SpecialisedSelectedCardSorts extends RoutePaths {

    public cardSorts: SpecialisedPages[];
    public lvl: number = 0;
    public isBusy: boolean = false;
    public errorObject: ErrorObject = null;
    public nextSpecialisedPage: SpecialisedPages;

    constructor(protected router: Router, protected globalState: GlobalState, private server: QuestionServer) {
        super();
    }

    public activate(params: any, route: RouteConfig): any {
        if (params.id && params.id.length >= 36)
        {
            if (!this.globalState.questionnaire.id || !this.globalState.questionnaire.id != params.id)
            {
                this.globalState.questionnaire.id = params.id;
                this.globalState.questionnaire.loaded = false;
            }
        } else if (this.globalState.Id)  
        {
            // Perform navigation so that we get the assessment Id present in the URL so that a refresh in the browser will not lose the clients page.
            this.router.navigateToRoute( route.name, { 'id': this.globalState.Id }, { trigger: false, replace: true });
        }

        if (this.globalState.questionnaire.id) {
            return this.server.loadSpecialisedAnalysis(this.globalState.questionnaire.id, this.globalState )
                .then(specialisedAnalysis => {
                    this.cardSorts = specialisedAnalysis.filter(itm => itm.include);
                    this.globalState.buildSpecialisedPages(specialisedAnalysis);
                    this.nextSpecialisedPage = this.globalState.nextSpecialisedPage[Routes.IA_SPECIALISED_INTRO.route];
                    this.isBusy = false;
                })
                .catch(response => {
                    this.errorObject = new ErrorObject(
                        response,
                        null,
                        "Load Questionnaire specialised analysis", 
                        "The specialised analysis data load has failed.");       
                });
        }
    }

    public nextPage(): boolean {
        return this.router.navigateToRoute( this.nextSpecialisedPage.routeRef.route, { 'id': this.globalState.Id }, { trigger: true, replace: false });
    }
}   

