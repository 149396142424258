import {autoinject} from 'aurelia-framework';
import {AuthService} from 'aurelia-authentication';
import {SecurityRoles} from '../models/SecurityRoles';
import {GlobalState} from '../models/GlobalState';
import {Router} from 'aurelia-router';
import {Routes, RoutePaths} from '../routes';

@autoinject
export class AccountsLogin extends RoutePaths {

    constructor(protected router: Router, protected globalState: GlobalState, private authService: AuthService, public securityRoles: SecurityRoles) {
        super();
        this.globalState.clearFooterDetails();
    }

    public activate() {
        this.authService.logout();
        this.authService.setResponseObject(null);
        this.router.navigate(Routes.CLIENT_HOME);
    }
}
