import {CardSortItem, selectType} from '../models/CardSortItem';

export class CardSortResult {
    public id: number = 0;
    public definite: string = "";
    public very: string = "";
    public maybe: string = "";
    public slight: string = "";
    public not: string = "";
    public none: string = "";

    public static toCardSortItem(obj: any): CardSortItem {
        let item = new CardSortItem();
        item.id = obj.id;
        item.leadin = obj.leadinText;
        item.description = obj.cardText;
        item.explanation = obj.explanation;
        item.answer = obj.answer;
        item.answerCode = obj.answerCode;

        return item;
    }

    public static toCardSortResult(obj: CardSortItem): CardSortResult {
        let item = new CardSortResult();
        switch (obj.cardPile) {
            case selectType.isNot:
                item.not = obj.description;
                break;
            case selectType.isSlight:
                item.slight = obj.description;
                break;
            case selectType.isMaybe:
                item.maybe = obj.description;
                break;
            case selectType.isVery:
                item.very = obj.description;
                break;
            case selectType.isDefinite:
                item.definite = obj.description;
                break;
            default:
                item.none = obj.description;
                break;
        }

        return item;
    }
}

export interface ICardSortResult {
    cardsortResults: CardSortResult[];
    cardsortResultsTop2: CardSortResult[];
    cardsortResultsTop3: CardSortResult[];
    displayResults: CardSortResult[];
    analysisCounts: number[];
    definiteItemsCount: number;
    analysis: any;
}

