
export class DateFormatValueConverter {

    private formatter = new Intl.DateTimeFormat('en-NZ', {day: "numeric", month: "short", year: "numeric", hour: "2-digit", minute: "numeric"});


    public toView(value):string 
    {
        if ((value) && (value > 1))
        {
            return this.formatter.format(value);
        }

        return "";
    }
  }