import {autoinject, computedFrom} from 'aurelia-framework';
import {QuestionServer} from '../server/QuestionServer';
import {Router, RouteConfig} from 'aurelia-router';
import {GlobalState} from '../models/GlobalState';
import {ErrorObject} from '../models/ErrorObject';
import {backPage, clearFullDisplay} from '../resources/navigation/NextPage';
import {Routes} from '../routes';

@autoinject
@backPage()
@clearFullDisplay()
export class RiasecHeader {

    public firstName: string = '';
    public lastName: string = '';
    public termsAndConditions: boolean = false;
    public isTermsDisplayed: boolean = false;
    public isIndependentAssessment: boolean = false;
    public isBusy: boolean = false;
    public errorObject: ErrorObject = null;

    constructor(protected router: Router, protected globalState: GlobalState, private server: QuestionServer) {
    }

    public activate(params: any, route: RouteConfig): any {
        this.globalState.questionnaire.id = null;
        if (params.id && params.id.length == 36)
        {
            if (!this.globalState.questionnaire.id || !this.globalState.questionnaire.id != params.id)
            {
                this.globalState.questionnaire.id = params.id;
                this.globalState.questionnaire.loaded = false;
            }
        } else if (this.globalState.Id)  
        {
            // Perform navigation so that we get the assessment Id present in the URL so that a refresh in the browser will not lose the clients page.
            this.router.navigateToRoute( route.name, { 'id': this.globalState.Id }, { trigger: false, replace: true });
        }

        this.globalState.questionnaire.id = null;
        if (this.globalState.questionnaire.id) {
            return this.server.loadQuestionnaire(this.globalState.questionnaire.id, this.globalState)
                .then(response => {
                    this.firstName = response.firstname;
                    this.lastName = response.surname;
                    this.termsAndConditions = response.isTermsAccepted;
                    this.isIndependentAssessment = response.isIndependentAssessment;
                })
                .catch(response => {
                    this.errorObject = new ErrorObject(
                        response,
                        null,
                        "Load existing assessment", 
                        "The assessment data load has failed.");       
                });
        }
    }

    public submitQuestionnaire(): any {
        if (!this.isBusy) {
            this.isBusy = true;
            this.errorObject = ErrorObject.EmptyError();
            return this.server.initialiseQuestionnaire({ firstname: this.firstName, surname: this.lastName, isTermsAccepted: this.termsAndConditions, isIndependentAssessment: this.isIndependentAssessment })
                .then(questionnaire => 
                {
                    this.globalState.questionnaire = questionnaire;
                    if (this.globalState.questionnaire.isIndependentAssessment)
                    {
                        this.router.navigate(Routes.IA_MAIN_INTRO.addFragments(questionnaire.id), {});
                    }
                    else
                    {
                        this.router.navigate(Routes.CARDS_VALUES.addFragments(questionnaire.id), {});
                    }
                })
                .catch(response =>
                {
                    this.errorObject = new ErrorObject(
                        response,
                        { firstname: this.firstName, surname: this.lastName },
                        "Start Assessment", 
                        "Could not get a new assessment started." );        
                    this.isBusy = false;
                });
        }
    }

    @computedFrom("firstName","lastName", "termsAndConditions","isBusy")
    public get isNotValid(): boolean {
        return (this.firstName <= ' ') || (this.lastName <= ' ') || !this.termsAndConditions || this.isBusy;
    }

    public displayTerms(): void
    {
        this.isTermsDisplayed = true;
    }
}
