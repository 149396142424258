import {BillingSummary} from '../models/BillingSummary';
import {BillingDetail} from '../models/BillingDetail';
import {BillingCycle} from '../models/BillingCycle';

export class BillingInfo {
    public billingCycleId: number = 0;
    public billingItems: BillingDetail[];
    public billingSummary: BillingSummary[];
    public billingCycles: BillingCycle[];

    public static toBillingInfo(obj: any): BillingInfo {
        let item = new BillingInfo();
        item.billingCycleId = obj.billingCycleId;
        item.billingItems = obj.billingItems.map(BillingDetail.toBillingDetail);
        item.billingSummary = obj.billingSummary.map(BillingSummary.toBillingSummary);
        item.billingCycles = obj.billingCycles.map(BillingCycle.toBillingCycle).reverse();

        return item;
    }
}

