import {Router} from 'aurelia-router';
import {AuthService} from 'aurelia-authentication';
import {GlobalState} from '../models/GlobalState';
import {QuestionServer} from '../server/QuestionServer';
import {CardSortItem, selectType} from '../models/CardSortItem';
import {CardSortAnalysisViewModel} from '../models/CardSortAnalysisViewModel';
import {ErrorObject} from '../models/ErrorObject';
import {SpecialisedPages} from '../models/SpecialisedPages';
import {RouteCnfg} from '../routes';

export class CardSortIAAnalysisViewModel extends CardSortAnalysisViewModel {

    public notList: CardSortItem[] = [];
    public slightList: CardSortItem[] = [];
    public maybeList: CardSortItem[] = [];
    public veryList: CardSortItem[] = [];
    public definiteList: CardSortItem[] = [];
    public cardList: CardSortItem[] = [];
    public nextSpecialisedPage: SpecialisedPages;

    constructor(protected router: Router, public globalState: GlobalState, protected server: QuestionServer, protected authService: AuthService, setIdParam: string, protected cardsRoute: RouteCnfg, protected introRoute: RouteCnfg, protected analysisRoute: RouteCnfg) {
        super(router, globalState, server, authService);
        this.setId = setIdParam;

        if (this.introRoute)
        {
            this.nextSpecialisedPage = this.globalState.nextSpecialisedPage[this.introRoute.route];
        }
    }

    public attached(): void{
        // If a refresh is done then global state gets cleared ... so reload it.
        if (this.introRoute)
        {
            this.checkNextSpecialisedPage(this.introRoute.route);
        }
    }

    public nextPage():boolean {
        if (this.isReportAccess)
        {
            return this.router.navigateToRoute(this.analysisRoute.route, { 'id': this.globalState.Id }, { trigger: true, replace: false });
        }
        else if (this.introRoute)
        {
            this.checkNextSpecialisedPage(this.introRoute.route);
            return this.router.navigateToRoute(this.nextSpecialisedPage.routeRef.route, { 'id': this.globalState.Id }, { trigger: true, replace: false });
        }
    }

    protected getReClassifyList() : CardSortItem[]
    {
        return this.definiteList.concat(this.veryList);
    }

    public reClassify(event: Event): Promise<any>
    {
        // Perform navigation so that we get the assessment Id present in the URL so that a refresh in the browser will not lose the clients page.
        return this.server.loadCardData(this.globalState.questionnaire.id, this.setId, this.globalState, this).then(
            response => {
                this.isBusy = true;
                let list = this.deSelectList(this.getReClassifyList());
                return Promise.all(list).then( () =>
                {
                    this.isBusy = false;
                    return this.router.navigateToRoute( this.cardsRoute.route, { 'id': this.globalState.Id }, { trigger: true, replace: false });
                });
            });
    }


    protected deSelectList(list: CardSortItem[]): Promise<any>[]
    {
        let promises = [];
        if (list.length > 0) {
            for (let i = 0; i < list.length; i++) {
                let item = list[i];
                item.cardPile = selectType.isNone;
                let promise = new Promise<void>((resolve, reject) => 
                    {
                        this.updateCardAnswer(item, resolve, reject);
                    });
                promises.push(promise);
            }
        }

        return promises;
    }

    protected updateCardAnswer(card: CardSortItem, resolve: any, reject: any): Promise<void> {
        this.errorObject = ErrorObject.EmptyError();
        return this.server.saveCardAnswer(this.globalState.questionnaire.id, '2', this.setId, card)
            .then(response => {
                // if there was any error on the previous attempt - throw it away now.
                return resolve();
            })
            .catch(reason => 
            {
                this.errorObject = new ErrorObject(
                    reason,
                    card,
                    'Save Card State', 
                    `The card with the title of "<span class='errorHighlight'>${card.description}</span>" has not had it's current state saved.`);       

                return reject();
            });
    }
    
    protected checkNextSpecialisedPage(route: string): Promise<void>
    {
        if (!this.nextSpecialisedPage)
        {
            if (this.globalState.questionnaire.id) {
                return this.server.loadSpecialisedAnalysis(this.globalState.questionnaire.id, this.globalState )
                    .then(specialisedAnalysis => {
                        this.globalState.buildSpecialisedPages(specialisedAnalysis);
                        this.nextSpecialisedPage = this.globalState.nextSpecialisedPage[route];
                        this.isBusy = false;
                    })
                    .catch(response => {
                        this.errorObject = new ErrorObject(
                            response,
                            null,
                            "Load next specialised card sort page", 
                            "The specialised analysis data load has failed.");       
                    });
            }
    
        }
    }
}   