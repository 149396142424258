
export class BillingDetail {
    public company: string;
    public billingGroupRuleId: number;
    public assessmentAmount: number;

    /// <summary>
    /// The amount the user will be charged for this billing cycle for the assessment. If the user has the payment portal enabled, then this will only ever be the amount of the report.
    /// </summary>
    public subscriptionCharge: number;
    public billingGroupCount: number

    public billingItemPK: number

    /// <summary>
    /// The billing cycle with which this item is associated
    /// </summary>
    public billingCycleId: number;

    /// <summary>
    /// This is actually the Questionnaire OID/FK Reference
    /// </summary>
    public assessmentId: number;

    public billingState: number;

    /// <summary>
    /// The item description - currently just defined as the clients name from the questionnaire/assessment.
    /// </summary>
    public billingDescription: string;

    /// <summary>
    /// Yes - the item is/has been charged to the user. No the item has not been charged. - Null  not yet charged (will only happen if the answer count is zero). I.e. a link has been sent, but not yet used.
    /// This typically means they will get charged in the next billing cycle and this is just a place holder to show they have not yet been charged for this assessment.
    /// </summary>
    public isReportCharged: boolean;
    /// <summary>
    /// Yes - the item is/has been charged to the user. No the item has not been charged. - Null  not yet charged (will only happen if the answer count is zero). I.e. a link has been sent, but not yet used.
    /// This typically means they will get charged in the next billing cycle and this is just a place holder to show they have not yet been charged for this assessment.
    /// </summary>
    public isAssessmentCharged: boolean;


    /// <summary>
    /// The monthly subscription charge due. The actual amount varies depending on the billing group rule id. For rule zero, the amount is per user. For all other groups it is a charge per company and billing rule group.
    /// </summary>

    public answersCount: number;
    public firstAnswered: Date;
    public lastAnswered: Date;
    public companyCount: number;
    public userCount: number;
    public assessmentCreatedAt: Date;
    public assessmentLastAccessedAt: Date;
    public reportCreatedAt: Date;
    public reportLastAccessedAt: Date;
    public appointment: Date;
    public auditCreatedAt: Date;

    public userPK: number;

    public knownAsName: string;

    public static toBillingDetail(obj: any): BillingDetail {
        let item = new BillingDetail();
        item.company = obj.company;
        item.billingGroupRuleId = obj.billingGroupRuleId;
        item.billingGroupCount = obj.billingGroupCount;
        item.assessmentAmount = obj.itemAmount;
        item.subscriptionCharge = obj.subscriptionCharge;
        item.knownAsName = obj.knownAsName;

        item.billingItemPK = obj.billingItemPK;
        item.billingCycleId = obj.billingCycleId;
        item.assessmentId = obj.assessmentId;
        item.billingState = obj.billingState;
        item.billingDescription = obj.billingDescription;
        item.isReportCharged = obj.isReportChargedEx;
        item.isAssessmentCharged = obj.isAssessmentChargedEx;
        item.answersCount = obj.answersCount;
        item.firstAnswered = new Date(obj.firstAnswerDate);
        item.lastAnswered = new Date(obj.lastAnswerDate);
        item.companyCount = obj.companyCount;
        item.userCount = obj.userCount;
        item.assessmentCreatedAt = new Date(obj.assessmentCreatedAt);
        item.assessmentLastAccessedAt = new Date(obj.assessmentLastAccessedAt);
        item.reportCreatedAt = new Date(obj.reportCreatedAt);
        item.reportLastAccessedAt = new Date(obj.reportLastAccessedAt);
        item.appointment = new Date(obj.appointment);
        item.auditCreatedAt = new Date(obj.auditCreatedAt);
    
        return item;
    }
}

