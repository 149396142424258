import {autoinject} from 'aurelia-framework';
import {CardSortResult} from '../../models/CardSortResult';

@autoinject
export class LeastImportantValueConverter {


  /**
   * @results  {CardSortResult}  routes            the results array to be filtered/converted
   * @return {CardSortResult[]}      only those elements with a slight or maybe result present
   */
  toView(results: CardSortResult[]): CardSortResult[] {
    return results.filter(itm => (itm.slight > ' '  || itm.not > ' ' || itm.maybe > ' '));
  }
}
