import {autoinject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {GlobalState} from '../models/GlobalState';
import {AdministrationServer} from '../server/AdministrationServer';
import {backPage} from '../resources/navigation/NextPage'
import {ErrorObject} from '../models/ErrorObject';
import {RoutePaths} from '../routes';

//NOTE: the html refers to the ROUTE paths required
@autoinject
@backPage()
export class Users extends RoutePaths {

    public users: any[] = [];
    public errorObject: ErrorObject = null;

    constructor(protected router: Router, protected globalState: GlobalState, private server: AdministrationServer) {
        super();
        this.globalState.clearFooterDetails();
    }

    public activate(): any 
    {
        return this.server.loadUsers()
                .then(response => {
                    this.users = response;
                    this.globalState.users = response;
                })
                .catch(response => {
                    this.errorObject = new ErrorObject(
                        response,
                        null,
                        "Load User List", 
                        "The data load of the users list has failed.");       
                });
    }
}   