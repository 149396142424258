import {autoinject} from 'aurelia-framework';
import {Router, RouteConfig} from 'aurelia-router';
import {AuthService} from 'aurelia-authentication';
import {GlobalState} from '../models/GlobalState';
import {QuestionServer} from '../server/QuestionServer';
import {nextPage} from '../resources/navigation/NextPage'
import {CardSortAnalysisViewModel} from '../models/CardSortAnalysisViewModel';
import {Routes} from '../routes';

@autoinject
@nextPage(Routes.IA_INTERESTS_INTRO.urlFragment)
export class SkillsPreferredAnalysis extends CardSortAnalysisViewModel {

    constructor(protected router: Router, public globalState: GlobalState, protected server: QuestionServer, protected authService: AuthService) {
        super(router, globalState, server, authService);
        this.setId = "2";
    }

    public activate(params: any, route: RouteConfig): Promise<any> {
        super.activate(params, route).then(obj => {

                if (this.analysis)
                {
                    this.analysis[0].id  = 'people';
                    this.analysis[0].alt = this.analysis[0].id
                    this.analysis[1].id  = 'ideas';
                    this.analysis[1].alt = 'thinking and ideas';
                    this.analysis[2].id  = 'knowledge';
                    this.analysis[2].alt = this.analysis[2].id 
                    this.analysis[3].id  = 'handson';
                    this.analysis[3].alt = 'hands-on and doing';
                    this.analysis.sort((a, b) => b.score - a.score );
                }

                return obj;
            });

        return Promise.resolve(true);
    }

}   

