import {NavModel} from 'aurelia-router';


export class SubMenuValueConverter {

    constructor() {
    }


  /**
   * route toView predictator  rebuild an alternate list that has parent/child items.
   * @param  {NavModel[]}  routes            the routes array to convert
   * @return {NavModel[]}  the returned list of navigation entries converted to have nested children
   */
  toView(routes: NavModel[]): NavModel[] {
    let menuItems = [];
    routes.forEach((menuItem) => {
        if (menuItem.settings.parentMenu) {
            // Submenu children
            let parent = menuItems.find(x => x.config.name == menuItem.settings.parentMenu);

            // If it doesn't exist, then something went wrong, so not checking 
            if (parent.children === null)
            {
              (<any>parent).children = [];
    
              // Create empty children
              (<any>menuItem).children = null;
            }

            parent.children.push(menuItem);                   
        } else {
            menuItems.push(menuItem);

            // Create empty children
            (<any>menuItem).children = null;
        }
    });

    return menuItems;    
  }
}
