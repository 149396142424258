import {DOM} from 'aurelia-pal';
import {autoinject, bindable} from 'aurelia-framework';
import {ScaleResult, CardsScaleResult} from '../../models/ScaleResult';

@autoinject
export class CardAnalysisSingleCustomElement {
    @bindable 
    public editable: boolean = false;

    @bindable 
    public longForm: boolean = false;

    @bindable 
    public isTopValuesDisplayed: boolean = false;

    @bindable
    public items: ScaleResult[] = [];

    @bindable 
    public results: CardsScaleResult;

    private populateEvent: CustomEvent;
    private saveEvent: CustomEvent;

    public aResult: ScaleResult;
    public bResult: ScaleResult;
    public cResult: ScaleResult;
    public dResult: ScaleResult;
    public eResult: ScaleResult;
    public fResult: ScaleResult;
    public gResult: ScaleResult;
    public hResult: ScaleResult;
    public iResult: ScaleResult;
    public jResult: ScaleResult;
    public kResult: ScaleResult;
    public lResult: ScaleResult;
    public mResult: ScaleResult;
    public nResult: ScaleResult;
    public oResult: ScaleResult;
    public pResult: ScaleResult;
    public qResult: ScaleResult;
    public rResult: ScaleResult;
    public sResult: ScaleResult;


    constructor(private readonly element: Element) {
        this.populateEvent = DOM.createCustomEvent('onpopulate', { bubbles: true, cancelable: true, detail: {}});
        this.saveEvent = DOM.createCustomEvent('onsave', { bubbles: true, cancelable: true, detail: {} });
    }

    public populateStatement(evt: Event): void {
        this.element.dispatchEvent(this.populateEvent);
    }

    public savePage(evt: Event): void
    {
      this.element.dispatchEvent(this.saveEvent);
    }


    public itemsChanged(value: ScaleResult[], oldValue: ScaleResult[])
    {
      if (value)
      {
        this.aResult = value[0];
        this.bResult = value[1];
        this.cResult = value[2];
        this.dResult = value[3];
        this.eResult = value[4];
        this.fResult = value[5];
        this.gResult = value[6];
        this.hResult = value[7];
        this.iResult = value[8];
        this.jResult = value[9];
        this.kResult = value[10];
        this.lResult = value[11] ?? ScaleResult.EmptyResult();
        this.mResult = value[12] ?? ScaleResult.EmptyResult();
        this.nResult = value[13] ?? ScaleResult.EmptyResult();
        this.oResult = value[14] ?? ScaleResult.EmptyResult();
        this.pResult = value[15] ?? ScaleResult.EmptyResult();
        this.qResult = value[16] ?? ScaleResult.EmptyResult();
        this.rResult = value[17] ?? ScaleResult.EmptyResult();
        this.sResult = value[18] ?? ScaleResult.EmptyResult();
      }
    }
}
