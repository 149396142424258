import {autoinject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {SecurityRoles} from '../models/SecurityRoles';
import {GlobalState} from '../models/GlobalState';
import {CardSortCore} from '../models/CardSortCore';
import {QuestionServer} from '../server/QuestionServer';
import {nextPage,clearFullDisplay} from '../resources/navigation/NextPage';
import {Routes} from '../routes';

@autoinject
@nextPage(Routes.IA_SKILLS_MOST.urlFragment)
@clearFullDisplay(true)
export class SkillsCards extends CardSortCore {
    
    constructor(router: Router, public globalState: GlobalState, server: QuestionServer, public securityRoles: SecurityRoles) {
        super(router, globalState, server, securityRoles);
        this.setIdFragment = "2";
        this.isNextPageVisible = true;
        this.isIndependentAssessment = true;
    }

    public onquicktip(event: Event): any
    {
        alert("If you find it difficult to identify your preferred skills ask your future self this “Is this a skill I would like to use if I have trained into my career and am experienced and feel confident?”");
        return false;
    }
}
