import {autoinject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {BackgroundInfo, IBackgroundInfo} from '../models/backgroundInfo';
import {GlobalState} from '../models/GlobalState';
import {QuestionServer} from '../server/QuestionServer';
import {nextPage, clearFullDisplay} from '../resources/navigation/NextPage'
import {ErrorObject} from '../models/ErrorObject';
import {Routes} from '../routes';

@autoinject
@nextPage(Routes.ANALYSIS_RESULTS)
@clearFullDisplay()
export class BackgroundInfoCapture implements IBackgroundInfo {

    public education: string;
    public work: string;
    public leisure: string;
    public genogram: string;
    public info: BackgroundInfo;
    public lvl: number = 0;
    public isBusy: boolean = false;
    public errorObject: ErrorObject = null;

    constructor(protected router: Router, protected globalState: GlobalState, private server: QuestionServer) {
        this.info = new BackgroundInfo();
    }

    public activate(params, route, navInstruction) {
        if (params.id)
        {
            this.globalState.questionnaire.id = params.id;
        }
        if (this.globalState.questionnaire.id) {
            return this.server.loadBackgroundInfo(this.globalState.questionnaire.id, this)
                .then(response => {
                    this.info = response;
                })
                .catch(response => {
                    this.errorObject = new ErrorObject(
                        response,
                        null,
                        "Load Questionnaire RIASEC analysis", 
                        "The RIASEC questionnaire analysis data load has failed.");       
                });
        }
    }

    public get isNotModified(): boolean {
    return this.info.education == this.education
            && this.info.work == this.work
            && this.info.leisure == this.leisure
            && this.info.genogram == this.genogram;
    }

    public savePage() {
        if (!this.isBusy) {
            this.isBusy = true;
            this.errorObject = ErrorObject.EmptyError();
            let obj = BackgroundInfo.toBackgroundInfo(this);

            return this.server.saveBackgroundInfo(this.globalState.questionnaire.id, obj)
                .then(response => {
                    this.info = obj;
                    this.isBusy = false;
                })
                .catch(response => {
                    this.errorObject = new ErrorObject(
                        response,
                        obj,
                        "Save Career Details", 
                        "The career detail changes have not been saved.");       
                    this.isBusy = false;
                });
        }
    }
}   