import {RiasecQuestionSet} from '../models/RiasecQuestionSet';
import {CardSortItem} from '../models/CardSortItem';

export class RiasecQuestionnaire {
    public id: string;
    public loaded: boolean;
    public type: string;
    public version: string;
    public firstname: string;
    public surname: string;
    public name: string;
    public emailAddress: string;
    public appointment: Date;
    public careerA: string;
    public careerB: string;
    public careerRiasecA: string;
    public careerRiasecB: string;
    public notes: string;
    public pagesVisited: string;
    public questionSets: RiasecQuestionSet[];
    public cards: CardSortItem[];
    public paymentReferenceId: string;
    public isTermsAccepted: boolean = false;
    public isPaymentRequired: boolean = false;
    public isIndependentAssessment: boolean = false;
    public isPaid: boolean = false;
    public isComplete: boolean = false;
    public isValuesCompleted: boolean = false;
    public isSkillsCompleted: boolean = false;
    public isInterestsCompleted: boolean = false;
    public isQ40Completed: boolean = false;
    public isAnalysisViewable: boolean = false;
    public isCardSortOccupationRequired: boolean = false;
    public isCardSortHelpingRequired: boolean = false;
    public isCardSortBusinessRequired: boolean = false;
    public isCardSortEngineeringRequired: boolean = false;
    public isCardSortScienceRequired: boolean = false;
    public isCardSortArtsRequired: boolean = false;
    public isCardSortTradesRequired: boolean = false;
    public isCardSortCreativityRequired: boolean = false;
    public isMostMeaningsOnly: boolean = false;

    constructor() {
        this.type = "RIASEC";
        this.version = "1.0";
        this.loaded = false;
        this.appointment = new Date();
        this.questionSets = [new RiasecQuestionSet()];
    }

    public toQuestionnaire(obj: any): void {
        this.id = obj.id;
        this.type = obj.type;
        this.version = obj.version;
        this.firstname = obj.firstname;
        this.surname = obj.surname;
        this.name = this.firstname + ' ' + this.surname;
        this.emailAddress = obj.emailAddress;
        this.appointment = (obj.appointment) ? new Date(obj.appointment) : new Date();
        this.careerA = obj.careerA;
        this.careerB = obj.careerB;
        this.careerRiasecA = obj.careerRiasecA;
        this.careerRiasecB = obj.careerRiasecA;
        this.paymentReferenceId = obj.paymentReferenceId;
        this.isPaid = obj.isPaid;
        this.isPaymentRequired = obj.isPaymentRequired;
        this.isIndependentAssessment = obj.isIndependentAssessment;
        this.isComplete = obj.isComplete;
        this.isTermsAccepted = obj.isTermsAccepted;
        this.isValuesCompleted = obj.isValuesCompleted;
        this.isSkillsCompleted = obj.isSkillsCompleted;
        this.isInterestsCompleted = obj.isInterestsCompleted;
        this.isQ40Completed = obj.isQ40Completed;
        this.isAnalysisViewable = obj.isAnalysisViewable;
        this.isCardSortOccupationRequired = obj.isCardSortOccupationRequired;
        this.isCardSortHelpingRequired = obj.isCardSortHelpingRequired;
        this.isCardSortBusinessRequired = obj.isCardSortBusinessRequired;
        this.isCardSortEngineeringRequired = obj.isCardSortEngineeringRequired;
        this.isCardSortScienceRequired = obj.isCardSortScienceRequired;
        this.isCardSortArtsRequired = obj.isCardSortArtsRequired;
        this.isCardSortTradesRequired = obj.isCardSortTradesRequired;
        this.isCardSortCreativityRequired = obj.isCardSortCreativityRequired;
        this.isMostMeaningsOnly = obj.isMostMeaningsOnly;
        this.notes = obj.notes;
        this.pagesVisited = (obj.pagesVisited) ? obj.pagesVisited : "";
        if (obj.questionSets)
        {
            this.questionSets = obj.questionSets.map(RiasecQuestionSet.toQuestionSet);
        }
        
        this.loaded = true;
    }
}    