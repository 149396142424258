
export class BackgroundInfo {

    public education: string;
    public work: string;
    public leisure: string;
    public genogram: string;

    constructor() {
    }

    public static toBackgroundInfo(obj: any): BackgroundInfo 
    {
        var itm = new BackgroundInfo();
        itm.education = obj.education;
        itm.work = obj.work;
        itm.leisure = obj.leisure;
        itm.genogram = obj.genogram;
        return itm;
    }
}     

export interface IBackgroundInfo {
    education: string;
    work: string;
    leisure: string;
    genogram: string;
}

