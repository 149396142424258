import {autoinject} from 'aurelia-framework';
import {nextPage} from '../resources/navigation/NextPage';
import {Routes} from '../routes';
import {RootAssessmentPage} from '../models/RootAssessmentPage';

@autoinject
@nextPage(Routes.IA_VALUES_MEANINGS_QUESTIONS.urlFragment)
export class ValuesIntro extends RootAssessmentPage {
    
}    

