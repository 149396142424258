import {autoinject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {SecurityRoles} from '../models/SecurityRoles';
import {GlobalState} from '../models/GlobalState';
import {CardSortCore} from '../models/CardSortCore';
import {QuestionServer} from '../server/QuestionServer'
import {nextPage} from '../resources/navigation/NextPage';
import {Routes} from '../routes';

@autoinject
@nextPage(Routes.IA_CAREER_OCCUPATION_PREFERRED.urlFragment)
export class OccupationalCardSort extends CardSortCore {

    constructor(router: Router, public globalState: GlobalState, server: QuestionServer, public securityRoles: SecurityRoles) {
        super(router, globalState, server, securityRoles);
        this.setIdFragment = "7";
        this.isNextPageVisible = true;
        this.isIndependentAssessment = true;
    }
}
