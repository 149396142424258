import {Aurelia, LogManager} from 'aurelia-framework'
import {PLATFORM} from 'aurelia-pal'
import environment from './environment';
import authConfig from './authConfig';
import {ConsoleAppender} from 'aurelia-logging-console';

//Configure Bluebird Promises.
//Note: You may want to use environment-specific configuration.
(<any>Promise).config({
  warnings: {
    wForgottenReturn: false
  },
  longStackTraces: false   
});

//Bluebird.config( { warnings: false, longStackTraces: false } );

LogManager.addAppender(new ConsoleAppender());
LogManager.setLevel(LogManager.logLevel.debug);

export function configure(aurelia: Aurelia) {
  // We want to configure the URL to be used for authentication and backend API calls to be based on the website URL from where we have been loaded.
  const pageName = 'index.html';
  let theConfig = authConfig;
  let urlRoot = PLATFORM.location.href.toLowerCase();
  let idx = urlRoot.indexOf(pageName);
  if (idx > 0)
  {
    urlRoot = urlRoot.substring(0,idx);
  } else 
  { 
      // So they did not type in the actual url we have just part of the equation ...
      idx = urlRoot.indexOf("#");
      if (idx > 0)
      {
          // so remove everything after the # which should leave us with the origin and path.
          urlRoot = urlRoot.substring(0,idx);
      }
  }

  theConfig.providers.google.redirectUri = urlRoot + pageName;
  
  aurelia
    .use
    .standardConfiguration()
    .feature('resources')
    .globalResources([PLATFORM.moduleName("./resources/value-converters/rolesFilterValueConverter"), 
                      PLATFORM.moduleName("./resources/value-converters/authorisationValueConverter"),
                      PLATFORM.moduleName("./resources/value-converters/leastImportantValueConverter"),
                      PLATFORM.moduleName("./resources/value-converters/mostImportantValueConverter"),
                      PLATFORM.moduleName("./resources/value-converters/mostImportantMaybeValueConverter"),
                      PLATFORM.moduleName("./resources/elements/card-Analysis-Single"),
                      PLATFORM.moduleName("./resources/elements/card-Analysis.html"),
                      PLATFORM.moduleName("./resources/elements/card-Analysis-Least.html"),
                      PLATFORM.moduleName("./resources/elements/card-Analysis-Most.html"),
                      PLATFORM.moduleName("./resources/elements/card-Select.html"),
                      PLATFORM.moduleName("./resources/elements/card-Stack.html"),
                      PLATFORM.moduleName("./resources/elements/busyIndicator"),
                      PLATFORM.moduleName("./resources/elements/helpIndicator"),
                      PLATFORM.moduleName("./resources/elements/errorHandler"),
                      PLATFORM.moduleName("./resources/elements/next-Page-Handler"),
                      PLATFORM.moduleName("./resources/elements/reSortNotification"),
                      PLATFORM.moduleName("./resources/elements/authorisedAnchor")])
    //.plugin('aurelia-animator-css')
    .plugin(PLATFORM.moduleName('@appex/aurelia-dompurify'), {ALLOWED_TAGS: ['b', 'i', 'em', 'p']})
    .plugin(PLATFORM.moduleName('aurelia-api'), configure => {
      configure
        .registerEndpoint('accounts', urlRoot+ 'api/accounts/')
        .registerEndpoint('questions', urlRoot+ 'api/assessments/')
        .registerEndpoint('administration', urlRoot+ 'api/accounts/')
        .registerEndpoint('payments', urlRoot+ 'api/payments/')
        .registerEndpoint('tokens', urlRoot+ 'api/tokens/')
    })
    /* configure aurelia-authentication */
    .plugin(PLATFORM.moduleName('aurelia-authentication'), baseConfig => {
        baseConfig.configure(theConfig);
    });

  if (environment.debug) {
    aurelia.use.developmentLogging();
  }


  if (environment.testing) {
    aurelia.use.plugin('aurelia-testing');
  }

  aurelia.start().then(() => aurelia.setRoot());
}
