import {autoinject} from 'aurelia-framework';
import {NavModel} from 'aurelia-router';
import {SecurityRoles} from '../../models/SecurityRoles';

@autoinject
export class RolesFilterValueConverter {

    constructor(private securityRoles: SecurityRoles) {
    }


  /**
   * route toView predictator on route.config.auth === isAuthenticated
   * @param  {RouteConfig}  routes            the routes array to convert
   * @param  {boolean}      isAuthenticated   authentication status
   * @return {boolean}      show/hide element
   */
  toView(routes: NavModel[], isAuthenticated: boolean): NavModel[] {
    let roles = this.securityRoles.Roles;
    return routes.filter(route => SecurityRoles.RouteAuthorisationFilter(route.config, roles, isAuthenticated));
  }
}
