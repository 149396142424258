import {autoinject, computedFrom} from 'aurelia-framework';
import {Router, RouteConfig} from 'aurelia-router';
import {AuthService} from 'aurelia-authentication';
import {GlobalState, IGlobalState} from '../models/GlobalState';
import {ErrorObject} from '../models/ErrorObject';
import {CardSortResult, ICardSortResult} from '../models/CardSortResult';
import {QuestionServer} from '../server/QuestionServer';
import {clearFullDisplay} from '../resources/navigation/NextPage'
import {CommonRoles} from './CommonRoles';

@autoinject
@clearFullDisplay()
export class CardSortAnalysisViewModel extends CommonRoles implements ICardSortResult, IGlobalState {

    public cardsortResults: CardSortResult[] = [];
    public cardsortResultsTop2: CardSortResult[] = [];
    public cardsortResultsTop3: CardSortResult[] = [];
    public displayResults: CardSortResult[] = [];
    public definiteItemsCount: number = 0;
    public analysisCounts: any[] = [];
    public analysis: any[] = null;
    public analysisPeople: any[] = null;
    public analysisIdeas: any[] = null;
    public analysisKnowledge: any[] = null;
    public analysisDoing: any[] = null;
    public columns: number = 5;
    public fullPrintOption: boolean = true;
    protected setId: string = '1';
    public errorObject: ErrorObject = null;
    public isBusy: boolean;

    constructor(protected router: Router, public globalState: GlobalState, protected server: QuestionServer, protected authService: AuthService) {
        super(authService);
        this.fullPrintOption = false;
    }

    public activate(params: any, route: RouteConfig): Promise<any> {
        if (params.id && params.id.length >= 36)
        {
            if (!this.globalState.questionnaire.id || !this.globalState.questionnaire.id != params.id)
            {
                this.globalState.questionnaire.id = params.id;
                this.globalState.questionnaire.loaded = false;
            }
        } else if (this.globalState.Id)  
        {
            // Perform navigation so that we get the assessment Id present in the URL so that a refresh in the browser will not lose the clients page.
            this.router.navigateToRoute( route.name, { 'id': this.globalState.Id }, { trigger: false, replace: true });
        }
        
        if (this.globalState.questionnaire.id) 
        {
            return this.server.loadCardAnalysisResults(this.globalState.questionnaire.id , this.setId, this.globalState, this)
                .then(response => {
                    if (this.isQuestionnaireAnalysisUser)
                    {
                        this.isFullPrint = false;
                    }                         
                    if (this.analysis)
                    {
                        this.analysisPeople = this.analysis[0];
                        this.analysisIdeas = this.analysis[1];
                        this.analysisKnowledge = this.analysis[2];
                        this.analysisDoing = this.analysis[3];
                    }

                    return this.analysis;
                })
                .catch(reason => 
                {
                    this.errorObject = new ErrorObject(
                        reason,
                        this.globalState.questionnaire,
                        'Load card sort analysis results', 
                        'There has been an error reported attempting to load the analysis of the card data. Try again?');       
                    this.isBusy = false;
                });
        } 
        else
        {
            this.fullPrintOption = false;
        }
    }

    @computedFrom("fullPrintOption")
    public get isFullPrint(): boolean
    {
        return this.fullPrintOption;
    }

    public set isFullPrint(value: boolean)
    {
        this.fullPrintOption = value;
        if (value)
        {
            // Full print. Print all the columns ...
            this.columns = 5;
            this.displayResults = this.cardsortResults;
        }
        else if (this.definiteItemsCount === 0)
        {
            // Partial print -- no items in the top column so print 3 columns instead of 2.
            this.columns = 3;
            this.displayResults = this.cardsortResultsTop3;
        }
        else
        {
            this.columns = 2;
            this.displayResults = this.cardsortResultsTop2;
        }
    }
}   
