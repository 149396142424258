import {autoinject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {AuthService} from 'aurelia-authentication';
import {GlobalState} from '../models/GlobalState';
import {SecurityRoles} from '../models/SecurityRoles';
import {QuestionServer} from '../server/QuestionServer';
import {backPage} from '../resources/navigation/NextPage'
import {CardSortIAAnalysisViewModel} from '../models/CardSortIAAnalysisViewModel';
import {Routes} from '../routes';

@autoinject
@backPage()
export class EngineeringMatrix extends CardSortIAAnalysisViewModel {

    constructor(protected router: Router, public globalState: GlobalState, protected server: QuestionServer, protected authService: AuthService, protected securityRoles: SecurityRoles) {
        super(router, globalState, server, authService, "4", Routes.IA_ENGINEERING_CARDS, Routes.IA_ENGINEERING_INTRO, Routes.IA_ENGINEERING_ANALYSIS);
    }
}   
