import {autoinject} from 'aurelia-framework';
import {HttpClient, HttpResponseMessage} from 'aurelia-http-client';
import {ContactUsInfo} from '../models/ContactUsInfo';

const contactUsUri = 'api/contactUs/SendContactUsRequest';

@autoinject
export class Server
{

   constructor(private http: HttpClient) {
        this.http.configure(config => 
        {
            config.withHeader('Content-Type', 'application/json;charset=utf-8')
        });           
    }

    public sendContactUsRequest(info: ContactUsInfo): Promise<HttpResponseMessage> {
        return this.http.post(contactUsUri, info);
    }
}