import {autoinject} from 'aurelia-framework';
import {Router, RouteConfig} from 'aurelia-router';
import {AuthService} from 'aurelia-authentication';
import {GlobalState} from '../models/GlobalState';
import {QuestionServer} from '../server/QuestionServer';
import {ErrorObject} from '../models/ErrorObject';
import {CareerAttributeItem} from '../models/CareerAttributeItem';
import {Routes} from '../routes';
import {backPage} from '../resources/navigation/NextPage'

@autoinject
@backPage()
export class CDAAnalysis {

    public attributes: CareerAttributeItem[] = [];
    public errorObject: ErrorObject = null;
    public isNextPageDisabled: boolean = true;
    public isBusy: boolean;

    constructor(protected router: Router, public globalState: GlobalState, protected server: QuestionServer, protected authService: AuthService) {
    }

    public activate(params: any, route: RouteConfig): Promise<any> {
        if (params.id && params.id.length >= 36)
        {
            if (!this.globalState.questionnaire.id || !this.globalState.questionnaire.id != params.id)
            {
                this.globalState.questionnaire.id = params.id;
                this.globalState.questionnaire.loaded = false;
            }
        } else if (this.globalState.Id)  
        {
            // Perform navigation so that we get the assessment Id present in the URL so that a refresh in the browser will not lose the clients page.
            this.router.navigateToRoute( route.name, { 'id': this.globalState.Id }, { trigger: false, replace: true });
        }
        
        if (this.globalState.questionnaire.id) 
        {
            return this.server.loadAttributesData(this.globalState.questionnaire, this.globalState)
                .then(attributes => {
                    this.attributes = attributes;

                    // Have the items been sorted/prioritised? If yes then resort based on the priority/rank.
                    if (this.attributes.some( itm => itm.rank && itm.rank > 0))
                    {
                        this.attributes = this.attributes.sort((a,b) => a.rank - b.rank);
                    }
                    })
                .catch(reason => 
                {
                    this.errorObject = new ErrorObject(
                        reason,
                        this.globalState.questionnaire,
                        'Load card sort analysis results', 
                        'There has been an error reported attempting to load the analysis of the card data. Try again?');       
                    this.isBusy = false;
                });
        } 
    }

    public nextPage(): boolean
    {

        return this.router.navigateToRoute(Routes.IA_END_STAGE1.route, { id: this.globalState.Id }, { trigger: true, replace: false });
    }
}   

