import {autoinject} from 'aurelia-framework';
import {AuthService} from 'aurelia-authentication';
import {Router, RouteConfig} from 'aurelia-router';
import {GlobalState} from '../models/GlobalState';
import {ErrorObject} from '../models/ErrorObject';
import {CardSortItemEx, ICardSortItemExList} from '../models/CardSortItemEx';
import {CardSortAnswerEx} from '../models/CardSortAnswerEx';
import {QuestionServer} from '../server/QuestionServer';
import {Routes} from '../routes';
import {CommonRoles} from '../models/CommonRoles';

@autoinject
export class ValuesMeaningsCardSort extends CommonRoles implements ICardSortItemExList {

    public optionsList:CardSortItemEx[] = [];
    public cardList: CardSortItemEx[] = [];
    public card: CardSortItemEx;
    private index: number;
    public isNextPageDisabled: boolean = true;
    public nextPageBtn: HTMLButtonElement;
    public errorObject: ErrorObject = null;
    public isBusy: boolean;
    public isEndOfCards: boolean = false;
    public isOptionsDisplayed: boolean = false;
    

    constructor(protected router: Router, public globalState: GlobalState, protected server: QuestionServer, protected authService: AuthService) {
        super(authService);
    }

    public activate(params: any, route: RouteConfig): any {
        if (params.id && params.id.length >= 36)
        {
            if (!this.globalState.questionnaire.id || !this.globalState.questionnaire.id != params.id)
            {
                this.globalState.questionnaire.id = params.id;
                this.globalState.questionnaire.loaded = false;
            }
        } else if (this.globalState.Id)  
        {
            // Perform navigation so that we get the assessment Id present in the URL so that a refresh in the browser will not lose the clients page.
            this.router.navigateToRoute( route.name, { 'id': this.globalState.Id }, { trigger: false, replace: true });
        }
    }

    public attached(): any {
        this.globalState.clearNowVersion();
        if (this.globalState.questionnaire.id && this.cardList.length == 0) 
        {
            return this.server.loadCardMeaningsData(this.globalState.questionnaire, this.globalState, this)
                .then(obj => {
                    this.index = 0;
                    if ((this.cardList) && (this.cardList.length > 0))
                    {
                        this.card = this.cardList[this.index];
                        this.doNextPageEnabled();
                    }
                    return obj;
                })
                .catch(reason => 
                {
                    this.errorObject = new ErrorObject(
                        reason,
                        this.globalState.questionnaire,
                        'Load Values Cards Meanings', 
                        'There has been an error reported attempting to load the analysis of the card data. Try again?');       
                    this.isBusy = false;
                    return reason;
                });
        } 
    }

    public otherMeaningKeyup(event: Event)
    {
        // this is just about getting the next key enabled ...
        // the on change on the card.otherText deals with updating the card answerText that will get saved ...
        let meaning = <CardSortAnswerEx>(<any>event.target).model;
        if (meaning.isOtherItem && (this.card.otherText) && this.card.otherText.length > 10)
        {
            this.isNextPageDisabled = false;
        }
    }

    public otherMeaning(event: Event): boolean {
        return this.doNextPageEnabled();
    }

    public meaningBtn(event: any): boolean {
        let meaning = <CardSortAnswerEx>event.target.model;
        this.card.DoSelectMeaning(meaning);

        // let the event keep on bubbling ...
        return this.doNextPageEnabled();
    }

    private doNextPageEnabled(): boolean
    {
        let priorBtnState = this.isNextPageDisabled;
        this.isNextPageDisabled = !(this.card) || !(this.card.answerText);

        // Give the next button focus on state change to enabled so that enter can be used to go the next question.
        if (priorBtnState != this.isNextPageDisabled && !this.isNextPageDisabled)
        {
            this.nextPageBtn.disabled = false;
            this.nextPageBtn.focus();
        }

        // let the event keep on bubbling ...
        return true;
    }

    public backPage() {
        if (--this.index < 0) {
            this.index = 0;
            this.router.navigateBack();
        }
        else {
            this.errorObject = ErrorObject.EmptyError();
            this.isBusy = false;
            this.card = this.cardList[this.index];
            this.doNextPageEnabled();
        }
    }

    public moveToNextPage(event: Event) : boolean {
       return this.router.navigateToRoute(Routes.ANALYSIS_CARDS_CAREERVALUES.route, { id : this.globalState.Id}, { trigger: true, replace: false });
    }

    public applyOptions(event: Event): any {

        this.isOptionsDisplayed = false;

        return this.server.loadCardMeaningsData(this.globalState.questionnaire, this.globalState, this)
        .then(obj => {
            this.index = 0;
            if ((this.cardList) && (this.cardList.length > 0))
            {
                this.card = this.cardList[this.index];
                this.doNextPageEnabled();
            }
            return obj;
        })
        .catch(reason => 
        {
            this.errorObject = new ErrorObject(
                reason,
                this.globalState.questionnaire,
                'Load Values Cards Meanings', 
                'There has been an error reported attempting to load the analysis of the card data. Try again?');       
            this.isBusy = false;
            return reason;
        });
    }

    public cancelOptions(event: Event) : boolean {
        this.isOptionsDisplayed = false;
        return true;
    }

    public toggleMostMeanings(event: Event) : boolean {
        this.isOptionsDisplayed = false;
        this.optionsList = this.cardList.map(CardSortItemEx.toCardSortItemEx);
        this.isOptionsDisplayed = true;
        return true;
    }

    public nextPage(): any {
        let savePoint = {
            card:  this.card,
            index: this.index
        }

        if (!this.isBusy)
        {
            this.isBusy = true;
            this.errorObject = ErrorObject.EmptyError();
        }

        this.server.saveMeaningsCard(this.globalState.questionnaire.id, savePoint.card)
            .then(response => {
                // if there was any error on the previous attempt - throw it away now.
                this.isBusy = false;
            })
            .catch(reason => 
            {
                this.errorObject = new ErrorObject(
                    reason,
                    savePoint,
                    'Save Values cards meanings', 
                    `The answers to the question about "<span class='errorHighlight'>${savePoint.card.description}</span>" have not been saved.`);       

                this.isBusy = false;
                this.index = savePoint.index;
                this.card = savePoint.card;
                this.doNextPageEnabled();
            });

        this.index++;
        if (this.index >= this.cardList.length) {
            this.isEndOfCards = true;
            this.isNextPageDisabled = true;
            this.index--;
            return;
        }

        this.card = this.cardList[this.index];
        this.doNextPageEnabled();
    }
}   
 
