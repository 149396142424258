import {bindable, autoinject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {AuthService} from 'aurelia-authentication';
import {SecurityRoles} from '../../models/SecurityRoles';

@autoinject
export class AuthorisedAnchorCustomElement {
    @bindable route:string = "";

    constructor(private router: Router, private authService: AuthService, public securityRoles: SecurityRoles) {
    }


  /**
   * route toView predictator on route.config.auth === isAuthenticated
   * @param  {RouteConfig}  routes            the routes array to convert
   * @param  {boolean}      isAuthenticated   authentication status
   * @return {boolean}      show/hide element
   */
  authorisation(theRoutes: string): boolean {
    let isAuthenticated = this.authService.authenticated;
    let routeOption = this.route.toHRef();
    let routeConfig = this.router.routes.find(itm => itm.href == this.route || itm.href == routeOption);
    if (!isAuthenticated || !this.router.isConfigured || !routeConfig)  
    {
        return false;
    }
     
    let roles = this.securityRoles.Roles;
    return SecurityRoles.RouteAuthorisationFilter(routeConfig, roles, isAuthenticated);
  }    
}
