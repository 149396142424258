import {CareerAttributeUserSelection} from './CareerAttributeUserSelection';

export class CareerAttributeItem {

    public id: number;
    public attributeId: string;
    public attributeCode: string;
    public name: string;
    public heading: string;
    public stem: string;
    public stemAnswer: string
    public stemOther: string
    public stemOtherAnswer: string
    public stemSkillsAnswers: string
    public stemInterestsAnswers: string
    public rank: number = 0;
    public userSelections: CareerAttributeUserSelection;

    public CareerAttributeItem()
    {
    }

    /**
        * This is used to sort given cards into a particular "pile" ranked from most ... down to not.
        * @return {selectType} The card pile in which the card has been assigned/sorted into by the user.
        */
    public static toCareerAttributeItem(obj: any): CareerAttributeItem {
        let item = new CareerAttributeItem();
        item.id = obj.id;
        item.attributeId = obj.attributeId;
        item.attributeCode = obj.attributeCode;
        item.name = obj.name;
        item.heading = obj.narrative.heading;
        item.stem = obj.narrative.stem;
        item.stemAnswer = obj.narrative.stemAnswer;
        item.stemOther = obj.narrative.stemOther;

        if (obj.rank)
        {
            item.rank = obj.rank;
        }
        item.userSelections = CareerAttributeUserSelection.toCareerAttributeUserSelection(obj.userSelections);
        return item;
    }
}    
