import {autoinject, computedFrom} from 'aurelia-framework';
import {Router,RouteConfig} from 'aurelia-router';
import {GlobalState} from '../models/GlobalState';
import {AuthService} from 'aurelia-authentication';
import {QuestionServer} from '../server/QuestionServer';
import {backPage, clearFullDisplay} from '../resources/navigation/NextPage'
import {ErrorObject} from '../models/ErrorObject';
import {CommonRoles} from 'models/CommonRoles';


@autoinject
@backPage()
@clearFullDisplay()
export class SpecialisedCardSortAnalysis extends CommonRoles {

    public cardSorts: any[];
    public lvl: number = 0;
    public isBusy: boolean = false;
    public errorObject: ErrorObject = null;
    public fullPrintOption: boolean = true;

    constructor(protected router: Router, protected globalState: GlobalState, private server: QuestionServer, protected authService: AuthService) {
        super(authService);
        this.fullPrintOption = this.authService.authenticated;
    }

    public activate(params: any, route: RouteConfig): any {
        if (params.id && params.id.length >= 36)
        {
            if (!this.globalState.questionnaire.id || !this.globalState.questionnaire.id != params.id)
            {
                this.globalState.questionnaire.id = params.id;
                this.globalState.questionnaire.loaded = false;
            }
        } else if (this.globalState.Id)  
        {
            // Perform navigation so that we get the assessment Id present in the URL so that a refresh in the browser will not lose the clients page.
            this.router.navigateToRoute( route.name, { 'id': this.globalState.Id }, { trigger: false, replace: true });
        }

        if (this.globalState.questionnaire.id) {
            return this.server.loadSpecialisedAnalysis(this.globalState.questionnaire.id, this.globalState )
                .then(specialisedAnalysis => {
                    this.cardSorts = specialisedAnalysis
                })
                .catch(response => {
                    this.errorObject = new ErrorObject(
                        response,
                        null,
                        "Load Questionnaire specialised analysis", 
                        "The questionnaire specialised analysis data load has failed.");       
                });
        }
    }

    @computedFrom("fullPrintOption")
    public get isFullPrint(): boolean
    {
        return this.fullPrintOption;
    }

    public set isFullPrint(value: boolean)
    {
        this.fullPrintOption = value;
    }
}   

