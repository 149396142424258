import {autoinject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {AuthService} from 'aurelia-authentication';
import {GlobalState, IGlobalState} from '../models/GlobalState';
import {QuestionServer} from '../server/QuestionServer';
import {ErrorObject} from '../models/ErrorObject';
import {backPage} from '../resources/navigation/NextPage'
import {CareerTheme, ICareerThemes} from '../models/CareerTheme';
import {CommonRoles} from 'models/CommonRoles';

@autoinject
@backPage()
export class TradesCardSortCareers extends CommonRoles implements ICareerThemes, IGlobalState  {

    public themedCareers: CareerTheme[] = [];
    protected setId: string = '11';
    public errorObject: ErrorObject = null;
    public isBusy: boolean;

    constructor(protected router: Router, public globalState: GlobalState, protected server: QuestionServer, protected authService: AuthService) {
        super(authService);
    }

    public activate(): any {
        if (this.globalState.questionnaire.id) 
        {
            return this.server.loadCareerThemeReport(this.globalState.questionnaire.id , this.setId, this.globalState, this)
                .catch(reason => 
                {
                    this.errorObject = new ErrorObject(
                        reason,
                        this.globalState.questionnaire,
                        'Load Themed Career Report', 
                        'There has been an error reported attempting to load the themed career report analysis of the card data. Try again?');       
                    this.isBusy = false;
                });
        } 
    }
}   
