import {autoinject} from 'aurelia-framework';
import {Router, RouteConfig} from 'aurelia-router';
import {AuthService} from 'aurelia-authentication';
import {GlobalState} from '../models/GlobalState';
import {QuestionServer} from '../server/QuestionServer';
import {ErrorObject} from '../models/ErrorObject';
import {CardsScaleResult} from '../models/ScaleResult';
import {nextPage} from '../resources/navigation/NextPage';
import {Routes} from '../routes';

@autoinject
@nextPage(Routes.IA_CAREER_POSSIBLE.urlFragment)
export class CareerStatement {
    
    public results: CardsScaleResult = new CardsScaleResult();
    public errorObject: ErrorObject = null;
    public isNextPageDisabled: boolean = true;
    public isBusy: boolean;
    public statement: string = '';

    constructor(protected router: Router, public globalState: GlobalState, protected server: QuestionServer, protected authService: AuthService) {
    }

    public activate(params: any, route: RouteConfig): Promise<any> {
        if (params.id && params.id.length >= 36)
        {
            if (!this.globalState.questionnaire.id || !this.globalState.questionnaire.id != params.id)
            {
                this.globalState.questionnaire.id = params.id;
                this.globalState.questionnaire.loaded = false;
            }
        } else if (this.globalState.Id)  
        {
            // Perform navigation so that we get the assessment Id present in the URL so that a refresh in the browser will not lose the clients page.
            this.router.navigateToRoute( route.name, { 'id': this.globalState.Id }, { trigger: false, replace: true });
        }
        
        if (this.globalState.questionnaire.id) 
        {
            return this.server.loadUserCardTitlesAnalysis(this.globalState.questionnaire.id, "0", this.globalState, this.results)
               .then(obj => this.statement = this.results.notes)
               .catch(reason => 
                {
                    this.errorObject = new ErrorObject(
                        reason,
                        this.globalState.questionnaire,
                        'Load User Titles Analysis', 
                        'There has been an error reported attempting to load the analysis of the card data. Try again?');       
                });
        } 
    }
}    

