import {autoinject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {SecurityRoles} from '../models/SecurityRoles';
import {GlobalState} from '../models/GlobalState';
import {CardSortCore} from '../models/CardSortCore';
import {QuestionServer} from '../server/QuestionServer';
import {backPage, clearFullDisplay} from '../resources/navigation/NextPage'

@autoinject
@backPage()
@clearFullDisplay(true)
export class ScienceCardSort extends CardSortCore {
    
    constructor(router: Router, public globalState: GlobalState, server: QuestionServer, public securityRoles: SecurityRoles) {
        super(router, globalState, server, securityRoles);
        this.setIdFragment = "9";
    }
}    


