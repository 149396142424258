import {autoinject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {AuthService} from 'aurelia-authentication';
import {SecurityRoles} from '../../models/SecurityRoles';

@autoinject
export class AuthorisationValueConverter {

    constructor(private router: Router, private authService: AuthService, private securityRoles: SecurityRoles) {
    }


  /**
   * route toView predictator on route.config.auth === isAuthenticated
   * @param  {RouteConfig}  routes            the routes array to convert
   * @param  {string}      route to check the required authentication ..   authentication status
   * @return {boolean}      show/hide element
   */
  toView(bvalue: boolean, route: string): boolean {
    let isAuthenticated = this.authService.authenticated;
    if (!isAuthenticated || !this.router.isConfigured || !this.router.routes.some(itm => itm.route == route))  
    {
        return false;
    }
     
    let roles = this.securityRoles.Roles;
    let routeConfig = this.router.routes.find(itm => itm.route == route);
    return SecurityRoles.RouteAuthorisationFilter(routeConfig, roles, isAuthenticated);
  }
}
