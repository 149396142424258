import {autoinject} from 'aurelia-framework';
import {nextPage} from '../resources/navigation/NextPage';
import {Routes} from '../routes';
import {RootAssessmentPage} from '../models/RootAssessmentPage';

@autoinject
@nextPage(Routes.IA_ARTS_CARDS.urlFragment)
export class ArtsIntro extends RootAssessmentPage {
    
}    

