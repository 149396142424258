import {autoinject} from 'aurelia-framework';
import {nextPage} from '../resources/navigation/NextPage';
import {Routes} from '../routes';
import {RootAssessmentPage} from '../models/RootAssessmentPage';

@autoinject
@nextPage(Routes.IA_Q40_QUESTIONS.urlFragment)
export class Q40Intro extends RootAssessmentPage {

}
