import {autoinject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {AuthService} from 'aurelia-authentication';
import {GlobalState} from '../models/GlobalState';
import {QuestionServer} from '../server/QuestionServer';
import {nextPage} from '../resources/navigation/NextPage'
import {CardSortIAAnalysisViewModel} from '../models/CardSortIAAnalysisViewModel';
import {Routes} from '../routes';

@autoinject
@nextPage(Routes.IA_SKILLS_TYPE.urlFragment)
export class SkillsCardsMostAnalysis extends CardSortIAAnalysisViewModel {

    constructor(protected router: Router, public globalState: GlobalState, protected server: QuestionServer, protected authService: AuthService) {
        super(router, globalState, server, authService, "2", Routes.IA_SKILLS_CARDS, null, null);
    }
}   

