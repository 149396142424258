import {autoinject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {AuthService} from 'aurelia-authentication';
import {backPage} from '../resources/navigation/NextPage'
import {GlobalState} from '../models/GlobalState';
import {QuestionServer} from '../server/QuestionServer';
import {CardSortAnalysisGroupedViewModel} from '../models/CardSortAnalysisGroupedViewModel';

@autoinject
@backPage()
export class ArtsSingleCardSortResults extends CardSortAnalysisGroupedViewModel {

    constructor(protected router: Router, public globalState: GlobalState, protected server: QuestionServer, protected authService: AuthService) {
        super(router, globalState, server, authService);
        this.setId = "10";
    }
}    
