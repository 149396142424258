
export class CareerAttributeOptions {

    public id: string = '';
    public value: string = '';
    public selected: boolean = false;

    public CareerAttributeItem()
    {
    }

    public static toCareerAttributeOptions(obj: any): CareerAttributeOptions {
        let item = new CareerAttributeOptions();
        item.id = obj.id;
        item.value = obj.value;
        item.selected = obj.selected;
        return item;
    }
}    


export class CareerAttributeOptionContainer {

    public options: CareerAttributeOptions[];
    public description: string = '';
    public criteria: string = ''
    public answer: boolean = false;

    public CareerAttributeOptionContainer()
    {
    }

    public static toCareerAttributeOptionContainer(obj: any): CareerAttributeOptionContainer {

        let container = new CareerAttributeOptionContainer();
        container.description = obj.description;
        container.criteria = obj.criteria;
        if (obj.item)
        {
            container.options = obj.item.map(CareerAttributeOptions.toCareerAttributeOptions);
        }
        else
        {
            container.options = [];
        }

        return container;
    }
}    
