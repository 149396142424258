import {autoinject} from 'aurelia-framework';
import {Router, RouteConfig} from 'aurelia-router';
import {AuthService} from 'aurelia-authentication';
import {GlobalState} from '../models/GlobalState';
import {QuestionServer} from '../server/QuestionServer';
import {ErrorObject} from '../models/ErrorObject';
import {CareerAttributeItem} from '../models/CareerAttributeItem';
import {Routes} from '../routes';
import {backPage} from '../resources/navigation/NextPage'

@autoinject
@backPage()
export class CDAPrioritisation {

    public errorObject: ErrorObject = null;
    public isNextPageDisabled: boolean = true;
    public isBusy: boolean;
    public attributes: CareerAttributeItem[] = [];

    constructor(protected router: Router, public globalState: GlobalState, protected server: QuestionServer, protected authService: AuthService) {
    }

    public activate(params: any, route: RouteConfig): Promise<any> {
        if (params.id && params.id.length >= 36)
        {
            if (!this.globalState.questionnaire.id || !this.globalState.questionnaire.id != params.id)
            {
                this.globalState.questionnaire.id = params.id;
                this.globalState.questionnaire.loaded = false;
            }
        } else if (this.globalState.Id)  
        {
            // Perform navigation so that we get the assessment Id present in the URL so that a refresh in the browser will not lose the clients page.
            this.router.navigateToRoute( route.name, { 'id': this.globalState.Id }, { trigger: false, replace: true });
        }
        
        if (this.globalState.questionnaire.id) 
        {
            return this.server.loadAttributesData(this.globalState.questionnaire, this.globalState)
                .then(attributes => {
                    this.attributes = attributes;

                    // Have the items been sorted/prioritised? If yes then resort based on the priority/rank.
                    if (this.attributes.some( itm => itm.rank && itm.rank > 0))
                    {
                        this.attributes = this.attributes.sort((a,b) => a.rank - b.rank);
                    }
                })
                .catch(reason => 
                {
                    this.errorObject = new ErrorObject(
                        reason,
                        this.globalState.questionnaire,
                        'Load Career Defining Attributes prioritisation results', 
                        'There has been an error reported attempting to load the attributes prioritisation analysis of the card data. Try again?');       
                    this.isBusy = false;
                });
        } 
    }

    public firstPiority(event: Event): boolean
    {
        // Check for the right element being clicked ...
        let item: CareerAttributeItem = <CareerAttributeItem>(<any>event.target).model;
        if (item === undefined)
        {
            item = <CareerAttributeItem>(<any>event.target).parentElement.model;
        }
        let index = this.attributes.indexOf(item);
        if (index != -1)
        {
            this.attributes.removeItem(item);
            this.attributes.unshift(item);
        }

        return true;
    }

    public increasePriority(event: Event): boolean
    {
        let item: CareerAttributeItem = <CareerAttributeItem>(<any>event.target).model;
        if (item === undefined)
        {
            item = <CareerAttributeItem>(<any>event.target).parentElement.model;
        }
        let index = this.attributes.indexOf(item);
        if (index != -1)
        {
            this.attributes.removeItem(item);
            this.attributes.splice(index -1, 0, item );
        }

        return true;
    }


    public decreasePriority(event: Event): boolean
    {
        let item: CareerAttributeItem = <CareerAttributeItem>(<any>event.target).model;
        if (item === undefined)
        {
            item = <CareerAttributeItem>(<any>event.target).parentElement.model;
        }
        let index = this.attributes.indexOf(item);
        if (index != -1)
        {
            this.attributes.removeItem(item);
            this.attributes.splice(index + 1, 0, item );
        }

        return false;
    }

    public lastPriority(event: Event): boolean
    {
        let item: CareerAttributeItem = <CareerAttributeItem>(<any>event.target).model;
        if (item === undefined)
        {
            item = <CareerAttributeItem>(<any>event.target).parentElement.model;
        }
        let index = this.attributes.indexOf(item);
        if (index != -1)
        {
            this.attributes.removeItem(item);
            this.attributes.push(item);
        }

        return false;
    }

    public nextPage(): any {
        this.isBusy = true;
        this.errorObject = ErrorObject.EmptyError();

        /// Update the checked state of all the items ... based on their presence in the selected items list.
        for (let index:number = 0; index < this.attributes.length; index++) {
            this.attributes[index].rank = index + 1;
        }

        this.server.saveAttributePriorities(this.globalState.questionnaire.id, this.attributes)
            .then(response => {
                // if there was any error on the previous attempt - throw it away now.
                this.isBusy = false;
                return this.router.navigateToRoute(Routes.IA_CDA_ANALYSIS.route, { id: this.globalState.Id }, { trigger: true, replace: false });
            })
            .catch(reason => 
            {
                this.errorObject = new ErrorObject(
                    reason,
                    this.attributes,
                    'Save Career Attribute Priorities', 
                    `The Career Defining Attributes priorities have not been saved - Try again?`);       

                this.isBusy = false;
                return;
            });

    }
}   

