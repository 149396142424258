import {computedFrom} from 'aurelia-framework';

export class RouteCnfg {

    private _href:string;
    private _idFragment: string;
    private _urlFragment: string;
    private _route: string;
    private _title: string;
    private _visited:boolean = false;
    private _isVisible: boolean = false;

    constructor (route: string, urlFragment: string, title:string = "")
    {
        this._route = route;
        this._urlFragment = urlFragment;
        this._href = '#/' + urlFragment;;
        this._idFragment = urlFragment + "/:id?";
        this._title = title;
    }

    public toString(): string
    {
        return this._urlFragment;
    }

    public get route(): string
    {
        return this._route;
    }

    public get idFragment(): string
    {
        return this._idFragment;
    }

    public get href(): string
    {
        return this._href;
    }

    public get urlFragment(): string
    {
        return this._urlFragment;
    }

    @computedFrom("_title")
    public get title(): string
    {
        return this._title;
    }

    @computedFrom("_visited")
    public get visited(): boolean
    {
        return this._visited;
    }

    public set visited(value: boolean)
    {
        this._visited = value;
    }

    @computedFrom("_visited")
    public get isVisible(): boolean
    {
        return this._isVisible;
    }

    public set isVisible(value: boolean)
    {
        this._isVisible = value;
    }

    @computedFrom('_visited', '_isVisible')
    public get breadcrumbClass(): string
    {
        if (this._isVisible)
        {
            if (this._visited)
            {
                return "visited-link";
            }
    
            return "disabled-link";
        }

        return "hide";
    }


    /**
    * Treats the string as a URL and adds each fragment with a seperator of "/" between each parameter
    *
    * @param args The additional URL fragments to be added to the URL.
    * @returns the initila string with the fragments appended.
    */
    public addFragments(...args: string[]): string 
    {
        if (args.length === 1)
        {
            return this._urlFragment + '/' + args[0];
        }

        let s = this._urlFragment;
        for (var itm of args)
        {
            s = s + '/' + itm;
        }

        return s;
    }
}

export class Routes
{
    public static readonly USER:string = 'administration/user';
    public static readonly USERS:string = 'administration/users';
    public static readonly USER_BILLING:string = 'administration/billing';
    public static readonly USER_REGISTER:string = 'administration/registerUser';
    public static readonly USER_ASSESSMENTS:string = 'administration/assessments';
    public static readonly QUESTIONNAIRE_COPY:string = 'administration/assessmentCopy';

    public static readonly CLIENT_HOME:string = "accounts/login";
    public static readonly CLIENT_LIST:string = "accounts/clients";
    public static readonly CLIENT_UPDATEDETAILS:string = 'accounts/updateDetail';
    public static readonly CLIENT_UPDATEPAGES:string = 'accounts/updateAssessment';
    public static readonly CLIENT_SENDASSESSMENT:string = 'accounts/sendAssessment';
    public static readonly CLIENT_ASSESSMENT: RouteCnfg = new RouteCnfg('CLIENT_ASSESSMENT', 'accounts/assessment');
    public static readonly USER_PROFILE:string = 'accounts/profile';
    
    public static readonly CARDS_VALUES: RouteCnfg = new RouteCnfg('CARDS_VALUES', "assessment/cards/values");
    public static readonly CARDS_MEANINGS: RouteCnfg = new RouteCnfg('CARDS_MEANINGS', 'assessment/cards/meanings');
    public static readonly CARDS_SKILLS: RouteCnfg = new RouteCnfg('CARDS_SKILLS', "assessment/cards/skills");
    public static readonly CARDS_INTERESTS: RouteCnfg = new RouteCnfg('CARDS_INTERESTS', "assessment/cards/interests");
    public static readonly CARDS_ARTS:string = "assessment/cards/arts";
    public static readonly CARDS_BUSINESS:string = "assessment/cards/business";
    public static readonly CARDS_OCCUPATIONS: RouteCnfg = new RouteCnfg('CARDS_OCCUPATIONS', "assessment/cards/occupational");
    public static readonly CARDS_ENGINEERING:string = "assessment/cards/engineering";
    public static readonly CARDS_CREATIVITY:string = "assessment/cards/creativity";
    public static readonly CARDS_HELPING:string = "assessment/cards/helping";
    public static readonly CARDS_SCIENCE:string = "assessment/cards/science";
    public static readonly CARDS_TRADES:string = "assessment/cards/trades";
    public static readonly CARDS_STATEMENT: RouteCnfg = new RouteCnfg('CARDS_STATEMENT', "assessment/cards/summary");

    public static readonly QUESTIONNAIRE:string = "assessment";
    public static readonly QUESTIONNAIRE_PAYMENT:string = "assessment/paymentresult";

    public static readonly Q40: RouteCnfg = new RouteCnfg('Q40', "assessment/q40");
    public static readonly QEND:string = "assessment/end";
    
    public static readonly ANALYSIS_RESULTS:string = 'assessment/analysis/results';
    public static readonly ANALYSIS_OUTCOMES:string = 'assessment/analysis/outcomes';
    public static readonly ANALYSIS_CARDS_COMBINED:string = 'assessment/analysis/cards/combined';
    public static readonly ANALYSIS_CARDS_GROUPED:string = 'assessment/analysis/cards/grouped';
    public static readonly ANALYSIS_CARDS_VALUES:string = 'assessment/analysis/cards/values';
    public static readonly ANALYSIS_CARDS_CAREERVALUES: RouteCnfg = new RouteCnfg('ANALYSIS_CARDS_CAREERVALUES', 'assessment/analysis/cards/careerValues');
    public static readonly ANALYSIS_CARDS_VALUESMEANINGS:string = 'assessment/analysis/cards/valuesMeanings';
    public static readonly ANALYSIS_CARDS_SKILLS:string = 'assessment/analysis/cards/skills';
    public static readonly ANALYSIS_CARDS_SKILLSII:string = 'assessment/analysis/cards/skillsII';
    public static readonly ANALYSIS_CARDS_INTERESTS:string = 'assessment/analysis/cards/interests';
    public static readonly ANALYSIS_CARDS_INTERESTSII:string = 'assessment/analysis/cards/interestsII';
    public static readonly ANALYSIS_CARDS_OCCUPATIONS:string = 'assessment/analysis/cards/occupational';
    public static readonly ANALYSIS_CARDS_SPECIALISED:string = 'assessment/analysis/cards/specialised';
    public static readonly ANALYSIS_CARDS_ARTS:string = 'assessment/analysis/cards/arts';
    public static readonly ANALYSIS_CARDS_TRADES:string = 'assessment/analysis/cards/trades';
    public static readonly ANALYSIS_CARDS_SCIENCE:string = 'assessment/analysis/cards/science';
    public static readonly ANALYSIS_CARDS_HELPING:string = 'assessment/analysis/cards/helping';
    public static readonly ANALYSIS_CARDS_ENGINEERING:string = 'assessment/analysis/cards/engineering';
    public static readonly ANALYSIS_CARDS_BUSINESS:string = 'assessment/analysis/cards/business';
    public static readonly ANALYSIS_CARDS_CREATIVITY:string = 'assessment/analysis/cards/creativity';

    public static readonly ANALYSIS_CARDS_ARTS_EX:string = 'assessment/analysis/cards/artsSingle';
    public static readonly ANALYSIS_CARDS_BUSINESS_EX:string = 'assessment/analysis/cards/businessSingle';
    public static readonly ANALYSIS_CARDS_CREATIVITY_EX:string = 'assessment/analysis/cards/creativitySingle';
    public static readonly ANALYSIS_CARDS_TRADES_EX:string = 'assessment/analysis/cards/tradesSingle';
    public static readonly ANALYSIS_CARDS_SCIENCE_EX:string = 'assessment/analysis/cards/scienceSingle';
    public static readonly ANALYSIS_CARDS_HELPING_EX:string = 'assessment/analysis/cards/helpingSingle';
    public static readonly ANALYSIS_CARDS_ENGINEERING_EX:string = 'assessment/analysis/cards/engineeringSingle';

    public static readonly CAREERS_RECOMMENDED:string = 'assessment/careers/recommendedCareers';
    public static readonly CAREERS_CREATIVITY:string = 'assessment/careers/creativity';
    public static readonly CAREERS_HELPING:string = 'assessment/careers/helping';
    public static readonly CAREERS_TRADES:string = 'assessment/careers/trades';

    public static readonly BACKGROUND_INFO:string = 'assessment/background';

    public static readonly IA_SECURITY_ROLES:string[] = ["User", "AssessmentUser"];
    public static readonly IA_MAIN_INTRO: RouteCnfg = new RouteCnfg('IA_MAIN_INTRO', 'ia/intro', 'Introduction');
    public static readonly IA_VALUES_INTRO: RouteCnfg = new RouteCnfg('IA_VALUES_INTRO', 'ia/values/intro','Values');

    public static readonly IA_VALUES_CARDS: RouteCnfg = new RouteCnfg('IA_VALUES_CARDS', 'ia/values/cards');
    public static readonly IA_VALUES_LEAST: RouteCnfg = new RouteCnfg('IA_VALUES_LEAST', 'ia/values/lessResults'); 
    public static readonly IA_VALUES_MOST: RouteCnfg = new RouteCnfg('IA_VALUES_MOST', 'ia/values/preferredResults');
    public static readonly IA_VALUES_MEANINGS_INTRO: RouteCnfg = new RouteCnfg('IA_VALUES_MEANINGS_INTRO', 'ia/values/meanings/intro','Values Meanings');
    public static readonly IA_VALUES_MEANINGS_QUESTIONS: RouteCnfg = new RouteCnfg('IA_VALUES_MEANINGS_QUESTIONS', 'ia/values/meanings/questions');
    public static readonly IA_VALUES_UNDERSTANDING: RouteCnfg = new RouteCnfg('IA_VALUES_UNDERSTANDING', 'ia/values/understandings','Values Meanings Understanding');
    public static readonly IA_VALUES_PURPOSE: RouteCnfg = new RouteCnfg('IA_VALUES_PURPOSE', 'ia/values/purpose');
    public static readonly IA_VALUES_PREFERENCES: RouteCnfg = new RouteCnfg('IA_VALUES_PREFERENCES', 'ia/values/preferences');

    public static readonly IA_SKILLS_INTRO: RouteCnfg = new RouteCnfg('IA_SKILLS_INTRO', 'ia/skills/intro','Skills');
    public static readonly IA_SKILLS_CARDS: RouteCnfg = new RouteCnfg('IA_SKILLS_CARDS', 'ia/skills/cards');
    public static readonly IA_SKILLS_MOST: RouteCnfg = new RouteCnfg('IA_SKILLS_MOST', 'ia/skills/mostResults');
    public static readonly IA_SKILLS_TYPE: RouteCnfg = new RouteCnfg('IA_SKILLS_TYPE', 'ia/skills/types');
    public static readonly IA_SKILLS_PREFERRED: RouteCnfg = new RouteCnfg('IA_SKILLS_PREFERRED', 'ia/skills/preferred');

    public static readonly IA_INTERESTS_INTRO: RouteCnfg = new RouteCnfg('IA_INTERESTS_INTRO', 'ia/interests/intro','Interests');
    public static readonly IA_INTERESTS_CARDS: RouteCnfg = new RouteCnfg('IA_INTERESTS_CARDS', 'ia/interests/cards');
    public static readonly IA_INTERESTS_MOST: RouteCnfg = new RouteCnfg('IA_INTERESTS_MOST', 'ia/interests/mostResults');
    public static readonly IA_INTERESTS_THEMES: RouteCnfg = new RouteCnfg('IA_INTERESTS_THEMES', 'ia/interests/themes');

    public static readonly IA_Q40_INTRO: RouteCnfg = new RouteCnfg('IA_Q40_INTRO', 'ia/q40/intro','Q40'); 
    public static readonly IA_Q40_QUESTIONS: RouteCnfg = new RouteCnfg('IA_Q40_QUESTIONS', 'ia/q40/questions');
    public static readonly IA_Q40_ANALYSIS: RouteCnfg = new RouteCnfg('IA_Q40_ANALYSIS', 'ia/q40/analysis','Q40 Analysis');  

    public static readonly IA_CDA_INTRO: RouteCnfg = new RouteCnfg('IA_CDA_INTRO', 'ia/cda/intro','Career Defining Attributes');
    public static readonly IA_CDA_QUESTIONS: RouteCnfg = new RouteCnfg('IA_CDA_QUESTIONS', 'ia/cda/questions');
    public static readonly IA_CDA_PRIORITY: RouteCnfg = new RouteCnfg('IA_CDA_PRIORITY', 'ia/cda/prioritisation','Career Attribute Prioritisation');
    public static readonly IA_CDA_ANALYSIS: RouteCnfg = new RouteCnfg('IA_CDA_ANALYSIS', 'ia/cda/analysis'); 
    public static readonly IA_CAREER_STATEMENT: RouteCnfg = new RouteCnfg('IA_CAREER_STATEMENT', 'ia/career/statement'); 
    public static readonly IA_CAREER_POSSIBLE: RouteCnfg = new RouteCnfg('IA_CAREER_POSSIBLE', 'ia/career/possible','Exploring Occupational Areas');  
    public static readonly IA_CAREER_OCCUPATION_CARDS: RouteCnfg = new RouteCnfg('IA_CAREER_OCCUPATION_CARDS', 'ia/career/occupation/cards');  
    public static readonly IA_CAREER_OCCUPATION_ANALYSIS: RouteCnfg = new RouteCnfg('IA_CAREER_OCCUPATION_ANALYSIS', 'ia/career/occupation/analysis');
    public static readonly IA_CAREER_OCCUPATION_PREFERRED: RouteCnfg = new RouteCnfg('IA_CAREER_OCCUPATION_PREFERRED', 'ia/career/occupation/preferred');

    public static readonly IA_SPECIALISED_INTRO: RouteCnfg = new RouteCnfg('IA_SPECIALISED_INTRO', 'ia/specialised/intro','Specialised');
    public static readonly IA_SPECIALISED_OPTIONAL: RouteCnfg = new RouteCnfg('IA_SPECIALISED_OPTIONAL', 'ia/specialised/optional');

    public static readonly IA_BUSINESS_INTRO: RouteCnfg = new RouteCnfg('IA_BUSINESS_INTRO', 'ia/business/intro','Business');
    public static readonly IA_BUSINESS_CARDS: RouteCnfg = new RouteCnfg('IA_BUSINESS_CARDS', 'ia/business/cards');
    public static readonly IA_BUSINESS_ANALYSIS: RouteCnfg = new RouteCnfg('IA_BUSINESS_ANALYSIS', 'ia/business/analysis');
    public static readonly IA_BUSINESS_MATRIX: RouteCnfg = new RouteCnfg('IA_BUSINESS_MATRIX', 'ia/business/matrix');
    public static readonly IA_ENGINEERING_INTRO: RouteCnfg = new RouteCnfg('IA_ENGINEERING_INTRO', 'ia/engineering/intro','Engineering');
    public static readonly IA_ENGINEERING_CARDS: RouteCnfg = new RouteCnfg('IA_ENGINEERING_CARDS', 'ia/engineering/cards');
    public static readonly IA_ENGINEERING_ANALYSIS: RouteCnfg = new RouteCnfg('IA_ENGINEERING_ANALYSIS', 'ia/engineering/analysis');
    public static readonly IA_ENGINEERING_MATRIX: RouteCnfg = new RouteCnfg('IA_ENGINEERING_MATRIX', 'ia/engineering/matrix');
    public static readonly IA_HELPING_INTRO: RouteCnfg = new RouteCnfg('IA_HELPING_INTRO', 'ia/helping/intro','Helping');
    public static readonly IA_HELPING_CARDS: RouteCnfg = new RouteCnfg('IA_HELPING_CARDS', 'ia/helping/cards');
    public static readonly IA_HELPING_ANALYSIS: RouteCnfg = new RouteCnfg('IA_HELPING_ANALYSIS', 'ia/helping/analysis');
    public static readonly IA_HELPING_CAREERS: RouteCnfg = new RouteCnfg('IA_HELPING_CAREERS', 'ia/helping/careers');
    public static readonly IA_HELPING_MATRIX: RouteCnfg = new RouteCnfg('IA_HELPING_MATRIX', 'ia/helping/matrix');

    public static readonly IA_SCIENCE_INTRO: RouteCnfg = new RouteCnfg('IA_SCIENCE_INTRO', 'ia/science/intro','Science');
    public static readonly IA_SCIENCE_CARDS: RouteCnfg = new RouteCnfg('IA_SCIENCE_CARDS', 'ia/science/cards');
    public static readonly IA_SCIENCE_ANALYSIS: RouteCnfg = new RouteCnfg('IA_SCIENCE_ANALYSIS', 'ia/science/analysis');
    public static readonly IA_SCIENCE_MATRIX: RouteCnfg = new RouteCnfg('IA_SCIENCE_MATRIX', 'ia/science/matrix');
    public static readonly IA_CREATIVITY_INTRO: RouteCnfg = new RouteCnfg('IA_CREATIVITY_INTRO', 'ia/creativity/intro','Creativity');
    public static readonly IA_CREATIVITY_CARDS: RouteCnfg = new RouteCnfg('IA_CREATIVITY_CARDS', 'ia/creativity/cards');
    public static readonly IA_CREATIVITY_ANALYSIS: RouteCnfg = new RouteCnfg('IA_CREATIVITY_ANALYSIS', 'ia/creativity/analysis');
    public static readonly IA_CREATIVITY_MATRIX: RouteCnfg = new RouteCnfg('IA_CREATIVITY_MATRIX', 'ia/creativity/matrix');
    public static readonly IA_TRADES_INTRO: RouteCnfg = new RouteCnfg('IA_TRADES_INTRO', 'ia/tradespredegree/intro','Trades');
    public static readonly IA_TRADES_CARDS: RouteCnfg = new RouteCnfg('IA_TRADES_CARDS', 'ia/tradespredegree/cards');
    public static readonly IA_TRADES_ANALYSIS: RouteCnfg = new RouteCnfg('IA_TRADES_ANALYSIS', 'ia/tradespredegree/analysis');
    public static readonly IA_TRADES_CAREERS: RouteCnfg = new RouteCnfg('IA_TRADES_CAREERS', 'ia/tradespredegree/careers');
    public static readonly IA_TRADES_MATRIX: RouteCnfg = new RouteCnfg('IA_TRADES_MATRIX', 'ia/tradespredegree/matrix');
    public static readonly IA_ARTS_INTRO: RouteCnfg = new RouteCnfg('IA_ARTS_INTRO', 'ia/artssocscience/intro','Arts');
    public static readonly IA_ARTS_CARDS: RouteCnfg = new RouteCnfg('IA_ARTS_CARDS', 'ia/artssocscience/cards');
    public static readonly IA_ARTS_ANALYSIS: RouteCnfg = new RouteCnfg('IA_ARTS_ANALYSIS', 'ia/artssocscience/analysis');
    public static readonly IA_ARTS_MATRIX: RouteCnfg = new RouteCnfg('IA_ARTS_MATRIX', 'ia/artssocscience/matrix');

    public static readonly IA_END: RouteCnfg = new RouteCnfg('IA_END', 'ia/end');
    public static readonly IA_END_STAGE1: RouteCnfg = new RouteCnfg('IA_END_STAGE1', 'ia/end/stage1');

}

/*
 This only exists so that views can access the values of the variables.
*/

export class RoutePaths
{
    public USER_REGISTER:string = Routes.USER_REGISTER;
    public USER_PROFILE:string = Routes.USER_PROFILE;
    public USER:string = Routes.USER;
    public USERS:string = Routes.USERS;
    public USER_BILLING:string = Routes.USER_BILLING;
    public USER_ASSESSMENTS:string = Routes.USER_ASSESSMENTS;

    public QUESTIONNAIRE_COPY: string = Routes.QUESTIONNAIRE_COPY;

    public CLIENT_UPDATEDETAILS:string = Routes.CLIENT_UPDATEDETAILS;
    public CLIENT_UPDATEPAGES:string = Routes.CLIENT_UPDATEPAGES;
    public CLIENT_HOME:string = Routes.CLIENT_HOME;
    public CLIENT_LIST:string = Routes.CLIENT_LIST;
    public CLIENT_SENDASSESSMENT:string = Routes.CLIENT_SENDASSESSMENT;

    public CARDS_VALUES: RouteCnfg = Routes.CARDS_VALUES;

    public QUESTIONNAIRE:string = Routes.QUESTIONNAIRE;
    public Q40:RouteCnfg = Routes.Q40;
    public QEND:string = Routes.QEND;
    
    public CARDS_MEANINGS:RouteCnfg = Routes.CARDS_MEANINGS;
    
    public ANALYSIS_RESULTS:string = Routes.ANALYSIS_RESULTS;
    public ANALYSIS_CARDS_COMBINED:string = Routes.ANALYSIS_CARDS_COMBINED;
    public ANALYSIS_CARDS_GROUPED:string = Routes.ANALYSIS_CARDS_GROUPED;
    public ANALYSIS_CARDS_VALUES:string = Routes.ANALYSIS_CARDS_VALUES;
    public ANALYSIS_CARDS_CAREERVALUES: RouteCnfg = Routes.ANALYSIS_CARDS_CAREERVALUES;
    public ANALYSIS_CARDS_VALUESMEANINGS:string = Routes.ANALYSIS_CARDS_VALUESMEANINGS;
    public ANALYSIS_CARDS_SKILLS:string = Routes.ANALYSIS_CARDS_SKILLS;
    public ANALYSIS_CARDS_SKILLSII:string = Routes.ANALYSIS_CARDS_SKILLSII;
    public ANALYSIS_CARDS_INTERESTS:string = Routes.ANALYSIS_CARDS_INTERESTS;
    public ANALYSIS_CARDS_INTERESTSII:string = Routes.ANALYSIS_CARDS_INTERESTSII;
    public ANALYSIS_CARDS_OCCUPATIONS:string = Routes.ANALYSIS_CARDS_OCCUPATIONS;
    public ANALYSIS_CARDS_SPECIALISED:string = Routes.ANALYSIS_CARDS_SPECIALISED;
    public ANALYSIS_CARDS_TRADES:string = Routes.ANALYSIS_CARDS_TRADES;
    public ANALYSIS_CARDS_ARTS:string = Routes.ANALYSIS_CARDS_ARTS;
    public ANALYSIS_CARDS_SCIENCE:string = Routes.ANALYSIS_CARDS_SCIENCE;
    public ANALYSIS_CARDS_HELPING:string = Routes.ANALYSIS_CARDS_HELPING;
    public ANALYSIS_CARDS_ENGINEERING:string = Routes.ANALYSIS_CARDS_ENGINEERING;
    public ANALYSIS_CARDS_BUSINESS:string = Routes.ANALYSIS_CARDS_BUSINESS;
    public ANALYSIS_CARDS_CREATIVITY:string = Routes.ANALYSIS_CARDS_CREATIVITY;

    public ANALYSIS_CARDS_ARTS_EX:string = Routes.ANALYSIS_CARDS_ARTS_EX;
    public ANALYSIS_CARDS_BUSINESS_EX:string = Routes.ANALYSIS_CARDS_BUSINESS_EX;
    public ANALYSIS_CARDS_CREATIVITY_EX:string = Routes.ANALYSIS_CARDS_CREATIVITY_EX;
    public ANALYSIS_CARDS_TRADES_EX:string = Routes.ANALYSIS_CARDS_TRADES_EX;
    public ANALYSIS_CARDS_SCIENCE_EX:string = Routes.ANALYSIS_CARDS_SCIENCE_EX;
    public ANALYSIS_CARDS_HELPING_EX:string = Routes.ANALYSIS_CARDS_HELPING_EX;
    public ANALYSIS_CARDS_ENGINEERING_EX:string = Routes.ANALYSIS_CARDS_ENGINEERING_EX;

    public CAREERS_CREATIVITY: string = Routes.CAREERS_CREATIVITY;
    public CAREERS_HELPING: string = Routes.CAREERS_HELPING;
    public CAREERS_TRADES: string = Routes.CAREERS_TRADES;

    public IA_MAIN_INTRO: RouteCnfg = Routes.IA_MAIN_INTRO;
    public IA_VALUES_INTRO: RouteCnfg = Routes.IA_VALUES_INTRO;
    public IA_VALUES_CARDS: RouteCnfg = Routes.IA_VALUES_CARDS;
    public IA_VALUES_LEAST: RouteCnfg = Routes.IA_VALUES_LEAST;
    public IA_VALUES_MOST: RouteCnfg = Routes.IA_VALUES_MOST;
    public IA_VALUES_MEANINGS_INTRO: RouteCnfg = Routes.IA_VALUES_MEANINGS_INTRO;
    public IA_VALUES_MEANINGS_QUESTIONS: RouteCnfg = Routes.IA_VALUES_MEANINGS_QUESTIONS;
    public IA_VALUES_UNDERSTANDING: RouteCnfg = Routes.IA_VALUES_UNDERSTANDING;
    public IA_VALUES_PURPOSE: RouteCnfg = Routes.IA_VALUES_PURPOSE;
    public IA_VALUES_PREFERENCES: RouteCnfg = Routes.IA_VALUES_PREFERENCES;

    public IA_SKILLS_INTRO: RouteCnfg = Routes.IA_SKILLS_INTRO;
    public IA_SKILLS_CARDS: RouteCnfg = Routes.IA_SKILLS_CARDS;
    public IA_SKILLS_MOST: RouteCnfg = Routes.IA_SKILLS_MOST;
    public IA_SKILLS_TYPE: RouteCnfg = Routes.IA_SKILLS_TYPE;
    public IA_SKILLS_PREFERRED: RouteCnfg = Routes.IA_SKILLS_PREFERRED;

    public IA_INTERESTS_INTRO: RouteCnfg = Routes.IA_INTERESTS_INTRO;
    public IA_INTERESTS_CARDS: RouteCnfg = Routes.IA_INTERESTS_CARDS;
    public IA_INTERESTS_MOST: RouteCnfg = Routes.IA_INTERESTS_MOST;
    public IA_INTERESTS_THEMES: RouteCnfg = Routes.IA_INTERESTS_THEMES;

    public IA_Q40_INTRO: RouteCnfg = Routes.IA_Q40_INTRO;
    public IA_Q40_QUESTIONS: RouteCnfg = Routes.IA_Q40_QUESTIONS;
    public IA_Q40_ANALYSIS: RouteCnfg = Routes.IA_Q40_ANALYSIS;

    public IA_CDA_INTRO: RouteCnfg = Routes.IA_CDA_INTRO;
    public IA_CDA_QUESTIONS: RouteCnfg = Routes.IA_CDA_QUESTIONS;
    public IA_CDA_ANALYSIS: RouteCnfg = Routes.IA_CDA_ANALYSIS;
    public IA_CDA_PRIORITY: RouteCnfg = Routes.IA_CDA_PRIORITY;

    public IA_CAREER_STATEMENT: RouteCnfg = Routes.IA_CAREER_STATEMENT;
    public IA_CAREER_POSSIBLE: RouteCnfg = Routes.IA_CAREER_POSSIBLE;
    public IA_CAREER_OCCUPATION_CARDS: RouteCnfg = Routes.IA_CAREER_OCCUPATION_CARDS;
    public IA_CAREER_OCCUPATION_PREFERRED: RouteCnfg = Routes.IA_CAREER_OCCUPATION_PREFERRED;
    public IA_CAREER_OCCUPATION_ANALYSIS: RouteCnfg = Routes.IA_CAREER_OCCUPATION_ANALYSIS;

    public IA_BUSINESS_INTRO: RouteCnfg = Routes.IA_BUSINESS_INTRO;
    public IA_ENGINEERING_INTRO: RouteCnfg = Routes.IA_ENGINEERING_INTRO;
    public IA_HELPING_INTRO: RouteCnfg = Routes.IA_HELPING_INTRO;

    public IA_SCIENCE_INTRO: RouteCnfg = Routes.IA_SCIENCE_INTRO;
    public IA_CREATIVITY_INTRO: RouteCnfg = Routes.IA_CREATIVITY_INTRO;
    public IA_TRADES_INTRO: RouteCnfg = Routes.IA_TRADES_INTRO;
    public IA_ARTS_INTRO: RouteCnfg = Routes.IA_ARTS_INTRO;
 
    public IA_END: RouteCnfg = Routes.IA_END;
    public IA_END_STAGE1: RouteCnfg = Routes.IA_END_STAGE1;

    public static readonly IA_BREADCRUMB_ROUTES: RouteCnfg[] = [    Routes.IA_MAIN_INTRO,
                                                                    Routes.IA_VALUES_INTRO,
                                                                    Routes.IA_VALUES_MEANINGS_INTRO,
                                                                    Routes.IA_VALUES_UNDERSTANDING,
                                                                    Routes.IA_SKILLS_INTRO,
                                                                    Routes.IA_INTERESTS_INTRO,
                                                                    Routes.IA_Q40_INTRO,
                                                                    Routes.IA_Q40_ANALYSIS,
                                                                    Routes.IA_CDA_INTRO,
                                                                    Routes.IA_CDA_PRIORITY,
                                                                    Routes.IA_CAREER_POSSIBLE,

                                                                    Routes.IA_SPECIALISED_INTRO,
                                                                    Routes.IA_HELPING_INTRO,
                                                                    Routes.IA_BUSINESS_INTRO,
                                                                    Routes.IA_ENGINEERING_INTRO,
                                                                    Routes.IA_SCIENCE_INTRO,
                                                                    Routes.IA_CREATIVITY_INTRO,
                                                                    Routes.IA_TRADES_INTRO,
                                                                    Routes.IA_ARTS_INTRO
                                                                ];
}

