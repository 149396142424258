import {BindingEngine, autoinject, Disposable} from 'aurelia-framework';
import {AuthService} from 'aurelia-authentication';
import {Router, RouteConfig} from 'aurelia-router';
import {GlobalState} from '../models/GlobalState';
import {ErrorObject} from '../models/ErrorObject';
import {QuestionServer} from '../server/QuestionServer';
import {Routes} from '../routes';
import {CareerAttributeItem} from '../models/CareerAttributeItem';
import {CareerAttributeOptions} from '../models/CareerAttributeOptions';

@autoinject
export class CDAQuestions {

    private index: number;
    public isNextPageDisabled: boolean = true;
    public nextPageBtn: HTMLButtonElement;
    public errorObject: ErrorObject = null;
    public isBusy: boolean;
    public isEndOfCards: boolean = false;
    public isOptionsDisplayed: boolean = false;
    public attributes: CareerAttributeItem[];
    public attribute: CareerAttributeItem;
    public attributeOptions: CareerAttributeOptions[];
    public selectedItems: CareerAttributeOptions[];
    private subscription: Disposable;

    constructor(protected router: Router, public globalState: GlobalState,  protected server: QuestionServer, protected authService: AuthService, protected bindingEngine: BindingEngine) {
        this.selectedItems = [];
        this.subscription = this.bindingEngine.collectionObserver(this.selectedItems).subscribe((slices) => 
        {
            this.selectedItemsChanged(slices);
        });

    }

    public activate(params: any, route: RouteConfig): any {
        if (params.id && params.id.length >= 36)
        {
            if (!this.globalState.questionnaire.id || !this.globalState.questionnaire.id != params.id)
            {
                this.globalState.questionnaire.id = params.id;
                this.globalState.questionnaire.loaded = false;
            }
        } else if (this.globalState.Id)  
        {
            // Perform navigation so that we get the assessment Id present in the URL so that a refresh in the browser will not lose the clients page.
            this.router.navigateToRoute( route.name, { 'id': this.globalState.Id }, { trigger: false, replace: true });
        }

        this.index = 0;
        if (this.router.isExplicitNavigationBack)
        {
            this.index = 1;
        }

        if (this.globalState.questionnaire.id) 
        {
            return this.server.loadAttributesData(this.globalState.questionnaire, this.globalState)
                .then(obj => {
                    this.selectedItems.length = 0;
                    this.attributes = obj;
                    this.attribute = null;
                    if ((this.attributes) && (this.attributes.length > 0))
                    {
                        if (this.index == 1)
                        {
                            this.index = this.attributes.length - 1;
                        }
                        this.attribute = this.attributes[this.index];
                        this.doNextPageEnabled();
                    }
                    this.switchPage();

                    return obj;
                })
                .catch(reason => 
                {
                    this.errorObject = new ErrorObject(
                        reason,
                        this.globalState.questionnaire,
                        'Load Career Defining Attributes', 
                        'There has been an error reported attempting to load the assessment career attributes. Try again?');       
                    this.isBusy = false;
                    return reason;
                });
        } 
    }

    public attached(): any {
        this.globalState.clearNowVersion();
    }

    public deactivate(): any 
    {
        this.subscription?.dispose();
        this.subscription = null;
    }

    public selectedItemsChanged(slices: any): void
    {
        this.doNextPageEnabled();
    }

    private doNextPageEnabled(): boolean
    {
        let priorBtnState = this.isNextPageDisabled;
        this.isNextPageDisabled = !(this.selectedItems) || (this.selectedItems.length == 0);
        // Give the next button focus on state change to enabled so that enter can be used to go the next question.
        if (priorBtnState != this.isNextPageDisabled && !this.isNextPageDisabled && (this.nextPageBtn))
        {
            this.nextPageBtn.disabled = false;
            this.nextPageBtn.focus();
        }


        // let the event keep on bubbling ...
        return true;
    }

    private switchPage()
    {
        this.selectedItems.length = 0;
        if (this.attribute)
        {
            this.attribute.userSelections.options.forEach((itm) => 
            { 
                if (itm.selected) {
                    this.selectedItems.push(itm);
                } 
            });

            this.attributeOptions = this.attribute.userSelections.options;
        }
    }

    public backPage() {
        if (--this.index < 0) {
            this.index = 0;
            this.switchPage();
            this.router.navigateBack();
        }
        else {
            this.errorObject = ErrorObject.EmptyError();
            this.isBusy = false;
            this.attribute = this.attributes[this.index];
            this.switchPage();
            this.doNextPageEnabled();
        }
    }

    public moveToNextPage(event: Event) : boolean {
        return this.router.navigateToRoute(Routes.IA_CDA_PRIORITY.route, { id: this.globalState.Id }, { trigger: true, replace: false });
    }

    public nextPage(): any {
        let savePoint = {
            attribute: this.attribute,
            index: this.index
        }

        if (!this.isBusy)
        {
            this.isBusy = true;
            this.errorObject = ErrorObject.EmptyError();
        }

        /// Update the checked state of all the items ... based on their presence in the selected items list.
        this.attribute.userSelections.options.forEach((itm) => 
        { 
            itm.selected = this.selectedItems.some( selected => itm === selected );
        });


        this.server.saveAttribute(this.globalState.questionnaire.id, this.attribute)
            .then(response => {
                // if there was any error on the previous attempt - throw it away now.
                this.isBusy = false;
            })
            .catch(reason => 
            {
                this.errorObject = new ErrorObject(
                    reason,
                    savePoint,
                    'Save Career Attribute values', 
                    `The answers to the question about "<span class='errorHighlight'>${savePoint.attribute.name}</span>" have not been saved.`);       

                this.isBusy = false;
                this.index = savePoint.index;
                this.attribute = savePoint.attribute;
                this.doNextPageEnabled();
                return;
            });

        this.index++;
        if (this.index >= this.attributes.length) {
            this.isEndOfCards = true;
            this.isNextPageDisabled = true;
            this.index--;
            this.switchPage();
            return;
        }

        this.isEndOfCards = false;
        this.attribute = this.attributes[this.index];
        this.switchPage();
        this.doNextPageEnabled();
    }
}   
 

