import {autoinject} from 'aurelia-framework';
import {Router, RouteConfig} from 'aurelia-router';
import {GlobalState} from './GlobalState';
import {clearFullDisplay} from '../resources/navigation/NextPage';
import {ErrorObject} from '../models/ErrorObject';
import {QuestionServer} from '../server/QuestionServer';

@autoinject
@clearFullDisplay(true)
export class RootAssessmentPage {

    public errorObject: ErrorObject = null;
    public isBusy: boolean;
    

    constructor(protected router: Router, public globalState: GlobalState, private server: QuestionServer) {
    }

    public activate(params: any, route: RouteConfig): any {
        if (params.id && params.id.length >= 36)
        {
            if (!this.globalState.questionnaire.id || !this.globalState.questionnaire.id != params.id)
            {
                this.globalState.questionnaire.id = params.id;
                this.globalState.questionnaire.loaded = false;
            }
        } else if (this.globalState.Id)  
        {
            // Perform navigation so that we get the assessment Id present in the URL so that a refresh in the browser will not lose the clients page.
            // Replace - replace the current browser history item with this one - trigger - don't navigate/switch pages.
            this.router.navigateToRoute( route.name, { 'id': this.globalState.Id }, { trigger: false, replace: true });
        }

        if (!this.globalState.questionnaire.loaded)
        {
            return this.server.loadQuestionnaire(this.globalState.questionnaire.id, this.globalState);
        }
    }
}    

