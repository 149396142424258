import {autoinject} from 'aurelia-framework';
import {AuthService} from 'aurelia-authentication';
import {Router} from 'aurelia-router';
import {GlobalState} from '../models/GlobalState';
import {ErrorObject} from '../models/ErrorObject';
import {PaymentsServer} from '../server/PaymentsServer';
import {TokenServer} from '../server/TokenServer';
import {clearFullDisplay} from '../resources/navigation/NextPage';
import {Routes} from '../routes';

// This is the landing page on return from the stripe payment portal. It just forwards the received parameters through to the server to deal with.

@autoinject
@clearFullDisplay(true)
export class AccountPayment {

    public errorObject: ErrorObject = null;
    private paymentReferenceId: string = '';
    private resultToken: string;
    private sessionToken: string;
    private emailAddress: string = '';
    private questionnaireToken: string = '';
    private version: string = '';
    private hash: string = '';

    constructor(protected router: Router, protected globalState: GlobalState, protected authService: AuthService, private tokenServer: TokenServer, private server: PaymentsServer) 
    {
    }

    public activate(params: any): Promise<any | boolean>
    {
        if (params.id && params.id.length === 36 && (params.resultToken) && (params.sessionToken))
        {
            this.paymentReferenceId = params.id;
            this.resultToken = params.resultToken;
            this.sessionToken = params.sessionToken;
            this.emailAddress = params.email ? decodeURIComponent(params.email) : null;
            this.questionnaireToken = params.token ? params.token : null;
            this.version = params.v ? params.v : null;
            this.hash = params.hash ? decodeURIComponent(params.hash) : null;
    
            return this.tokenServer.questionnaireRegister(this.emailAddress, this.questionnaireToken, this.version, this.hash)
                .then(response => {
                    this.authService.setResponseObject(response);
                    return this.server.paymentConfirmation(this.paymentReferenceId, this.resultToken, this.sessionToken)
                })
                .then(payObj =>
                {
                    if (payObj.isPaid)
                    {
                        // Payment went through ... send them off to the first questionnaire page.
                        if (payObj.isIndependentAssessment)
                        {
                            this.router.navigateToRoute(Routes.IA_MAIN_INTRO.route, { id: payObj.questionnaireId }, { trigger: true, replace: false });                 

                        }
                        else
                        {
                            this.router.navigateToRoute(Routes.CARDS_VALUES.route, { id: payObj.questionnaireId }, { trigger: true, replace: false });                 
                        }
                    }
                    else
                    {   
                        // Failure ... we don't know why ... go back to the start page again ...
                        this.router.navigateToRoute(Routes.CLIENT_ASSESSMENT.route, { token: payObj.questionnaireId, email: payObj.emailAddress, v: payObj.version, hash: payObj.hash }, { trigger: true, replace: false });                 
                    }
                }
            )
            .catch(response => 
                {
                    let error = new ErrorObject(
                        response,
                        null,
                        'Confirm Card Payment', 
                        'There has been an unexpected error reported. Wait 2 minutes and then try again. If this continues, please contact Career Sense.');       
                    if (error.status == 401)
                    {
                        // Should not be getting access denied messages here !! But the browsers do sometimes manage to stuff things up - it has been observed!
                        return this.server.paymentFailure(this.paymentReferenceId).then(failObj =>
                            {
                                if (failObj.isPaid)
                                {
                                    // Payment went through ... send them off to the first questionnaire page.
                                    if (failObj.isIndependentAssessment)
                                    {
                                        this.router.navigateToRoute(Routes.IA_MAIN_INTRO.route, { id: failObj.questionnaireId }, { trigger: true, replace: false });                 
            
                                    }
                                    else
                                    {
                                        this.router.navigateToRoute(Routes.CARDS_VALUES.route, { id: failObj.questionnaireId }, { trigger: true, replace: false });                 
                                    }
                                }
                                else
                                {   
                                    // Failure ... we don't know why ... go back to the start page again ...
                                    this.router.navigateToRoute(Routes.CLIENT_ASSESSMENT.route, { token: failObj.questionnaireId, email: failObj.emailAddress, v: failObj.version, hash: failObj.hash }, { trigger: true, replace: false });                 
                                }
                            }
                        ).catch( failResponse =>
                            {
                                this.errorObject = new ErrorObject(
                                failResponse,
                                null,
                                'Card Payment Failure', 
                                'Unable to deal with payment confirmation failure. Wait 2 minutes and then try again. If this continues, please contact Career Sense.');       
                            }
                        )
                    }
                    else
                    {
                        this.errorObject = error;
                    }
                });
}
        else 
        {
            this.errorObject = new ErrorObject(
                'The value does not represent the expected payment.',
                this.globalState.questionnaire,
                'Assessment Payments', 
                'Please contact Career Sense. This is not an expected payment scenario. Return to the start page and try again.');       
        }
    }
}
