
Array.prototype.remove = function remove<T>(this: T[], from:number, to:number): T[] {
    this.splice(from, to - from + 1);
    return this;
}

Array.prototype.removeItem = function removeItem<T>(this: T[], item: T): T[] {
    let index = this.indexOf(item);
    if (index >= 0) {
        this.splice(index, 1);
        return this;
    }

    return this;
}

String.prototype.toTitlecase = function (this: string): string {
    return this.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
    //return this.replace(/(\-[a-z])/g, function ($1) { return $1.toUpperCase().replace('-', ''); });
}

/**
* Treats the string as a URL and adds each fragment with a seperator of "/" between each parameter
*
* @param args The additional URL fragments to be added to the URL.
* @returns the initila string with the fragments appended.
*/
String.prototype.addFragment = function (this: string, ...args: string[]): string {
    let s = this;
    if (args.length === 1)
    {
        return s + '/' + args[0];
    }

    for (var itm of args)
    {
        s = s + '/' + itm;
    }

    return s;
}

  /**
    * Treats the string as a URL and adds a single optional path/fragment element of "/:id?"
    *
    * @returns the initial string with the fragments appended.
    */
String.prototype.addIdFragment = function (this: string): string {
    return this + "/:id?";
}

  /**
    * Treats the string as a URL and adds a # at the front to convert the URL into a home relative URL.
    *
    * @returns the initial string with the home reference pre-pended.
    */
String.prototype.toHRef = function (this: string): string {
    return '#/' + this;
}

let monthNamesArray: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
let dayNamesArray: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun', '...', '...'];

Date.prototype.toNZString = function(this: Date): string {
    if (!this) 
    {   
        return "";
    }

    let dayName = this.getDay();
    let day = this.getDate();
    let month = monthNamesArray[this.getMonth()];
    let year = this.getFullYear();

    return dayNamesArray[dayName] + " " + day + "-" + month + "-" + year;
}
