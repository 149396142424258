
export class BillingCycle {
    /// <summary>
    /// The billing cycle unique identified
    /// </summary>
    public billingCycleId: number;

    /// <summary>
    /// The item description - 
    /// </summary>
    public description: string;

    /// <summary>
    /// Indicates if the billing cycle is now closed/billed.
    /// </summary>
    public isFinalised: boolean;

    public fromDate: Date;
    public toDate: Date;
    public auditCreatedAt: Date;

    public static toBillingCycle(obj: any): BillingCycle {
        let item = new BillingCycle();

        item.billingCycleId = obj.billingCyclePK;
        item.description = obj.description;
        item.isFinalised = obj.isFinalised;
        item.fromDate = new Date(obj.fromDate);
        item.toDate = new Date(obj.toDate);
        item.auditCreatedAt = new Date(obj.auditCreatedAt);
    
        return item;
    }
}

