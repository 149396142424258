import {autoinject, computedFrom} from 'aurelia-framework';
import {AuthService} from 'aurelia-authentication';
import {RiasecQuestionnaire} from '../models/RiasecQuestionnaire';
import {Router, PipelineResult} from 'aurelia-router';
import {ErrorObject} from '../models/ErrorObject';
import {TokenServer} from '../server/TokenServer';
import {QuestionServer} from '../server/QuestionServer';
import {AccountsServer} from '../server/AccountsServer';
import {GlobalState} from '../models/GlobalState';
import {PaymentsServer} from '../server/PaymentsServer';
import {Routes} from '../routes';
import environment from 'environment';

declare var Stripe: any;

@autoinject
export class AccountsQuestionniareRegister {

    public firstName: string = '';
    public lastName: string = '';
    public termsAndConditions: boolean = false;
    public isTermsDisplayed: boolean = false;
    public emailAddress: string = '';
    public questionnaireToken: string = '';
    public version: string = '';
    public hash: string = '';
    public isBusy: boolean = true;
    public errorObject: ErrorObject = null;
    public isBadRequest: boolean = false;
    public isPaymentRequired: boolean = false;
    public isRedirect: boolean = false;
    public isIndependentAssessment: boolean = false;
    private static stripe: any = null;
    private sessionToken: string = '';
    private isPaid: boolean = false;
    private paymentReferenceId: string = '';
    private scriptElement: HTMLScriptElement;


    constructor(private router: Router, private globalState: GlobalState, protected authService: AuthService, private server: TokenServer, private accountsServer: AccountsServer, private paymentServer: PaymentsServer, private questionServer: QuestionServer) {
    }

    public activate(params: any): void {
        // Clear any login security, in essence similar to a logout without the redirect side effects.
        // which will stop user from seeing any error messages we display.
        this.authService.setResponseObject(null);
        this.emailAddress = params.email ? decodeURIComponent(params.email) : null;
        this.questionnaireToken = params.token ? params.token : null;
        this.version = params.v ? params.v : null;
        this.hash = params.hash ? decodeURIComponent(params.hash) : null;
    }

    public attached(): Promise<RiasecQuestionnaire | PipelineResult | any | boolean> | void
    {
        // Remove any current tokens so that we get some updated ones ... this is actually a logout in essence.
        this.authService.setResponseObject(null);
        
        // if we have details from the query string, attempt a verify & redirect
        if (this.emailAddress && this.questionnaireToken && this.questionnaireToken.length == 36)
        {
            // Get updated tokens and verify that the assessment data is valid before going any further.
            return this.server.questionnaireRegister(this.emailAddress, this.questionnaireToken, this.version, this.hash)
                .then(response => {
                    this.authService.setResponseObject(response);
                    return this.accountsServer.loadAssessmentHeader(this.questionnaireToken);
                })
                .then(header => {
                    this.globalState.assignNames(header);
                    this.globalState.questionnaire = header;
                    this.isBadRequest = false;

                    this.firstName = header.firstname;
                    this.lastName = header.surname;
                    this.termsAndConditions = header.isTermsAccepted;
                    this.isPaymentRequired = header.isPaymentRequired;
                    this.isIndependentAssessment = header.isIndependentAssessment;
                    this.paymentReferenceId = header.paymentReferenceId;

                    if (header.isTermsAccepted && (header.isPaid || !header.isPaymentRequired))
                    {
                        // Automagically redirect to the right page from where they left off.
                        return this.navigateToStartingPage(header);
                    }

                    this.isBusy = false;
                })
                .catch(reason => 
                {
                    this.isBusy = false;
                    this.authService.setResponseObject(null);                    
                    throw new ErrorObject(
                        reason,
                        this,
                        'Register and load career assessment', 
                        'There has been an error reported attempting to register and load the questionnaire. Try again?',
                        (sender, info) => {
                            if (sender.code == "400::QuestionnaireIsComplete")
                            {
                                // throw away the default display of the error, we have a special display instead.
                                sender.errorObj = null;
                                this.isBadRequest = true;
                            }
                        });    
                });
        }
        else
        { 
            this.isBusy = false;
            this.isBadRequest = true;
        }
    }

    public navigateToStartingPage(header: RiasecQuestionnaire): boolean
    {
        if (header.isIndependentAssessment)
        {
            if ((header.appointment) && (header.appointment.valueOf() <= Date.now()))
                return this.router.navigateToRoute( Routes.IA_MAIN_INTRO.route, { id: this.questionnaireToken }, { trigger: true, replace: true });                 
            else if (header.isValuesCompleted)
            {
                if (header.isSkillsCompleted)
                {
                    if (header.isInterestsCompleted)
                    {
                        if (header.isQ40Completed)
                        {
                            return this.router.navigateToRoute( Routes.IA_CDA_INTRO.route, { id: this.questionnaireToken }, { trigger: true, replace: true });                 
                        }
                        else
                        {
                            return this.router.navigateToRoute( Routes.IA_Q40_INTRO.route, { id: this.questionnaireToken }, { trigger: true, replace: true });                 
                        }
                    }
                    else
                    {
                        return this.router.navigateToRoute( Routes.IA_INTERESTS_INTRO.route, { id: this.questionnaireToken }, { trigger: true, replace: true });                 
                    }
                }
                else
                {
                    return this.router.navigateToRoute( Routes.IA_SKILLS_INTRO.route, { id: this.questionnaireToken }, { trigger: true, replace: true });                 
                }
            } 
    
            return this.router.navigateToRoute( Routes.IA_MAIN_INTRO.route, { id: this.questionnaireToken }, { trigger: true, replace: true });                 
        }
        else if (header.appointment.valueOf() <= Date.now() || header.isValuesCompleted && header.isSkillsCompleted && header.isInterestsCompleted && header.isQ40Completed)
        {
            return this.router.navigateToRoute( Routes.CARDS_VALUES.route, { id: this.questionnaireToken }, { trigger: true, replace: true });                 
        }
        else if (header.isValuesCompleted) 
        {
            if (header.isSkillsCompleted)
            {
                if (header.isInterestsCompleted)
                {
                    if (header.isQ40Completed)
                    {
                        return this.router.navigateToRoute( Routes.CARDS_STATEMENT.route, { id: this.questionnaireToken }, { trigger: true, replace: true });                 
                    }
                    else
                    {
                        return this.router.navigateToRoute( Routes.Q40.route, { id: this.questionnaireToken }, { trigger: true, replace: true });                 
                    }
                }
                else
                {
                    return this.router.navigateToRoute( Routes.CARDS_INTERESTS.route, { id: this.questionnaireToken }, { trigger: true, replace: true });                 
                }
            }
            else
            {
                return this.router.navigateToRoute( Routes.CARDS_SKILLS.route, { id: this.questionnaireToken }, { trigger: true, replace: true });                 
            }
        } 

        return this.router.navigateToRoute( Routes.CARDS_VALUES.route, { id: this.questionnaireToken }, { trigger: true, replace: true });                 
    }


    public displayTerms(): void
    {
        this.isTermsDisplayed = true;
    }

    public continueToAssessment(event: Event): Promise<any> {
        event.preventDefault();

        if (!this.isBusy) {
            this.isBusy = true;
            this.errorObject = ErrorObject.EmptyError();
            return this.questionServer.initialiseQuestionnaire({ firstname: this.firstName, surname: this.lastName, isTermsAccepted: this.termsAndConditions, isIndependentAssessment: this.isIndependentAssessment })
                .then(questionnaire => 
                {
                    this.globalState.questionnaire = questionnaire;
                    return this.navigateToStartingPage(questionnaire);
                })
                .catch(response =>
                {
                    this.errorObject = new ErrorObject(
                        response,
                        { firstname: this.firstName, surname: this.lastName },
                        "Start Assessment", 
                        "Could not get a new assessment started." );        
                    this.isBusy = false;
                });
        }
	}

    public continueToPayment(event: Event): Promise<any> {

        // Prevent default behavior when clicking a link
        event.preventDefault();

        if (!this.isBusy) {
            this.isBusy = true;
            this.isRedirect = true;
            this.errorObject = ErrorObject.EmptyError();
            // First off save the updated name etc ...
            return this.questionServer.initialiseQuestionnaire({ firstname: this.firstName, surname: this.lastName, isTermsAccepted: this.termsAndConditions, emailAddress: this.emailAddress, isIndependentAssessment: this.isIndependentAssessment })
                .then(() => 
                {
                    return this.paymentServer.paymentSetup(this.paymentReferenceId);
                })
                .then(obj => 
                {
                    this.sessionToken = obj.sessionToken;
                    if (obj.isPaid)
                    {
                        // Oh, there must have been a web hook event in the meantime ... and now it is paid. If someone is playing games it will get caught later on anyway..
                        this.isPaid = true;
                        return this.navigateToStartingPage(this.globalState.questionnaire);
                    }
                    else
                    {
                        return this.loadStripeApiLibrary();
                    }
                })
                .then(() =>
                {
                    if (!this.isPaid)
                    {
                        if (AccountsQuestionniareRegister.stripe == null)
                        {
                            AccountsQuestionniareRegister.stripe = Stripe(environment.stripePublicApiKey);
                        }

                        return AccountsQuestionniareRegister.stripe.redirectToCheckout({sessionId: this.sessionToken}).then(result => 
                            {
                                if (result.error)
                                {
                                    this.errorObject = new ErrorObject(
                                        result.error.message,
                                        result,
                                        'Redirect to Payment Provider', 
                                        'There has been an error reported attempting to setup the payment. If this continues, please contact Career Sense. Try again?');       
                                    this.isRedirect = false;
                                    this.isBusy = false;
                                }
                            });}
                })
                .catch(response => 
                    {
                        this.errorObject = new ErrorObject(
                            response,
                            null,
                            'Setup Payment Provider', 
                            'There has been an error reported attempting to setup the payment. If this continues, please contact Career Sense.' + response.name + ' ' + response.message);       
                        this.isBusy = false;
                        this.isRedirect = false;
                    });
                }

	}

    public loadStripeApiLibrary(): Promise<boolean>
    {
        if (AccountsQuestionniareRegister.stripe == null)
        {
            return new Promise<boolean>((resolve) =>
            {
                this.scriptElement = <HTMLScriptElement>document.createElement('script');
                if ((<any>this.scriptElement).readyState) {  //IE
                    let iescript = <any>this.scriptElement;
                    iescript.onreadystatechange = () => {
                        if (iescript.readyState === "loaded" || iescript.readyState === "complete") {
                            iescript.onreadystatechange = null;
                            resolve(true);
                        }
                    };        
                } else
                {
                    this.scriptElement.onload = () => { resolve(true); }
                }

                this.scriptElement.async = true;
                this.scriptElement.defer = true;
                this.scriptElement.src = "https://js.stripe.com/v3/";
                this.scriptElement = document.head.appendChild(this.scriptElement);
            });
        }

        // Resolve immediately, script already loaded.
        return new Promise<boolean>((resolve) => { resolve(false);});
    }    
    
    @computedFrom("questionnaireToken","emailAddress","badRequest","termsAndConditions", "firstName", "lastName", "isBusy")
    public get isNotValid(): boolean {
        return (this.isBadRequest || !this.termsAndConditions || !this.questionnaireToken || this.questionnaireToken.length != 36 || !this.emailAddress || !this.firstName || !this.lastName || this.isBusy);
    }
}
