import {bindable} from 'aurelia-framework';

export class  TermsV1CustomElement {
    @bindable 
    public isOnDisplay: boolean = false;

    constructor() {
    }

    public detached(): void
    {
        this.isOnDisplay = false;
    }

    public close(): void {
        this.isOnDisplay = false;
    }
}
