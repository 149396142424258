import {autoinject} from 'aurelia-framework';
import {Router,RouteConfig} from 'aurelia-router';
import {GlobalState} from '../models/GlobalState';
import {QuestionServer} from '../server/QuestionServer';
import {backPage, nextPage} from '../resources/navigation/NextPage'
import {ErrorObject} from '../models/ErrorObject';
import {RoutePaths} from '../routes';
import {SpecialisedPages} from '../models/SpecialisedPages';
import {Routes} from '../routes';

@autoinject
@backPage()
@nextPage(Routes.IA_END.urlFragment)
export class SpecialisedOptionalExplorer extends RoutePaths {

    private fileURL: any;
    public cardSorts: SpecialisedPages[];
    public lvl: number = 0;
    public isBusy: boolean = false;
    public errorObject: ErrorObject = null;
    public nextSpecialisedPage: SpecialisedPages;


    constructor(protected router: Router, protected globalState: GlobalState, private server: QuestionServer) {
        super();
    }

    public activate(params: any, route: RouteConfig): any {
        if (params.id && params.id.length >= 36)
        {
            if (!this.globalState.questionnaire.id || !this.globalState.questionnaire.id != params.id)
            {
                this.globalState.questionnaire.id = params.id;
                this.globalState.questionnaire.loaded = false;
            }
        } else if (this.globalState.Id)  
        {
            // Perform navigation so that we get the assessment Id present in the URL so that a refresh in the browser will not lose the clients page.
            this.router.navigateToRoute( route.name, { 'id': this.globalState.Id }, { trigger: false, replace: true });
        }

        if (this.globalState.questionnaire.id) {
            return this.server.loadSpecialisedAnalysis(this.globalState.questionnaire.id, this.globalState )
                .then(specialisedAnalysis => {
                    this.cardSorts = specialisedAnalysis.filter(itm => !itm.include);
                    this.isBusy = false;
                })
                .catch(response => {
                    this.errorObject = new ErrorObject(
                        response,
                        null,
                        "Load Questionnaire specialised analysis", 
                        "The specialised analysis data load has failed.");       
                });
        }
    }

    public deactivate(): any 
    {
        if (this.fileURL)
        {
            window.URL.revokeObjectURL(this.fileURL);
        }
    }

    public downloadReport(event: Event):any
    {
        // Prevent default behavior when clicking a link
        event.preventDefault();

        if (this.fileURL)
        {
            window.URL.revokeObjectURL(this.fileURL);
        }

        // Get filename from href
        let careerElement = <Node>event.target;
        (<any>careerElement).Disabled = true;
        let busyIndicator = document.createElement('i');
        busyIndicator.className = 'fa fa-spinner fa-spin busy-indicator';
        careerElement.appendChild(busyIndicator);

        this.server.loadCareerReport(this.globalState.questionnaire.id)
                .then(blob => {

                    careerElement.removeChild(busyIndicator);

                    // IE doesn't allow using a blob object directly as link href
                    // instead it is necessary to use msSaveOrOpenBlob
                    if (window.navigator && (<any>window.navigator).msSaveOrOpenBlob) {
                        (<any>window.navigator).msSaveOrOpenBlob(blob);
                        return;
                    } 

                    this.fileURL = window.URL.createObjectURL(blob);

                    let element = document.createElement('a');
                    element.href = this.fileURL;
                    element.download = this.globalState.firstname + "." +  this.globalState.surname + ".CareerReport.docx";
                    element.style.display = 'none';
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);
                 })
                .catch(response => {
                    careerElement.removeChild(busyIndicator);
                    this.errorObject = new ErrorObject(
                        response,
                        null,
                        "Generate Career Report", 
                        "Failed to download the generated report document.");       
                });
    }
}   
